import { motion } from "framer-motion";

const colorList = [
  {
    textColor: "#8C8C8C",
    bgColor: "#F0F0F0",
  },
  {
    textColor: "#4783C9",
    bgColor: "#E7F2FF",
  },
  {
    textColor: "#8E00D1",
    bgColor: "#F6E7FF",
  },
  {
    textColor: "#D17E00",
    bgColor: "#FFF6E7",
  },
  {
    textColor: "#04D100",
    bgColor: "#E8FFE7",
  },
  {
    textColor: "#D10000",
    bgColor: "#FFE7E7",
  },
];

export function ColorInput({ data, onChange, isFormOpen, handleDropdown }) {
  const handleChange = (color: (typeof colorList)[0]) => {
    handleDropdown();
    onChange(color);
  };

  return (
    <div className="w-full relative items-center">
      <button
        id="sport-selection"
        className={`w-full text-start h-[60px] ${
          data.bgColor && data.textColor ? "px-1" : "p-[1.125rem]"
        } border-solid border-[#E1E1EC] border-[1px] rounded-[8px] relative`}
        onClick={handleDropdown}
      >
        {data.bgColor && data.textColor ? (
          <ColorCard color={data} picked />
        ) : (
          "Select"
        )}
        <motion.div
          className={`w-[90%] right-[5%] fixed bottom-[12rem] right-0  bg-[white] rounded-[10px] p-2 z-[103] shadow-xl h-auto p-[1.5rem] overflow-hidden`}
          initial={{ maxHeight: "0px", padding: "0px" }}
          animate={{
            maxHeight: isFormOpen ? "500px" : "0px",
            padding: isFormOpen ? "8px" : "0px",
            border: isFormOpen ? "1px solid #E1E1EC" : "0px solid #E1E1EC",
          }}
          exit={{ maxHeight: "0px", padding: "0px", border: "none" }}
        >
          <motion.div
            className="grid text-center w-full"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: isFormOpen ? 1 : 0, y: isFormOpen ? 0 : -10 }}
            exit={{ opacity: 0, display: "none" }}
          >
            <div className="flex flex-col gap-3">
              {colorList.map((color, i) => (
                <ColorCard
                  color={color}
                  key={i}
                  onSelect={() => handleChange(color)}
                />
              ))}
            </div>
          </motion.div>
        </motion.div>
      </button>

      {/* all over behind dropdown click closes the dropdown */}
      {isFormOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[102] h-[100%]"
          onClick={handleDropdown}
        />
      )}
    </div>
  );
}

function ColorCard({
  color,
  onSelect,
  picked,
}: {
  color: {
    bgColor: string;
    textColor: string;
  };
  onSelect?: () => void;
  picked?: boolean;
}) {
  return (
    <div
      role="button"
      onClick={onSelect}
      className={`flex gap-2 ${
        onSelect
          ? "p-1 border border-[#ddd] hover:border-primary transition-all rounded-md"
          : "flex  gap-2"
      }`}
    >
      <div
        className="h-8 flex flex-col gap-3 rounded-[6px] flex-1"
        style={{
          backgroundColor: color.textColor || "#F0F0F0",
          height: picked ? "3rem" : "2rem",
        }}
      ></div>
      <div
        className="h-8 flex flex-col gap-3 rounded-[6px] flex-1"
        style={{
          backgroundColor: color.bgColor || "#F0F0F0",
          height: picked ? "3rem" : "2rem",
        }}
      ></div>
    </div>
  );
}
