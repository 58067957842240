import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./module/dashboard";
import UserManagement from "./module/users";
import Selection from "./module/selection";
import PreviousSelection from "module/previous-selection";
import TeamManagement from "module/teams";
import Login from "module/login";
import SuspenseWrapper from "components/SuspenseWrapper";
import { Main } from "module/main";
import Subscriptions from "module/subscription/Subscriptions";
import { PromoCodes } from "module/promo-codes/PromoCodes";

const RoutesList = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<Main />}>
        <Route
          path="/dashboard"
          element={
            <SuspenseWrapper>
              <Dashboard />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/users"
          element={
            <SuspenseWrapper>
              <UserManagement />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/selection"
          element={
            <SuspenseWrapper>
              <Selection />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/previous-selections"
          element={
            <SuspenseWrapper>
              <PreviousSelection />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/teams"
          element={
            <SuspenseWrapper>
              <TeamManagement />
            </SuspenseWrapper>
          }
        />
        <Route
          path="/promo-codes"
          element={
            <SuspenseWrapper>
              <PromoCodes />
            </SuspenseWrapper>
          }
        />
        <Route
          path="subscription"
          element={
            <SuspenseWrapper>
              <Subscriptions />
            </SuspenseWrapper>
          }
        />
        <Route
          index
          element={
            // if loggged in redirect to dashboard
            <Navigate to="/login" />
          }
        />
      </Route>
    </Routes>
  );
};
export default RoutesList;
