import "./css/general-sans.css";
import "./css/monument.css";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Routes from "./routes";

function App() {
  const queryClient = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Toaster
          toastOptions={{
            style: {
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            },
          }}
        />

        <Routes />
      </QueryClientProvider>
    </div>
  );
}

export default App;
