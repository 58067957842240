import Button from "components/Buttons";
import Input from "components/Input";
import Modal from "components/Modal";
import { api } from "helpers/api/api";
import { useEffect, useMemo, useState } from "react";
import { BenefitsInputList } from "./BenefitsInputList";
import { ColorInput } from "./ColorInput";
import { getArrayOfBenefitsString } from "helpers/utils";

export default function SubscriptionForm({
  isOpen,
  handleAddSubscription,
  onClose,
  subscription,
  refetch,
}: {
  isOpen: boolean;
  handleAddSubscription: () => void;
  onClose: () => void;
  subscription?: any;
  refetch: () => void;
}) {
  const [saving, setSaving] = useState(false);
  const [isColorOpen, setIsColorOpen] = useState(false);
  const handleColorDropdown = () => setIsColorOpen(!isColorOpen);
  const [form, setForm] = useState<{
    id?: string;
    name: string;
    priceMonthly: string;
    priceHalfYearly: string;
    priceYearly: string;
    benefits: string[];
    textColor: string;
    bgColor: string;
  }>({
    id: "",
    name: "",
    priceMonthly: "",
    priceHalfYearly: "",
    priceYearly: "",
    benefits: ["", ""],
    textColor: "",
    bgColor: "",
  });
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const isDisabled = useMemo(() => {
    if (
      !form.name ||
      !form.benefits.filter((e) => e).length ||
      !form.priceHalfYearly ||
      !form.priceMonthly ||
      !form.priceYearly ||
      !form.textColor ||
      !form.bgColor
    ) {
      return true;
    }
    return false;
  }, [form]);
  const handleSave = async () => {
    if (isDisabled) return;
    setSaving(true);
    const promise = subscription?.id
      ? api.updateSubscription
      : api.createSubscription;

    promise(form)
      .then((res) => {
        console.log(res);
        refetch();
        onClose();
        setSaving(false);
        setForm({
          name: "",
          priceMonthly: "",
          priceHalfYearly: "",
          priceYearly: "",
          benefits: ["", ""],
          textColor: "",
          bgColor: "",
        });
      })
      .catch((err) => {
        console.log(err);
        setSaving(false);
      });
  };
  useEffect(() => {
    if (
      isOpen &&
      subscription?.name &&
      subscription?.priceMonthly &&
      subscription?.priceHalfYearly &&
      subscription?.priceYearly &&
      subscription?.benefits &&
      subscription?.textColor &&
      subscription?.bgColor
    ) {
      setForm({
        id: subscription.id,
        name: subscription.name,
        priceMonthly: subscription.priceMonthly,
        priceHalfYearly: subscription.priceHalfYearly,
        priceYearly: subscription.priceYearly,
        benefits:
          // benefits are split by $$ make them an array
          subscription?.benefits?.split("$$"),
        textColor: subscription.textColor,
        bgColor: subscription.bgColor,
      });
    } else if (isOpen) {
      setForm({
        name: "",
        priceMonthly: "",
        priceHalfYearly: "",
        priceYearly: "",
        benefits: ["", ""],
        textColor: "",
        bgColor: "",
      });
    }
  }, [isOpen, subscription]);

  return (
    <Modal
      isOpen={isOpen}
      handleModal={() => {
        onClose();
        setForm({
          name: "",
          priceMonthly: "",
          priceHalfYearly: "",
          priceYearly: "",
          benefits: ["", ""],
          textColor: "",
          bgColor: "",
        });
      }}
      title="Add a subscription plan"
    >
      <div className="grid gap-[1.25rem]">
        <div className="text-[0.875rem] grid gap-2">
          <Input
            placeholder="Enter code"
            label="Plan name"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
        </div>
        <div className="text-[0.875rem] grid gap-2">
          <span className="text-sm text-black ">Price (in USD)</span>
          <div className="relative w-full flex items-center gap-3">
            <Input
              placeholder="Enter"
              label="Monthly"
              name="priceMonthly"
              value={form.priceMonthly}
              onChange={handleChange}
              type="number"
              // suffixText="/month"
            />
            <Input
              placeholder="Enter"
              label="Half-yearly"
              name="priceHalfYearly"
              value={form.priceHalfYearly}
              onChange={handleChange}
              type="number"
              // suffixText="/month"
            />
            <Input
              placeholder="Enter"
              label="Yearly"
              name="priceYearly"
              value={form.priceYearly}
              onChange={handleChange}
              type="number"
              // suffixText="/month"
            />
          </div>
        </div>
        <div className="text-[0.875rem] grid gap-2">
          <span className="text-sm text-black">Benefits</span>
          <BenefitsInputList
            onChange={(value) =>
              setForm((prev) => ({ ...prev, benefits: value }))
            }
            list={form.benefits}
          />
        </div>
        <div className="text-[0.875rem] grid gap-2">
          <button
            className="mb-1 text-[0.875rem] font-[400] cursor-pointer text-start"
            onClick={handleColorDropdown}
          >
            Assign Color
          </button>
          <ColorInput
            data={form}
            onChange={(value) => setForm((prev) => ({ ...prev, ...value }))}
            isFormOpen={isColorOpen}
            handleDropdown={handleColorDropdown}
          />
        </div>
      </div>
      <Button
        className="px-[1.75rem] py-[0.875rem] float-end text-[1rem] leading-[100%] mt-4"
        onClick={handleSave}
        disabled={isDisabled}
        isLoading={saving}
      >
        Save
      </Button>
    </Modal>
  );
}
