import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Button from "components/Buttons";
import Input from "components/Input";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { api } from "helpers/api/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export default function LandingModal({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["config"],
    queryFn: () => api.getConfig(),
  });
  const [formData, setFormData] = useState({
    totalSelections: 0,
    wins: 0,
    winPercentage: 0,
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    api
      .updateLandingPage({
        totalSelections: formData.totalSelections,
        wins: formData.wins,
        winPercentage: formData.winPercentage,
      })
      .then(() => {
        refetch();
        handleModal();
        toast.success("Landing page updated successfully");
      })
      .catch((err) => {
        toast.error("Landing page failed to update");
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      handleModal={handleModal}
      title="Landing Page Config"
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <form className="grid gap-4" onSubmit={handleSubmit}>
          <Input
            label="Total Selections"
            placeholder="Enter Total Selections"
            defaultValue={data.totalSelections}
            onChange={(e) =>
              setFormData({
                ...formData,
                totalSelections: parseInt(e.target.value),
              })
            }
          />
          <Input
            label="Wins"
            placeholder="Enter Wins"
            defaultValue={data.wins}
            onChange={(e) =>
              setFormData({
                ...formData,
                wins: parseInt(e.target.value),
              })
            }
          />
          <Input
            label="Win Percentage"
            placeholder="Enter Win Percentage"
            defaultValue={data.winPercentage}
            onChange={(e) =>
              setFormData({
                ...formData,
                winPercentage: parseInt(e.target.value),
              })
            }
          />
          <Button type="submit" className="w-full">
            Save
          </Button>
        </form>
      )}
    </Modal>
  );
}
