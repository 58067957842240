const searchIcon = require("assets/images/search.svg");

export default function Input({
  id,
  placeholder,
  label,
  className,
  type,
  onChange,
  error,
  onBlur,
  parentClassName,
  tabIndex,
  register,
  isPasswordField,
  search,
  value,
  name,
  suffixText,
  max,
  min,
  maxLength,
  defaultValue,
}: {
  id?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: any;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  parentClassName?: string;
  tabIndex?: number;
  props?: any;
  register?: any;
  isPasswordField?: boolean;
  search?: boolean;
  value?: string;
  name?: string;
  suffixText?: string;
  max?: number;
  min?: number;
  maxLength?: number;
  defaultValue?: string;
}) {
  if (search) {
    return (
      <div className={`${parentClassName} relative`}>
        <input
          id={id}
          className={`${className} md:min-w-[17.5rem] border-[#E0E0EA] border-[1px] pl-12 p-[1.125rem] rounded-[8px] w-full bg-[transparent] focus:outline-primary`}
          placeholder={placeholder}
          type={type || "text"}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={!isPasswordField && 50}
          tabIndex={tabIndex}
          max={max}
          {...(register && register(`${id}`))}
        />
        <searchIcon.ReactComponent className="absolute top-[50%] left-[1.25rem] transform -translate-y-1/2" />
      </div>
    );
  }

  return (
    <label htmlFor={id} className={`${parentClassName} grid gap-1`}>
      <span className="mb-1 text-[0.875rem] font-[400] cursor-pointer">
        {label}
      </span>
      <div className="border-[#E0E0EA] border-[1px] relative rounded-md">
        <input
          id={id}
          className={`${className} ${
            suffixText ? "p-[1.125rem] pl-3 pr-[4.25rem]" : "p-[1.125rem]"
          } rounded-[8px] w-full bg-[transparent] focus:outline-primary`}
          placeholder={placeholder}
          type={type || "text"}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={!isPasswordField ? maxLength || 100 : undefined}
          tabIndex={tabIndex}
          value={value}
          name={name}
          max={max}
          min={min}
          defaultValue={defaultValue}
          {...(register && register(`${id}`))}
        />
        {suffixText && (
          <div className="absolute top-0 bottom-0 right-2 flex items-center">
            <span className="text-base font-semibold">{suffixText}</span>
          </div>
        )}
      </div>

      {error && (
        <span className="text-[0.75rem] text-[#FF4D4F] font-[400]">
          {error}
        </span>
      )}
    </label>
  );
}
