/* eslint-disable import/no-anonymous-default-export */
import useResponsive from "helpers/useResponsive";
import PreviousSelectionMobile from "./PreviousSelecitonMobile";
import PreviousSelection from "./PreviousSelection";

export default function () {
  const { isMobile, isTablet } = useResponsive();
  if (isMobile || isTablet) return <PreviousSelectionMobile />;
  return <PreviousSelection />;
}
