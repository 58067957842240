import apiClient from "./client";

export const api = {
  login: (data: any) => {
    return apiClient.post("/auth/admin/login", data);
  },
  getAdminDetails: () => {
    return apiClient.get("/admin/me");
  },
  getDashboard: (id: string) => {
    return apiClient.get(`/users/${id}/dashboard`).then((res) => res.data);
  },
  getDashboardCharts: ({ id, type, duration }) => {
    return apiClient
      .get(`/users/${id}/reports?type=${type}&duration=${duration}`)
      .then((res) => res.data);
  },
  getUsers: (data: any) => {
    let url = `/users?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&searchKeyword=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    if (data.type) {
      url += `&type=${data.type}`;
    }
    url += `&showActive=${data.showActive}`;

    return apiClient.get(url).then((res) => res.data);
  },
  getBlockedUsersByAdmin: (data: any) => {
    let url = `/admin/user/blocked-by-admin?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&search=${data.keyword}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  updateUser: (data: any) => {
    ///admin/user/profile/update
    return apiClient.put("/admin/user/profile/update", data);
  },
  blockUser: (data: { id: string }) => {
    return apiClient.patch(`/users/${data.id}/status`, { isActive: false });
  },
  unblockUser: (data: { id: string }) => {
    return apiClient.patch(`/users/${data.id}/status`, { isActive: true });
  },
  deleteUser: (data: { id: string }) => {
    return apiClient.delete(`/users/${data.id}`);
  },
  getUserInfo: (userId: string) => {
    return apiClient
      .get(`/admin/user-info?id=${userId}`)
      .then((res) => res.data);
  },
  getSubscriptions: () => {
    return apiClient.get("/subscriptions").then((res) => res.data);
  },
  getTeams: (clientParams?: any) => {
    return apiClient
      .get("/teams", {
        params: clientParams,
      })
      .then((res) => res.data);
  },
  getTeam: (id: string) => {
    return apiClient.get(`/teams/${id}`).then((res) => res.data);
  },
  addTeam: (data: any) => {
    return apiClient.post("/teams", data);
  },
  deleteTeam: (id: string) => {
    return apiClient.delete(`/teams/${id}`);
  },
  updateTeam: (id: string, data: any) => {
    return apiClient.patch(`/teams/${id}`, data);
  },
  getSelections: (data: any) => {
    let url = `/selections?page=${data.page}&limit=${data.limit}&day=${data.days}&subscriptionId=${data.subscriptionId}`;
    return apiClient.get(url).then((res) => res.data);
  },
  getPreviousSelections: (data: any) => {
    let url = `/selections`;
    return apiClient
      .get(url, {
        params: {
          ...data,
        },
      })
      .then((res) => res.data);
  },
  patchSelection: (id: string, data: any) => {
    return apiClient.patch(`/selections/${id}`, data);
  },
  createSelection: (data: any) => {
    return apiClient.post("/selections", data);
  },
  saveSelection: (data: any) => {
    return apiClient.post("/selections", data);
  },
  savePromoCode: (data: any) => {
    return apiClient.post("/promo-codes", data);
  },
  getSelectionList: ({
    day,
    subscriptionId,
    page,
    limit,
    showPrevious,
  }: {
    day: string;
    subscriptionId?: string;
    page: number;
    limit: number;
    showPrevious?: boolean;
  }) => {
    let url = `/selections?page=${page}&limit=${limit}&subscriptionId=${
      subscriptionId || ""
    }&day=${day}`;
    return apiClient
      .get(url, {
        params: {
          showPrevious,
        },
      })
      .then((res) => res.data);
  },
  getImageSignedURL: (type?: string) => {
    return apiClient
      .get(
        `/users/${localStorage.getItem(
          "id"
        )}/images/signed-url?uploadType=USER_PROFILE&mimeType=${type}`
      )
      .then((res) => res.data);
  },
  createSubscription: (data: any) => {
    // const payload = {
    //   name: "Conservative",
    //   priceMonthly: 20,
    //   priceHalfYearly: 100,
    //   priceYearly: 200,
    //   benefits: ["Primary Selection 70%"],
    //   textColor: "#4783C9",
    //   bgColor: "#E7F2FF",
    // };
    return apiClient.post("/subscriptions", data);
  },
  updateSubscription: (data: any) => {
    return apiClient.patch(`/subscriptions/${data.id}`, data);
  },
  deleteSubscription: (id: string) => {
    return apiClient.delete(`/subscriptions/${id}`);
  },
  getPromoCodes: (data: any) => {
    let url = `/promo-codes?page=${data.page}&limit=${data.limit}`;
    if (data.keyword) {
      url += `&searchKeyword=${data.keyword}`;
    }
    if (data.type) {
      url += `&showActive=${data.type === "active" ? true : false}`;
    }
    if (data.sort_order && data.sort_by) {
      url += `&sort_order=${data.sort_order}&sort_by=${data.sort_by}`;
    }
    return apiClient.get(url).then((res) => res.data);
  },
  deletePromocode: (data: { id: string }) => {
    return apiClient.delete(`/promo-codes/${data.id}`);
  },
  getImageUpload: (data: {
    folder: string;
    file_name: string;
    content_type: string;
  }) => {
    return apiClient
      .post("/general/image/upload", data)
      .then((res) => res.data);
  },
  // /general/image/upload
  getConfig: () => {
    return apiClient.get("/configs").then((res) => res.data?.[0]);
  },
  updateEmailConfig: ({
    selectionEmailTemplateTitle,
    selectionEmailTemplateImgUrl,
  }: {
    selectionEmailTemplateTitle: string;
    selectionEmailTemplateImgUrl: string;
  }) => {
    return apiClient.post("/configs", {
      selectionEmailTemplateTitle,
      selectionEmailTemplateImgUrl,
    });
  },
  updateLandingPage: (data: any) => {
    return apiClient.post("/configs", data);
  },
  notifyUsers: (data: any) => {
    return apiClient.post("/notify-users", data);
  },
  deleteSelection: (id: string) => {
    return apiClient.delete(`/selections/${id}`);
  },
};
