import { ReactComponent as EmptyIcon } from "../assets/images/not-found.svg";

export function EmptyTableView({
  cols,
  message,
  className = "",
}: {
  cols: number;
  message: string;
  className?: string;
}) {
  return (
    <tr>
      <td colSpan={cols} className={`text-center py-5 ${className}`}>
        <div className="flex flex-col justify-center items-center text-base gap-4">
          <EmptyIcon />
          {message}
        </div>
      </td>
    </tr>
  );
}
