import { HighlightCard } from "./components/HighlightCard";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron.svg";
import { ReactComponent as UserIcon } from "../../assets/images/users.svg";
import { ReactComponent as EarnedIcon } from "../../assets/images/earned.svg";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import ChevronDown from "../../assets/images/chevron-down-black.svg";

import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment, { unitOfTime } from "moment";
import { api } from "helpers/api/api";
import { formatNumber, getUser } from "helpers/utils";
import { LoadAnimatedContainer } from "components/AnimatedContainer";
import { PersonCard } from "./components/PersonCard";
import Button from "components/Buttons";
import AddSelection from "module/selection/AddSelection";
import useResponsive from "helpers/useResponsive";
import EmailModal from "./EmailModal";
import LandingModal from "./LandingModal";
import SpecificEmail from "./SpecificEmail";

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {rest.type === "CURRENT_WEEK"
          ? moment(payload.value).format("dd")
          : rest.type === "CURRENT_MONTH"
          ? moment(payload.value).format("DD")
          : // turn month number to month name
          payload.value === 1
          ? "Jan"
          : payload.value === 2
          ? "Feb"
          : payload.value === 3
          ? "Mar"
          : payload.value === 4
          ? "Apr"
          : payload.value === 5
          ? "May"
          : payload.value === 6
          ? "Jun"
          : payload.value === 7
          ? "Jul"
          : payload.value === 8
          ? "Aug"
          : payload.value === 9
          ? "Sep"
          : payload.value === 10
          ? "Oct"
          : payload.value === 11
          ? "Nov"
          : payload.value === 12
          ? "Dec"
          : payload.value}
      </text>
    </g>
  );
}

const titleMap = {
  "Below 20": "Below 20yr",
  "20-25": "20yr - 25yr",
  "25-30": "25yr - 30yr",
  "30-35": "30yr - 35yr",
  "35-40": "35yr - 40yr",
  "40 above": "40yr above",
};

const renderUsersTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="px-5 py-4 bg-white rounded-xl shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex-col justify-center items-center gap-1 inline-flex">
          <div className="text-black text-base font-normal font-['Helvetica Neue'] leading-tight">
            {moment(payload[0]?.payload?.name).format("MMM DD, YYYY")}
          </div>
          <div className="text-black text-xl font-bold font-['Helvetica Neue'] leading-normal">
            {formatNumber(Number(payload[0]?.value))}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

function DashboardSkeleton() {
  return (
    <div>
      <div className="flex flex-col md:flex-row gap-5 md:items-center flex-wrap flex-1">
        <div className="flex-1 lg:w-[350px] lg:h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1  lg:w-[350px] lg:h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 lg:w-[350px] lg:h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col lg:flex-row  gap-5">
        <div className="flex flex-col flex-1 p-6 bg-white rounded-[16px] h-[450px]">
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
            <div className="lg:w-[125px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
          </div>
          <div className="animate-pulse transition duration-50 h-16 rounded-[16px] mb-4 bg-gray-300"></div>
          <div className="flex-1 animate-pulse transition duration-50 h-[402px] bg-gray-300"></div>
        </div>
        <div className="flex flex-col flex-1 p-6 bg-white rounded-[16px] h-[450px]">
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
            <div className="lg:w-[125px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
          </div>
          <div className="animate-pulse transition duration-50 h-16 rounded-[16px] mb-4 bg-gray-300"></div>
          <div className="flex-1 animate-pulse transition duration-50 h-[402px] bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
}

function Card({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={`${className} bg-[white] p-[1.5rem] rounded-[16px]`}>
      {children}
    </div>
  );
}

export function Dashboard() {
  const user = getUser();
  const [selectedChart, setSelectedChart] = useState<string>("CURRENT_WEEK");
  const [isAddSelectionOpen, setIsAddSelectionOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isLandingModalOpen, setIsLandingModalOpen] = useState(false);
  const [isSpecificEmailOpen, setIsSpecificEmailOpen] = useState(false);

  const { isMobile } = useResponsive();
  const handleAddSelection = () => setIsAddSelectionOpen(!isAddSelectionOpen);
  const handleEmailModal = () => setIsEmailModalOpen(!isEmailModalOpen);
  const handleLandingModal = () => setIsLandingModalOpen(!isLandingModalOpen);
  const handleSpecificEmail = () =>
    setIsSpecificEmailOpen(!isSpecificEmailOpen);

  const { isLoading, data } = useQuery({
    queryKey: ["dashboard", user.id],
    queryFn: () => api.getDashboard(user.id),
  });
  // get subscription data
  const { data: subscriptions = [] } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => api.getSubscriptions(),
  });
  // get team data
  const { data: teams = [] } = useQuery({
    queryKey: ["teams-selection"],
    queryFn: () => api.getTeams({ limit: 1000000 }),
  });
  const { data: chartData } = useQuery({
    queryKey: ["dashboard-charts", selectedChart, user.id],
    queryFn: () =>
      api.getDashboardCharts({
        duration: selectedChart,
        id: user.id,
        type: "USERS_COUNT",
      }),
  });

  const dashboardData = data;

  const { data: subscribersData, refetch } = useQuery({
    queryKey: ["subscribers"],
    queryFn: () =>
      api.getUsers({
        type: "SUBSCRIBERS",
        page: 0,
        limit: 10,
        showActive: true,
      }),
  });

  const handleSelectionForm = () => {
    setIsAddSelectionOpen(!isAddSelectionOpen);
  };
  const closeSelectionForm = () => {
    setIsAddSelectionOpen(false);
  };

  return (
    <LoadAnimatedContainer>
      <div className="bg-background h-[100vh] overflow-auto p-4 lg:p-7 pt-6">
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
            Dashboard
          </h1>
          {!isMobile && (
            <div className="flex gap-2">
              <Button
                className="w-full md:w-fit px-[1.75rem] py-[0.875rem] !h-[auto]"
                onClick={handleSpecificEmail}
                variant="secondary"
              >
                Specific Email
              </Button>
              <Button
                className="w-full md:w-fit px-[1.75rem] py-[0.875rem] !h-[auto]"
                onClick={handleEmailModal}
                variant="secondary"
              >
                Email Config
              </Button>
              <Button
                className="w-full md:w-fit px-[1.75rem] py-[0.875rem] !h-[auto]"
                onClick={handleLandingModal}
                variant="secondary"
              >
                Update Data
              </Button>
              <Button
                className="w-full md:w-fit px-[1.75rem] py-[0.875rem]"
                onClick={handleAddSelection}
              >
                Add selection of the day
              </Button>
            </div>
          )}
          {isMobile && (
            <>
              <div className="flex justify-center gap-2">
                <Button
                  className="w-full md:w-fit px-[1.75rem] py-[0.875rem] !h-[auto]"
                  onClick={handleEmailModal}
                  variant="secondary"
                >
                  Email Config
                </Button>
                <Button
                  className="w-full md:w-fit px-[1.75rem] py-[0.875rem] !h-[auto]"
                  onClick={handleLandingModal}
                  variant="secondary"
                >
                  Update Data
                </Button>
              </div>
              <div className="flex justify-center gap-2">
                <Button
                  className="w-full md:w-fit px-[1.75rem] py-[0.875rem] !h-[auto]"
                  onClick={handleSpecificEmail}
                  variant="secondary"
                >
                  Direct email
                </Button>
                <Button
                  className="w-full md:w-fit px-[1.75rem] py-[0.875rem]"
                  onClick={handleAddSelection}
                >
                  Add selection
                </Button>
              </div>
            </>
          )}
        </div>

        <>
          {isLoading ? (
            <DashboardSkeleton />
          ) : (
            <>
              <div className="flex gap-5 md:items-center flex-wrap flex-1 flex-col md:flex-row">
                <Link to="/users" className="md:w-1/3">
                  <HighlightCard
                    icon={<UserIcon />}
                    label={
                      <div className="flex items-center">
                        <span>Total Users</span>
                        <ChevronRight className="mt-1" />
                      </div>
                    }
                    value={formatNumber(Number(dashboardData?.totalUsers))}
                    className="transition-all cursor-pointer hover:shadow-[rgba(0,71,28,0.12)_0px_7px_16px] w-full"
                  />
                </Link>
                <HighlightCard
                  iconView={<EarnedIcon />}
                  label="Total Earned"
                  value={formatNumber(Number(dashboardData?.totalRevenue))}
                />
                {/* <Link to="/users?plusUser=true" className="flex-1"> */}
                <HighlightCard
                  icon={<CalendarIcon />}
                  label={
                    <div className="flex items-center">
                      <span>Monthly Earned</span>
                      {/* <ChevronRight className="mt-1" /> */}
                    </div>
                  }
                  value={formatNumber(Number(dashboardData?.monthlyRevenue))}
                  className=""
                />
                {/* </Link> */}
              </div>
              <div className="mt-5 flex flex-wrap gap-5">
                <div className="bg-white p-5 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] rounded-[16px] flex-1">
                  <div className="flex justify-between items-center">
                    <h3 className=" text-xl font-bold">Users</h3>
                    <select
                      onChange={(e) => setSelectedChart(e.target.value)}
                      value={selectedChart}
                      className="bg-[#EEF1EF] rounded-full pl-4 pr-8 py-2 outline-none appearance-none cursor-pointer hover:bg-[#DBE3DC]"
                      style={{
                        backgroundImage: `url(${ChevronDown})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "90% 55%",
                      }}
                    >
                      <option value="CURRENT_WEEK">This Week</option>
                      <option value="CURRENT_MONTH">This Month</option>
                      <option value="CURRENT_YEAR">This Year</option>
                    </select>
                  </div>
                  <div className="flex">
                    <div className="w-full mt-6 p-4 rounded-lg border border-gray-200 justify-between items-center flex flex-1 w-full">
                      <div className="text-black text-base font-normal font-['Helvetica Neue'] leading-tight">
                        {moment()
                          .startOf(
                            selectedChart.split("_")[1] as unitOfTime.StartOf
                          )
                          .format("MMM D")}{" "}
                        -{" "}
                        {moment()
                          .endOf(
                            selectedChart.split("_")[1] as unitOfTime.StartOf
                          )
                          .format("MMM D")}
                      </div>
                      <div className="py-px justify-end items-center gap-1 flex">
                        <div className="text-black text-lg font-bold font-['Helvetica Neue'] leading-tight">
                          {chartData?.users
                            ? formatNumber(
                                Number(
                                  chartData?.users?.reduce(
                                    (acc, item) => acc + Number(item.count),
                                    0
                                  )
                                )
                              )
                            : ""}
                        </div>
                        {chartData?.users && (
                          <div className="rounded-3xl justify-end items-center gap-1.5 flex">
                            <ArrowDown className="w-4 h-4" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {chartData?.users && (
                    <div className="h-[402px] mt-12">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                          data={chartData?.users?.map((item) => {
                            return {
                              name: item.day || item.month,
                              count: item.count,
                            };
                          })}
                          margin={{
                            top: 0,
                            right: 0,
                            left:
                              chartData?.users.length < 10
                                ? -40
                                : chartData?.users.length < 100
                                ? -30
                                : -10,
                            bottom: 15,
                          }}
                        >
                          <defs>
                            <linearGradient
                              id="paint0_linear_53_758"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#00FFB3" />
                              <stop
                                offset="1"
                                stop-color="#F0FF44"
                                stop-opacity="0"
                              />
                            </linearGradient>
                          </defs>
                          <CartesianGrid vertical={false} />
                          <XAxis
                            dataKey="name"
                            tick={(props) => (
                              <CustomizedLabel
                                {...props}
                                type={selectedChart}
                              />
                            )}
                          />
                          <YAxis
                            tickFormatter={(tick) => {
                              return Number.isInteger(tick) ? tick : "";
                            }}
                          />
                          <Tooltip content={renderUsersTooltip} />
                          <Area
                            type="monotone"
                            dataKey="count"
                            stroke="#00FFB3"
                            strokeWidth="3px"
                            fill="url(#paint0_linear_53_758)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>
                  )}
                </div>
                <Card className="md:w-1/2 w-full">
                  <div className="flex justify-between items-center mb-[1.5rem]">
                    <h2 className="font-[700] text-[1.25rem]">
                      Recent subscribers
                    </h2>
                    <Link
                      to="/users"
                      className="text-primary font-[500] text-[1rem]"
                    >
                      View all
                    </Link>
                  </div>
                  <div className="grid gap-4 overflow-y-auto max-h-[480px]">
                    {subscribersData?.map((subscriber) => (
                      <PersonCard
                        key={subscriber.id}
                        subscriber={subscriber}
                        refetch={refetch}
                      />
                    ))}
                  </div>
                </Card>
              </div>
            </>
          )}
        </>

        <AddSelection
          isAddSelectionOpen={isAddSelectionOpen}
          handleAddSelection={handleSelectionForm}
          onClose={closeSelectionForm}
          subscriptions={subscriptions}
          teams={teams}
        />
        <EmailModal isOpen={isEmailModalOpen} handleModal={handleEmailModal} />
        <LandingModal
          isOpen={isLandingModalOpen}
          handleModal={handleLandingModal}
        />
        <SpecificEmail
          isOpen={isSpecificEmailOpen}
          handleModal={handleSpecificEmail}
        />
      </div>
    </LoadAnimatedContainer>
  );
}
