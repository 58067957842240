import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
// import { ReactComponent as Ellipse } from "../../assets/icons/ellipse.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import userImage from "../../assets/images/user.svg";

import Button from "../Buttons";

const PROFILE_PIC =
  "https://scontent.fstv5-1.fna.fbcdn.net/v/t51.2885-15/393470545_363262946050114_2699725582897640060_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=7d201b&_nc_ohc=QnvOCbmlI1cAX-CBKHA&_nc_ht=scontent.fstv5-1.fna&edm=AL-3X8kEAAAA&oh=00_AfCJRu0jxavCkUKHt15MrG7gOlBxmY2yn1JIT-6gkSwJkQ&oe=653AE3BE";

export const menuItems = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Manage Users",
    path: "/users",
  },
  {
    title: "Selection of the day",
    path: "/selection",
  },
  {
    title: "Sport teams",
    path: "/teams",
  },
  {
    title: "Promo codes",
    path: "/promo-codes",
  },
  {
    title: "Subscription type",
    path: "/subscription",
  },
];

export function Sidebar({ user }: { user?: any }) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <div className="hidden lg:flex max-h-[100vh] overflow-auto flex-col bg-white">
      <div className="flex items-center self-stretch justify-center py-6 pl-2 pr-3 border-b border-[rgba(0,0,0,0.14)]">
        <div className="justify-center items-center flex relative h-[47px]">
          <Logo className="w-[146px] h-[47px]" />
        </div>
      </div>
      <div className="flex-col justify-between gap-5 flex h-[calc(100vh-70px)] pt-6 pb-5">
        <div className="flex flex-col items-start justify-start w-full gap-3 px-6">
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              className={`w-full px-[22px] py-[16px] rounded-lg items-center gap-3 flex group hover:bg-[#D0F3C9]/50 ${
                isSelected(item.path) ? "!bg-[#D0F3C9]" : ""
              }`}
              // target={item?.external === true ? "_blank" : "_self"}
            >
              {!collapsed && (
                <div className="text-lg leading-tight text-black grow shrink basis-0">
                  {item.title}
                </div>
              )}
            </Link>
          ))}
        </div>
        <div className="w-[250px] flex-col justify-start items-start gap-2.5 flex rounded-lg border border-[#D3D3D3] mx-5">
          <div className="flex items-center self-stretch justify-between gap-2 px-4 py-3">
            <div className="inline-flex items-center justify-start gap-2">
              <div className="rounded-full w-11 h-11">
                <img
                  src={userImage}
                  alt="profile-pic"
                  className="w-full h-full rounded-full object-fit  bg-background p-1"
                />
              </div>
              <div className="flex-col flex">
                <span className="text-lg font-bold text-black">Admin</span>
                <span className="text-xs text-black opacity-70">admin</span>
              </div>
            </div>
            <Button
              variant="icon"
              className="!p-0 hover:!scale-110 !bg-transparent"
              type="button"
              onClick={handleLogout}
            >
              <LogoutIcon className="w-6 h-6" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
