import { ReactComponent as Logo2 } from "../../assets/images/logo.svg";
import { ReactComponent as BurgerMenu } from "../../assets/images/burger-menu.svg";

import Button from "components/Buttons";

export function MobileHeader({ onCollapse }: { onCollapse: () => void }) {
  return (
    <div className="flex lg:hidden p-4 justify-between items-center bg-white">
      <Logo2 className="w-[6.25rem] h-8" />
      <Button
        variant="icon"
        onClick={onCollapse}
        className="!py-1 px-0 pl-4 !pr-0"
      >
        <BurgerMenu className="w-6 h-6 " />
      </Button>
    </div>
  );
}
