import { useState } from "react";
import useResponsive from "helpers/useResponsive";
import { motion } from "framer-motion";
const chevronIcon = require("assets/images/chevron-black.svg");

export function SubscriptionPicker({
  selectedSubscription,
  subscriptions,
  handleSubscriptionClick,
  className,
}: {
  selectedSubscription: string;
  subscriptions: any;
  handleSubscriptionClick: (id: string) => void;
  className?: string;
}) {
  const { isMobile } = useResponsive();
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const handleDropdown = () => setDropdown(!dropdown);

  if (isMobile) {
    return (
      <div className="relative">
        <button
          id="sport-selection"
          className={`${className} w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]`}
          onClick={handleDropdown}
        >
          <span
            className={`${
              value === "" ? "text-[#8D8E92]" : "text-[black]"
            } rounded-[8px] flex gap-3 items-center`}
          >
            {!value ? <>Select</> : <>{value}</>}
          </span>
          <img
            src={chevronIcon.default}
            alt="chevron"
            className="absolute right-[1.125rem] top-1/2 transform -translate-y-1/2"
          />
        </button>
        <motion.div
          className={`w-full absolute top-[4rem] right-0  bg-[white] rounded-[10px] p-2 z-[103] shadow-xl h-auto p-[1.5rem] overflow-hidden`}
          initial={{ maxHeight: "0px", padding: "0px" }}
          animate={{
            maxHeight: dropdown ? "500px" : "0px",
            padding: dropdown ? "8px" : "0px",
            border: dropdown ? "1px solid #E1E1EC" : "0px solid #E1E1EC",
          }}
          exit={{ maxHeight: "0px", padding: "0px", border: "none" }}
        >
          <motion.div
            className="grid text-center w-full"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: dropdown ? 1 : 0, y: dropdown ? 0 : -10 }}
            exit={{ opacity: 0, display: "none" }}
          >
            <button
              className={`text-start rounded-[50px] px-[1.75rem] py-[0.875rem] text-[1.125rem] font-medium ${
                selectedSubscription === "" ? "bg-[white]" : "text-[#6A6A6A]"
              }`}
              onClick={() => {
                setValue("All");
                handleDropdown();
                handleSubscriptionClick("");
              }}
            >
              All
            </button>
            {subscriptions.map((subscription) => (
              <button
                key={subscription.id}
                className={`text-start rounded-[50px] px-[1.75rem] py-[0.875rem]  text-[1.125rem] font-medium  ${
                  selectedSubscription === subscription.id
                    ? "bg-[white]"
                    : "text-[#6A6A6A]"
                }`}
                onClick={() => {
                  setValue(subscription?.name);
                  handleDropdown();
                  handleSubscriptionClick(subscription.id);
                }}
              >
                {subscription?.name}
              </button>
            ))}
          </motion.div>
        </motion.div>
        {/* all over behind dropdown click closes the dropdown */}
        {dropdown && (
          <div
            className="fixed top-0 left-0 w-full z-[102] h-[100vh]"
            onClick={handleDropdown}
          />
        )}
      </div>
    );
  }
  return (
    <div className="bg-[#DCECE0] rounded-[70px] p-1 w-full justify-self-center flex">
      <button
        className={`rounded-[50px] px-[1.75rem] py-[0.875rem] text-[1.125rem] font-medium flex-1 ${
          selectedSubscription === "" ? "bg-[white]" : "text-[#6A6A6A]"
        }`}
        onClick={() => handleSubscriptionClick("")}
      >
        All
      </button>
      {subscriptions.map((subscription) => (
        <button
          key={subscription.id}
          className={`rounded-[50px] px-[1.75rem] py-[0.875rem]  text-[1.125rem] font-medium flex-1  ${
            selectedSubscription === subscription.id
              ? "bg-[white]"
              : "text-[#6A6A6A]"
          }`}
          onClick={() => handleSubscriptionClick(subscription.id)}
        >
          {subscription?.name}
        </button>
      ))}
    </div>
  );
}
