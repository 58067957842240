import Button from "components/Buttons";
import Dropdown from "components/Dropdown";
import { ReactComponent as More } from "../../../assets/images/more.svg";
import { ReactComponent as TrashIcon } from "../../../assets/images/delete.svg";
import { useState } from "react";

export function OptionView({ removeUser }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative ml-auto flex justify-center w-full">
      <button
        className="text-primary text-[0.875rem] font-[600]"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="block p-2 ">
          <More />
        </span>
      </button>

      <Dropdown
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        padding="6px"
        className="w-60"
      >
        <div className="place-content-start">
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#DCECE0] !rounded-[3px] text-[#F54343] w-full text-left !justify-start"
            onClick={() => {
              setIsOpen(false);
              removeUser();
            }}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove promo code
          </Button>
        </div>
      </Dropdown>
    </div>
  );
}
