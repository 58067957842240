export default function SubscriptionCard({
  subscriptionBgColor,
  subscriptionName,
  subscriptionTextColor,
}: any) {
  return (
    <div
      className="px-[0.5rem] py-[0.375rem] rounded-[0.5rem] bg-[#E6F3FF] leading-[100%] w-fit"
      style={{ backgroundColor: subscriptionBgColor }}
    >
      <span
        className="text-[#4783C9] text-[0.75rem] font-[600]"
        style={{ color: subscriptionTextColor }}
      >
        {subscriptionName || "Conservative"}
      </span>
    </div>
  );
}
