import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Dropdown from "components/Dropdown";
import Spinner from "components/Spinner";
import { api } from "helpers/api/api";
import { sports } from "helpers/constant";
import { SubscriptionPicker } from "module/selection/components/SubscriptionPicker";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
const chevronIcon = require("assets/images/chevron-black.svg");

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <div
          key={i}
          className="flex-col px-[1.25rem] py-4 border-solid rounded-md bg-gray-200 p-4 flex flex-col gap-3 max-w-[92vw]"
        >
          <div className="flex justify-between">
            <div className="text-start text-[1rem] font-[500] flex items-center gap-2">
              <div className="w-20 h-6 bg-gray-300 rounded"></div>
            </div>
            <div className="text-start text-[1rem] font-[500] flex items-center gap-2">
              <div className="w-20 h-6 bg-gray-300 rounded"></div>
            </div>
          </div>
          <div className="flex w-full">
            <div className="w-1/3 text-start text-[1rem] font-[500] flex items-center gap-2">
              <div className="w-16 h-6 bg-gray-300 rounded"></div>
            </div>
            <div className="w-1/6 text-start text-[1rem] font-[500] flex items-center gap-2">
              <div className="w-16 h-6 bg-gray-300 rounded"></div>
            </div>
            <div className="ml-auto text-start text-[1rem] font-[500] flex items-center gap-2">
              <div className="flex justify-between w-full items-center relative">
                <div className="w-16 h-6 bg-gray-300 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default function PreviousSelectionMobile() {
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const loadMoreRef = useRef(null);
  const {
    data: selections = { pages: [] },
    isLoading: isSelectionsLoading,
    isError: isSelectionsError,
    refetch: refetchSelections,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["previous-selections", selectedSubscription],
    queryFn: ({ pageParam = 0 }) =>
      api.getPreviousSelections({
        showPrevious: true,
        page: pageParam,
        subscriptionId: selectedSubscription,
        limit: 10,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === 10 ? pages?.length : undefined,
    initialPageParam: 0, // Add this line
  });
  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };
  // console.log(selections);
  useEffect(() => {
    if (hasNextPage && loadMoreRef.current) {
      // Add null check for loadMoreRef.current
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleLoadMore();
        }
      });
      observer.observe(loadMoreRef.current);
      return () => observer.disconnect();
    }
  }, [hasNextPage]);

  const { data: subscriptions = [], isLoading } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => api.getSubscriptions(),
  });

  const handleSubscriptionClick = (id: string) => {
    setSelectedSubscription(id);
  };

  return (
    <div className="p-7 pt-6 bg-background">
      <div className="items-center flex gap-4 mb-[2.25rem]">
        <Link to="/selection">
          <chevronIcon.ReactComponent className="rotate-[90deg]" />
        </Link>
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Previous selections
        </h1>
      </div>
      <div className="rounded-[24px] w-full">
        <SubscriptionPicker
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          handleSubscriptionClick={handleSubscriptionClick}
          className="bg-white/80"
        />
        <div className="w-full mt-3">
          {/* do data 20 times */}
          <div className="grid gap-4">
            {!isLoading &&
              selections.pages.map((page, i) =>
                // Render each item in each page
                page?.map((selection) => (
                  <Data
                    key={selection.id}
                    data={selection}
                    refetch={refetchSelections}
                  />
                ))
              )}
            {/* if hasNextpage automatically call handleLoadMore */}
            {hasNextPage && (
              <button
                onClick={handleLoadMore}
                ref={loadMoreRef}
                className="rounded-[12px] p-[1.25rem] w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
              >
                <Spinner />
              </button>
            )}
            {(isLoading || isSelectionsLoading) && <LoadingSkeleton />}
          </div>
        </div>
      </div>
    </div>
  );
}

function Data({
  data,
  refetch,
}: {
  data: {
    id: string;
    teams: any[];
    day: string;
    sports: string;
    date: string;
    wager: string;
    winningProbability: number;
    winningStatus: number;
  };
  refetch: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleResultClick = (result: 1 | -1 | 2) => {
    api.patchSelection(data.id, { winningStatus: result }).then(
      () => {
        refetch();
        setIsOpen(false);
        toast.success("Result updated successfully");
      },
      (error) => {
        console.log(error);
        toast.error("Failed to update result");
      }
    );
  };
  //   class="rounded-md bg-white/80 p-4 flex flex-col gap-3 max-w-[92vw]"
  return (
    <div className="flex-col px-[1.25rem] py-4 border-solid rounded-md bg-white/80 p-4 flex flex-col gap-3 max-w-[92vw]">
      <div className="flex justify-between">
        <div className="text-start text-[1rem] font-[500] flex flex-col justify-center gap-2">
          {data?.teams?.map((team, i) => (
            <span key={i}>{team?.name}</span>
          ))}
        </div>
        <div className="text-start text-[1rem] font-[500] flex flex-col justify-center items-end gap-2">
          {data?.teams?.map((team, i) => (
            <div
              key={i}
              className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center"
            >
              <img
                src={sports.find((sport) => sport.name === team?.sports)?.icon}
                alt="NBA"
                className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
              />
              <span className="font-[500] text-[0.75rem]">{team?.sports}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/3 text-start text-[1rem] font-[500] flex items-center gap-2">
          <span>{moment(data.day).format("MM/DD/YY")}</span>
        </div>
        <div className="w-1/6 text-start text-[1rem] font-[500] flex items-center gap-2">
          <span
            className={`${
              data.winningProbability >= 70 && "text-[#00AB11] font-[700]"
            }`}
          >
            {data.winningProbability}%
          </span>
        </div>
        <div className="ml-auto text-start text-[1rem] font-[500] flex items-center gap-2">
          <div className="flex justify-between w-full items-center relative">
            <button
              className={`${
                data.winningStatus === 1 ? "text-[#00AB11]" : "text-[#EE3900]"
              } flex gap-1 items-center`}
              onClick={() => setIsOpen(!isOpen)}
            >
              {/* 0 not specified 1 win -1 lose */}
              {data.winningStatus === 0 ? (
                <span className="text-[#6A6A6A]">Not specified</span>
              ) : data.winningStatus === 1 ? (
                <span>Win</span>
              ) : data.winningStatus === 2 ? (
                <span className="text-[#6a6a6a]">Push</span>
              ) : (
                <span>Loss</span>
              )}
              <chevronIcon.ReactComponent className="w-[1.125rem] h-[1.125rem] opacity-[0.5]" />
            </button>
            <Dropdown
              isOpen={isOpen}
              setIsOpen={() => setIsOpen(!isOpen)}
              padding="6px"
            >
              <div className="place-content-start">
                <button
                  className="text-[#66BA3C] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                  onClick={() => handleResultClick(1)}
                >
                  Win
                </button>
                <button
                  className="text-[#6a6a6a] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                  onClick={() => handleResultClick(2)}
                >
                  Push
                </button>
                <button
                  className="text-[#D02929] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                  onClick={() => handleResultClick(-1)}
                >
                  Loss
                </button>
              </div>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}
