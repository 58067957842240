import NCAABIcon from "assets/sports/ncaab.png";
import NBAIcon from "assets/sports/nba.png";
import NFLIcon from "assets/sports/nfl.png";
import MLBIcon from "assets/sports/mlb.png";
import { useEffect, useState } from "react";
import { Dropdown, SelectionButton } from "module/teams/Teams";
const chevronBlackIcon = require("assets/images/chevron-black.svg");

export function SportSelection({
  onChange,
  value,
  setTeam,
  setForm,
  type,
}: {
  onChange: (value: string) => void;
  value: string;
  setTeam: any;
  setForm: any;
  type?: string;
}) {
  const [sportDropdown, setSportDropdown] = useState(false);
  const handleSportDropdown = () => setSportDropdown(!sportDropdown);

  return (
    <>
      <div className="w-full relative items-center sport-selection-dropdown">
        <button
          id="sport-selection"
          className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]"
          onClick={handleSportDropdown}
        >
          <span
            className={`${
              value === "" ? "text-[#8D8E92]" : "text-[black]"
            } rounded-[8px] flex gap-3 items-center`}
          >
            {!value ? (
              <>Select</>
            ) : (
              <>
                {
                  <img
                    src={
                      value === "NCAAB"
                        ? NCAABIcon
                        : value === "NCAAF"
                        ? NCAABIcon
                        : value === "NFL"
                        ? NFLIcon
                        : value === "MLB"
                        ? MLBIcon
                        : value === "NBA"
                        ? NBAIcon
                        : ""
                    }
                    alt="chevron"
                    className="w-[1.75rem] h-[1.75rem] inline-block"
                  />
                }
                {value}
              </>
            )}
          </span>
          <img
            src={chevronBlackIcon.default}
            alt="chevron"
            className={`absolute right-[1.125rem] top-1/2 transform -translate-y-1/2 ${
              sportDropdown ? "rotate-180" : "rotate-0"
            }`}
          />
        </button>
        <Dropdown
          isOpen={sportDropdown}
          handleDropdown={handleSportDropdown}
          padding="8px"
        >
          <SelectionButton
            title="NFL"
            onClick={() => {
              onChange("NFL");
              setSportDropdown(false);
              setTeam("");
              setForm((prev: any) => ({ ...prev, teamId: "" }));
            }}
            icon={NFLIcon}
          />
          <SelectionButton
            title="NBA"
            onClick={() => {
              onChange("NBA");
              setSportDropdown(false);
              setTeam("");
              setForm((prev: any) => ({ ...prev, teamId: "" }));
            }}
            icon={NBAIcon}
          />
          {type !== "TTT" ? (
            <SelectionButton
              title="MLB"
              onClick={() => {
                onChange("MLB");
                setSportDropdown(false);
                setTeam("");
                setForm((prev: any) => ({ ...prev, teamId: "" }));
              }}
              icon={MLBIcon}
            />
          ) : null}
          <SelectionButton
            title="NCAAF"
            onClick={() => {
              onChange("NCAAF");
              setSportDropdown(false);
              setTeam("");
              setForm((prev: any) => ({ ...prev, teamId: "" }));
            }}
            icon={NCAABIcon}
          />
          <SelectionButton
            title="NCAAB"
            onClick={() => {
              onChange("NCAAB");
              setSportDropdown(false);
              setTeam("");
              setForm((prev: any) => ({ ...prev, teamId: "" }));
            }}
            icon={NCAABIcon}
          />
        </Dropdown>
      </div>
      {sportDropdown && (
        <div
          className="fixed inset-0 z-50"
          onClick={() => setSportDropdown(false)}
        ></div>
      )}
    </>
  );
}
