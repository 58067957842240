import Button from "components/Buttons";
import Input from "components/Input";
import Modal from "components/Modal";
import { useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { Dropdown } from "module/teams/Teams";
import Editor from "./components/Editor";
import { api } from "helpers/api/api";
const chevronIcon = require("assets/images/chevron-black.svg");

export default function SpecificEmail({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const [formData, setFormData] = useState({
    subject: "",
    content: "",
    type: "",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    api
      .notifyUsers(formData)
      .then(() => {
        toast.success("Email sent successfully");
        handleModal();
      })
      .catch(() => {
        toast.error("Failed to send email");
      });
  };

  return (
    <Modal isOpen={isOpen} handleModal={handleModal} title="Direct Email">
      <form className="grid gap-4" onSubmit={handleSubmit}>
        <Input
          label="Subject"
          placeholder="Enter Subject"
          onChange={(e) =>
            setFormData({ ...formData, subject: e.target.value })
          }
        />
        <div className="grid gap-1">
          <span
            className="mb-1 text-[0.875rem] font-[400] cursor-pointer"
            onClick={handleDropdown}
          >
            Type
          </span>
          <TypeSelection
            isOpen={isDropdownOpen}
            handleDropdown={handleDropdown}
            onChange={(type) => setFormData({ ...formData, type })}
            value={formData.type}
            options={["ACTIVE_SUBSCRIBERS", "CANCELLED_SUBSCRIBERS"]}
          />
        </div>
        <div className="grid gap-1">
          <span className="mb-1 text-[0.875rem] font-[400]">Content</span>
          <Editor
            value={formData.content}
            onChange={(content) => setFormData({ ...formData, content })}
          />
        </div>

        <Button type="submit" className="w-full">
          Send Email
        </Button>
      </form>
    </Modal>
  );
}

function TypeSelection({
  options,
  onChange,
  value,
  isOpen,
  handleDropdown,
}: {
  options: string[];
  onChange: (value: string) => void;
  value: string;
  isOpen: boolean;
  handleDropdown: () => void;
}) {
  return (
    <div className="relative">
      <button
        className="w-full text-start items-center flex gap-[0.75rem] transition-all p-[1.125rem] rounded-[8px] border-[#E0E0EA] border-[1px] border-solid"
        onClick={handleDropdown}
        type="button"
      >
        <span>{value || "Select Type"}</span>
        <img src={chevronIcon.default} alt="chevron" className="ml-auto" />
      </button>
      <Dropdown isOpen={isOpen} handleDropdown={handleDropdown} padding="8px">
        {options.map((option) => (
          <button
            key={option}
            className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
            onClick={() => {
              onChange(option);
              handleDropdown();
            }}
            type="button"
          >
            <span>
              {option === "ACTIVE_SUBSCRIBERS"
                ? "Active Subscribers"
                : "Cancelled Subscribers"}
            </span>
          </button>
        ))}
      </Dropdown>
      {isOpen && (
        <div className="fixed inset-0 z-50" onClick={handleDropdown}></div>
      )}
    </div>
  );
}
