export function TextInput({
  ...props
}: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className="w-full">
      <input
        {...props}
        className={`!outline-none opacity-80 bg-white w-full focus:bg-white rounded-lg border border-lightGray focus:border-grey2 p-4 text-base text-black ${
          props.className || ""
        }`}
      />
    </div>
  );
}
