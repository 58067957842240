import { motion } from "framer-motion";
import useResponsive from "helpers/useResponsive";
import { useEffect } from "react";
const closeIcon = require("assets/images/x.svg");

export default function Modal({
  children,
  isOpen,
  handleModal,
  title,
  secondaryTitle,
  customTitle,
  zIndex,
  className,
  customWidth,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  handleModal: () => void;
  title?: string;
  secondaryTitle?: React.ReactNode;
  customTitle?: React.ReactNode;
  zIndex?: string;
  className?: string;
  customWidth?: string;
}) {
  // stop scroll when modal is open
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <motion.div
      animate={{
        opacity: isOpen ? 1 : 0,
        display: isOpen ? "block" : "none",
      }}
      exit={{ display: "none" }}
      transition={{ delay: 0.1 }}
    >
      <motion.div
        className={`w-full h-[100%] fixed top-0 left-0 z-[999] overflow-auto`}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -20 }}
        transition={{ duration: 0.3 }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            // width: "440px",
            // width: isMobile ? "95%" : "440px",
            width: isMobile ? "95%" : customWidth || "440px",
            borderRadius: "16px",
            background: "white",
            padding: "1.75rem",
            zIndex: zIndex || "101",
            backdropFilter: "blur(1000px)",
          }}
          className="shadow-xl"
        >
          <div className="flex justify-between mb-[1.875rem]">
            <h2 className="text-[1.125rem] font-[700]">{title}</h2>
            <div className="flex gap-[0.625rem]">
              {secondaryTitle && secondaryTitle}
              <button onClick={handleModal}>
                <closeIcon.ReactComponent />
              </button>
            </div>
          </div>
          <div className="max-h-[calc(100vh-250px)] overflow-auto">
            {children}
          </div>
        </div>
        {/* all over behind dropdown click closes the dropdown */}
        {isOpen && (
          <div
            className="fixed top-0 left-0 w-full z-[100] h-[100vh] opacity-[0.3] bg-[black]"
            onClick={handleModal}
          />
        )}
      </motion.div>
    </motion.div>
  );
}
