import { useState } from "react";

import DatePicker from "react-datepicker";
import { ReactComponent as ClockIcon } from "../../assets/images/clock.svg";

import "react-datepicker/dist/react-datepicker.css";

export function TimePicker({
  value,
  onChange,
}: {
  value: any;
  onChange: (value: any) => void;
}) {
  return (
    <div className="date-picker relative">
      <DatePicker
        onChange={onChange}
        selected={value}
        icon={<ClockIcon />}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        // timeClassName={() => "w-[200px]"}
        className="!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 p-4 text-base text-black [&>div]:!border-none"
      />
      <div className="absolute right-4 top-0 bottom-0 flex items-center pointer-events-none">
        <ClockIcon />
      </div>
    </div>
  );
}
