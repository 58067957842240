import toast from "react-hot-toast";
import { ReactComponent as Check } from "../assets/images/check.svg";
import { ReactComponent as Cross } from "../assets/images/x.svg";

export function ShowToast({
  message,
  type,
}: {
  message: string;
  type: "success" | "error";
}) {
  toast.custom((t) => (
    <div
      className={`md:max-w-[50vw] pl-2 pr-5 py-2 ${
        type === "success" ? "bg-[#E2FFEC]" : "bg-red-50"
      } rounded-[6px] border ${
        type === "success" ? "border-[rgba(0,156,32,0.32)]" : "border-red-600"
      } justify-start items-center gap-1.5 flex`}
    >
      {type === "success" ? (
        <div className="bg-[#009C20] flex justify-center items-center w-6 h-6 rounded-full">
          <Check className="w-3.5 h-3.5 relative" />
        </div>
      ) : (
        <div className="bg-[#F54343] flex justify-center items-center w-6 h-6 rounded-full">
          <Cross className="w-3.5 h-3.5 relative [&>path]:stroke-white" />
        </div>
      )}
      <div className="text-[#2A2B2F] text-base font-normal leading-normal">
        {message}
      </div>
    </div>
  ));
}
