import Dropdown from "components/Dropdown";
const chevronIcon = require("assets/images/chevron-black.svg");

export function ResultButton({
  handleResultClick,
  setIsOpen,
  isOpen,
  selection,
}) {
  return (
    <div className="relative ml-auto flex justify-center w-full">
      {selection?.winningStatus === 0 ? (
        <button
          className="text-primary text-[0.875rem] font-[600]"
          onClick={() => setIsOpen(!isOpen)}
        >
          Add Result
        </button>
      ) : (
        <button
          className={`${
            selection?.winningStatus === 1 ? "text-[#00AB11]" : "text-[#EE3900]"
          } font-[500] flex items-center gap-1`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selection?.winningStatus === 1 ? "Win" : "Loss"}
          <chevronIcon.ReactComponent className="w-[1.125rem] h-[1.125rem] opacity-[0.5]" />
        </button>
      )}

      <Dropdown
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(!isOpen)}
        padding="6px"
      >
        <div className="place-content-start">
          <button
            className="text-[#66BA3C] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
            onClick={() => handleResultClick(1)}
          >
            Win
          </button>
          <button
            className="text-[#6a6a6a] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
            onClick={() => handleResultClick(2)}
          >
            Push
          </button>
          <button
            className="text-[#D02929] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
            onClick={() => handleResultClick(-1)}
          >
            Loss
          </button>
        </div>
      </Dropdown>
    </div>
  );
}
