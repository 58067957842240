import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Button from "components/Buttons";
import Dropdown from "components/Dropdown";
import Modal from "components/Modal";
import Spinner from "components/Spinner";
import { api } from "helpers/api/api";
import { sports } from "helpers/constant";
import { SubscriptionPicker } from "module/selection/components/SubscriptionPicker";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
const chevronIcon = require("assets/images/chevron-black.svg");
const deleteIcon = require("assets/images/delete.svg");

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <div
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b w-full flex"
        >
          <td className="name pl-5 w-1/3">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
          <td className="name pl-5 w-1/3">
            <div className="flex items-center gap-3">
              <div className="h-10" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
          <td className="name pl-5 w-1/3">
            <div className="flex items-center gap-3">
              <div className="h-10" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
        </div>
      ))}
    </>
  );
}

export default function PreviousSelection() {
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedSelection, setSelectedSelection] = useState("");
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const loadMoreRef = useRef(null);
  const {
    data: selections = { pages: [] },
    isLoading: isSelectionsLoading,
    isError: isSelectionsError,
    refetch: refetchSelections,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["previous-selections", selectedSubscription],
    queryFn: ({ pageParam = 0 }) =>
      api.getPreviousSelections({
        showPrevious: true,
        page: pageParam,
        subscriptionId: selectedSubscription,
        limit: 10,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === 10 ? pages?.length : undefined,
    initialPageParam: 0, // Add this line
  });
  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };
  // console.log(selections);
  useEffect(() => {
    if (hasNextPage && loadMoreRef.current) {
      // Add null check for loadMoreRef.current
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleLoadMore();
        }
      });
      observer.observe(loadMoreRef.current);
      return () => observer.disconnect();
    }
  }, [hasNextPage]);

  const {
    data: subscriptions = [],
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => api.getSubscriptions(),
  });

  const handleSubscriptionClick = (id: string) => {
    setSelectedSubscription(id);
  };

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <DeleteSelectionModal
        isRemoveModalOpen={isRemoveModalOpen}
        setIsRemoveModalOpen={setIsRemoveModalOpen}
        selectedSelection={selectedSelection}
        refetchSelections={refetchSelections}
      />
      <div className="items-center flex gap-4 mb-[2.25rem]">
        <Link to="/selection">
          <chevronIcon.ReactComponent className="rotate-[90deg]" />
        </Link>
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Previous selections
        </h1>
      </div>
      <div className="bg-[#FCFCFA] rounded-[24px] p-[0.75rem] w-full">
        <SubscriptionPicker
          subscriptions={subscriptions}
          selectedSubscription={selectedSubscription}
          handleSubscriptionClick={handleSubscriptionClick}
        />
        <div className="w-full mt-3">
          <div className="bg-[#DCECE0] rounded-[12px] p-[1.25rem] flex">
            <TableHeading title="Date" w="w-1/6" />
            <TableHeading title="Sport" />
            <TableHeading title="Team" w="w-2/6" />
            <TableHeading title="prob %" />
            <TableHeading title="Result" />
            <TableHeading title="Delete" />
          </div>
          {/* do data 20 times */}
          <div className="max-h-[68vh] overflow-auto">
            {!isLoading &&
              selections.pages.map((page, i) =>
                // Render each item in each page
                page?.map((selection) => (
                  <Data
                    key={selection.id}
                    data={selection}
                    refetch={refetchSelections}
                    setSelectedSelection={setSelectedSelection}
                    setIsRemoveModalOpen={setIsRemoveModalOpen}
                  />
                ))
              )}
            {/* if hasNextpage automatically call handleLoadMore */}
            {hasNextPage && (
              <button
                onClick={handleLoadMore}
                ref={loadMoreRef}
                className="rounded-[12px] p-[1.25rem] w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
              >
                <Spinner />
              </button>
            )}
            {isLoading && <LoadingSkeleton />}
          </div>
        </div>
      </div>
    </div>
  );
}

function Data({
  data,
  refetch,
  setSelectedSelection,
  setIsRemoveModalOpen,
}: {
  data: {
    id: string;
    teams: any[];
    day: string;
    sports: string;
    date: string;
    wager: string;
    winningProbability: number;
    winningStatus: number;
    type: string;
  };
  refetch: () => void;
  setSelectedSelection: (id: string) => void;
  setIsRemoveModalOpen: (open: boolean) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleResultClick = (result: 1 | -1 | 2) => {
    api.patchSelection(data.id, { winningStatus: result }).then(
      () => {
        refetch();
        setIsOpen(false);
        toast.success("Result updated successfully");
      },
      (error) => {
        console.log(error);
        toast.error("Failed to update result");
      }
    );
  };

  console.log(data);

  return (
    <div className="flex px-[1.25rem] py-4">
      <div className="text-start w-1/6 text-[1rem] font-[500] flex items-center gap-2">
        <span>{data.day ? moment(data.day).format("MM/DD/YY") : ""}</span>
      </div>
      <div className="text-start w-1/5 text-[1rem] font-[500] flex justify-center gap-2 flex-col">
        {data?.type !== "I" ? (
          data?.teams?.map((team, i) => (
            <div
              key={i}
              className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center"
            >
              <img
                src={sports.find((sport) => sport.name === team?.sports)?.icon}
                alt="NBA"
                className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
              />
              <span className="font-[500] text-[0.75rem]">{team?.sports}</span>
            </div>
          ))
        ) : (
          <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
            <img
              src={
                sports.find((sport) => sport.name === data?.teams?.[0]?.sports)
                  ?.icon
              }
              alt="NBA"
              className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
            />
            <span className="font-[500] text-[0.75rem]">
              {data?.teams?.[0]?.sports}
            </span>
          </div>
        )}
      </div>
      <div className="text-start w-2/6 text-[1rem] font-[500] flex flex-col  justify-center gap-2">
        {data?.teams?.map((team, i) => (
          <span>{team.name}</span>
        ))}
      </div>
      <div className="text-start w-1/5 text-[1rem] font-[500] flex items-center gap-2">
        <span
          className={`${
            data.winningProbability >= 70 && "text-[#00AB11] font-[700]"
          }`}
        >
          {data.winningProbability}%
        </span>
      </div>
      <div className="text-start w-1/5 text-[1rem] font-[500] flex items-center gap-2">
        <div className="flex justify-between w-full items-center relative">
          <button
            className={`${
              data.winningStatus === 1 ? "text-[#00AB11]" : "text-[#EE3900]"
            } flex gap-1 items-center`}
            onClick={() => setIsOpen(!isOpen)}
          >
            {/* 0 not specified 1 win -1 lose */}
            {data.winningStatus === 0 ? (
              <span className="text-[#6A6A6A]">Not specified</span>
            ) : data.winningStatus === 1 ? (
              <span>Win</span>
            ) : data.winningStatus === 2 ? (
              <span className="text-[#6a6a6a]">Push</span>
            ) : (
              <span>Loss</span>
            )}
            <chevronIcon.ReactComponent className="w-[1.125rem] h-[1.125rem] opacity-[0.5]" />
          </button>
          <Dropdown
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(!isOpen)}
            padding="6px"
          >
            <div className="place-content-start">
              <button
                className="text-[#66BA3C] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                onClick={() => handleResultClick(1)}
              >
                Win
              </button>
              <button
                className="text-[#6a6a6a] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                onClick={() => handleResultClick(2)}
              >
                Push
              </button>
              <button
                className="text-[#D02929] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                onClick={() => handleResultClick(-1)}
              >
                Loss
              </button>
            </div>
          </Dropdown>
        </div>
      </div>
      <button
        className="text-start w-1/5 text-[1rem] font-[500] flex items-center gap-2"
        onClick={() => {
          setSelectedSelection(data.id);
          setIsRemoveModalOpen(true);
        }}
      >
        <deleteIcon.ReactComponent />
        <span className="text-[#C30000]">Delete</span>
      </button>
    </div>
  );
}
function TableHeading({ title, w }: { title: string; w?: string }) {
  return (
    <div className={`text-start ${w ? w : "w-1/5"}`}>
      <span className="uppercase text-[0.9375rem] font-[500]">{title}</span>
    </div>
  );
}

export function DeleteSelectionModal({
  isRemoveModalOpen,
  setIsRemoveModalOpen,
  selectedSelection,
  refetchSelections,
}: {
  isRemoveModalOpen: boolean;
  setIsRemoveModalOpen: (open: boolean) => void;
  selectedSelection: string;
  refetchSelections: () => void;
}) {
  const handleSubmit = () => {
    api.deleteSelection(selectedSelection).then(
      () => {
        refetchSelections();
        setIsRemoveModalOpen(false);
        toast.success("Selection deleted successfully");
      },
      (error) => {
        console.log(error);
        toast.error("Failed to delete selection");
      }
    );
  };

  return (
    <Modal
      title="Delete Selection"
      isOpen={isRemoveModalOpen}
      handleModal={() => {
        setIsRemoveModalOpen(false);
      }}
    >
      <p className="text-[#33363F] text-base">
        Are you sure you want to delete this selection?
      </p>
      <div className="flex gap-3 mt-6 flex-col md:flex-row">
        <Button
          variant="secondary"
          className="!text-base !rounded-full [&_.outline-variant]:!rounded-full flex-1"
          onClick={() => {
            setIsRemoveModalOpen(false);
          }}
        >
          Go Back
        </Button>
        <Button
          className="!text-base !rounded-full [&_.outline-variant]:!rounded-full flex-1"
          onClick={handleSubmit}
        >
          Yes, Delete
        </Button>
      </div>
    </Modal>
  );
}
