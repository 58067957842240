import { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as Logo2 } from "../../assets/images/logo.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import userImage from "../../assets/images/user.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/images/chevron.svg";
import { menuItems } from "./Sidebar";

export function MobileSidebar({
  isOpen,
  onClose,
  user,
}: {
  isOpen?: boolean;
  onClose?: () => void;
  user?: any;
}) {
  const handleLogout = () => {};
  const location = useLocation();
  const isSelected = (path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-20 transform transition duration-300`}
      onClick={onClose}
    >
      <div
        className="flex h-[100vh] w-fit overflow-auto bg-white border-r border-gray-200 flex-col"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="self-stretch pl-6 pr-2 py-5 bg-white border-b border-gray-200 justify-between items-center flex">
          <div className="relative justify-center items-center flex">
            <Logo2 className="w-28 h-10" />
          </div>
        </div>
        <div className="flex-col justify-between gap-5 flex h-[calc(100vh-180px)] flex-1">
          <div className="flex-col justify-start items-start flex w-full">
            {menuItems.map((item, index) => (
              <Link
                to={item.path}
                key={index}
                className={`w-full px-6 py-3.5 bg-white justify-start items-center gap-3 flex group hover:bg-[#D0F3C9] transition duration-75 hover:scale-105 ${
                  isSelected(item.path) ? "!bg-[#D0F3C9]" : ""
                }`}
                onClick={onClose}
              >
                <div className="grow shrink basis-0 text-neutral-800 text-base font-medium leading-tight">
                  {item.title}
                </div>
              </Link>
            ))}
          </div>

          <div className="self-stretch h-28 w-[250px] p-6 bg-white flex-col justify-between items-start gap-2.5 flex">
            <div className="flex-col justify-start items-start gap-2.5 flex w-full">
              <div className="self-stretch px-4 py-3 bg-white rounded-lg border border-gray-200 justify-start items-center gap-2 inline-flex">
                <div className="flex-1 flex items-center gap-2">
                  <div className="w-9 h-9 rounded-full">
                    <img
                      src={user?.profilePic || userImage}
                      alt="profile-pic"
                      className="w-full h-full rounded-full object-fit bg-background p-1"
                    />
                  </div>
                  <div className="flex-col gap-0.5 flex">
                    <span className="text-dark1 text-sm font-medium">
                      John smith
                    </span>
                    <span className="text-dark3 text-xs font-normal">
                      Admin
                    </span>
                  </div>
                </div>
                <button type="button" onClick={handleLogout}>
                  <LogoutIcon className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
