import Input from "components/Input";
import NCAABIcon from "assets/sports/ncaab.png";
import NBAIcon from "assets/sports/nba.png";
import NFLIcon from "assets/sports/nfl.png";
import MLBIcon from "assets/sports/mlb.png";
import Button from "components/Buttons";
import Modal from "components/Modal";
import { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { api } from "helpers/api/api";
import { sports } from "helpers/constant";
import toast from "react-hot-toast";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Spinner from "components/Spinner";
import moment from "moment";
import { TeamDeleteConfirm } from "./TeamDeleteConfirm";
import { ShowToast } from "components/Toast";
import { LoadAnimatedContainer } from "components/AnimatedContainer";
const chevronBlackIcon = require("assets/images/chevron-black.svg");
const deleteIcon = require("assets/images/delete.svg");
const editIcon = require("assets/images/edit.svg");
const uploadIcon = require("assets/images/upload.svg");
const uploadSoloIcon = require("assets/images/upload-solo.svg");

function TeamView({ team, handleEditTeam, handleSelectedTeam }) {
  return (
    <div className="rounded-md bg-white/80 p-4 flex flex-col gap-3 max-w-[92vw]">
      <div className="flex justify-between items-start">
        <div className="flex gap-2.5 items-center">
          <div className="w-[42px] h-[42px] rounded-full">
            <img
              src={team.logo}
              className="rounded-full w-full h-full object-cover"
              alt="logo"
            />
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-medium text-black max-w-[50vw] overflow-hidden text-ellipsis">
              {team.name}
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-8 items-center">
        <div className="flex flex-col justify-start gap-2">
          <span className="text-[#878787] text-xs font-medium">SPORT</span>
          <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
            <img
              src={
                sports.find(
                  (sportItem) => sportItem.name === (team.sports || "NCAAB")
                )?.icon
              }
              alt="NBA"
              className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
            />
            <span className="font-[500] text-[0.75rem]">{team.sports}</span>
          </div>
        </div>
        <div className="flex flex-col justify-start gap-2">
          <span className="text-[#878787] text-xs font-medium">ADDED ON</span>
          <span className="text-black text-sm font-medium">
            {moment.utc(team.createdAt).local().format("MMM DD,YYYY")}
          </span>
        </div>
      </div>
      <div className="flex justify-between w-full items-center border-t border-t-[#E0E0EA] mt-2 pt-4">
        <div className="flex gap-8 flex-1">
          <button
            className="flex gap-2 flex-1 justify-center"
            onClick={() => handleEditTeam(team)}
            type="button"
          >
            <editIcon.ReactComponent />
            <span>Edit</span>
          </button>
          <button
            className="flex gap-2 flex-1 justify-center"
            onClick={() => handleSelectedTeam(team)}
            type="button"
          >
            <deleteIcon.ReactComponent />
            <span className="text-[#C30000]">Delete</span>
          </button>
        </div>
      </div>
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <div
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b w-full flex"
        >
          <td className="name pl-5 w-1/3">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
          <td className="name pl-5 w-1/3">
            <div className="flex items-center gap-3">
              <div className="h-10" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
          <td className="name pl-5 w-1/3">
            <div className="flex items-center gap-3">
              <div className="h-10" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
              </div>
            </div>
          </td>
        </div>
      ))}
    </>
  );
}

export default function TeamManagement() {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [search, setSearch] = useState("");
  const loadMoreRef = useRef(null);
  const {
    data: teams = { pages: [] },
    isLoading,
    isError,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["teams", search],
    queryFn: ({ pageParam = 0 }) =>
      api.getTeams({
        showPrevious: true,
        page: pageParam,
        limit: 10,
        searchKeyword: search,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === 10 ? pages?.length : undefined,
    initialPageParam: 0, // Add this line
  });
  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };
  const handleSelectedTeam = (team: any) => {
    setSelectedTeam(team);
    setRemoveUserModal(true);
  };
  const handleEditTeam = (team: any) => {
    setSelectedTeam(team);
    setEditMode(true);
    setIsOpen(true);
  };
  const handleRemoveTeamConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteTeam(selectedTeam.id);
      setDeleting(false);
      if (response.status === 200) {
        ShowToast({
          type: "success",
          message: "Team removed successfully",
        });
        refetch();
        setRemoveUserModal(null);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };
  const handleIsOpen = () => {
    setEditMode(false);
    setIsOpen(!isOpen);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          // users.length < total &&
          !isLoading
        ) {
          handleLoadMore();
        }
      },
      { threshold: 0.1 }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isLoading, handleLoadMore]);

  return (
    <div className="bg-background h-[100vh] overflow-auto p-5 lg:p-7 pt-6">
      <AddTeam
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        refetch={refetch}
        editMode={editMode}
        editData={selectedTeam}
      />
      <TeamDeleteConfirm
        title={`Are you sure you want to remove ‘${selectedTeam?.name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveTeamConfirm}
        loading={deleting}
      />
      <div className="flex justify-between mb-5 items-center">
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Sport teams
        </h1>
        <Button
          className="px-[1.75rem] py-[0.875rem] text-[1rem] font-[500]"
          onClick={handleIsOpen}
        >
          Add a team
        </Button>
      </div>
      <LoadAnimatedContainer className="lg:hidden">
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-2">
          <div className="flex items-center gap-2 flex-1 [&>div]:w-full">
            <Input
              placeholder="Search"
              className="p-[1rem]"
              search
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 mt-3 h-[calc(100vh-225px)] overflow-auto">
          {!isLoading &&
            teams?.pages.map((page, i) =>
              page.map((team, j) => (
                <TeamView
                  key={j}
                  team={team}
                  handleSelectedTeam={handleSelectedTeam}
                  handleEditTeam={handleEditTeam}
                />
              ))
            )}
          {hasNextPage && (
            <button
              onClick={handleLoadMore}
              ref={loadMoreRef}
              className="rounded-[12px] p-[1.25rem] w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
            >
              <Spinner />
            </button>
          )}
          {isLoading && <LoadingSkeleton />}
        </div>
      </LoadAnimatedContainer>

      <div className="hidden lg:block bg-[white] rounded-[12px] p-[0.75rem] w-full">
        <div className="flex justify-between w-full">
          <Input
            placeholder="Search"
            className="p-[1rem]"
            search
            onChange={handleChange}
          />
        </div>
        <div className="w-full mt-3">
          <div className="bg-[#DCECE0] rounded-[8px] p-[1.25rem] flex">
            <TableHeading title="Team name" w="1/3" />
            <TableHeading title="Sport" w="1/3" />
            <TableHeading title="Added on" w="1/3" />
          </div>
          {/* do data 20 times */}
          <div className="max-h-[68vh] overflow-auto">
            {!isLoading &&
              teams?.pages.map((page, i) =>
                page.map((team, j) => (
                  <Data
                    key={j}
                    team={team}
                    handleSelectedTeam={handleSelectedTeam}
                    handleEditTeam={handleEditTeam}
                  />
                ))
              )}
            {hasNextPage && (
              <button
                onClick={handleLoadMore}
                ref={loadMoreRef}
                className="rounded-[12px] p-[1.25rem] w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
              >
                <Spinner />
              </button>
            )}
            {isLoading && <LoadingSkeleton />}
          </div>
        </div>
      </div>
    </div>
  );
}

function Data({
  team,
  handleSelectedTeam,
  handleEditTeam,
}: {
  team: any;
  handleSelectedTeam: (team) => void;
  handleEditTeam: (team) => void;
}) {
  return (
    <div className="flex px-[1.25rem] py-4">
      <TableData w="1/3">
        <img
          src={team.logo}
          alt="logo"
          className="w-[2.5rem] h-[2.5rem] rounded-full"
        />
        <span>{team.name}</span>
      </TableData>
      <TableData w="1/3">
        <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
          <img
            src={
              sports.find(
                (sportItem) => sportItem.name === (team.sports || "NCAAB")
              )?.icon
            }
            alt="NBA"
            className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
          />
          <span className="font-[500] text-[0.75rem]">{team.sports}</span>
        </div>
      </TableData>
      <TableData w="1/3">
        <div className="flex justify-between w-full items-center">
          <span>
            {moment.utc(team.createdAt).local().format("MMM DD,YYYY")}
          </span>
          <div className="flex gap-8">
            <button className="flex gap-2" onClick={() => handleEditTeam(team)}>
              <editIcon.ReactComponent />
              <span>Edit</span>
            </button>
            <button
              className="flex gap-2"
              onClick={() => handleSelectedTeam(team)}
            >
              <deleteIcon.ReactComponent />
              <span className="text-[#C30000]">Delete</span>
            </button>
          </div>
        </div>
      </TableData>
    </div>
  );
}
function TableHeading({ title, w }: { title: string; w?: string }) {
  return (
    <div className={`text-start w-${w}`}>
      <span className="uppercase text-[0.9375rem] font-[500]">{title}</span>
    </div>
  );
}
function TableData({ children, w }: { children: React.ReactNode; w?: string }) {
  return (
    <div
      className={`text-start w-${
        w ? w : "1/5"
      } text-[1rem] font-[500] flex items-center gap-2`}
    >
      {children}
    </div>
  );
}
function AddTeam({
  isOpen,
  handleIsOpen,
  refetch,
  editMode,
  editData,
}: {
  isOpen: boolean;
  handleIsOpen: () => void;
  refetch: () => void;
  editMode?: boolean;
  editData?: any;
}) {
  const [sportDropdown, setSportDropdown] = useState(false);
  const [sport, setSport] = useState("NCAAB");
  const handleSportDropdown = () => setSportDropdown(!sportDropdown);
  const [tempImg, setTempImg] = useState<File>();
  const [imgURL, setImgURL] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [form, setForm] = useState<{
    name: string;
  }>({
    name: "",
  });
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageUploading(true);
    api
      .getImageSignedURL(e.target?.files?.[0]?.type)
      .then((res) => {
        axios
          .put(
            res.uploadUrl,
            // remove data:image/png;base64, from the base64 string
            //   (reader.result as string)?.split(";")[1]
            e.target?.files?.[0],
            { headers: { "Content-Type": e?.target?.files?.[0].type } }
          )
          .then(() => {
            setImgURL(res.accessUrl);
            // setTempImg so it can be shown in the UI
            setTempImg(e?.target?.files?.[0]);
            // refetch();
            setImageUploading(false);
          })
          .catch((err) => {
            console.log(err);
            setImgURL("");
            setImageUploading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setImageUploading(false);
      });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const promise = editMode
      ? api.updateTeam(editData.id, {
          sports: sport,
          logo: imgURL,
          name: form.name,
        })
      : api.addTeam({
          sports: sport,
          logo: imgURL,
          name: form.name,
        });

    promise
      .then(() => {
        setIsLoading(false);
        handleIsOpen();
        toast.success("Team added successfully");
        setImgURL("");
        setForm({ name: "" });
        setTempImg(undefined);
        setSport("NCAAB");
        refetch();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to add team");
      });
  };

  const isDisabled = useMemo(() => {
    if (!form.name || imgURL === "") {
      return true;
    }
    return false;
  }, [form, imgURL]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    // event listener for closing emoji picker
    // outside click
    const handleClickOutside = (event) => {
      const container = document.querySelector(".sport-selection");
      if (
        container &&
        container !== event.target &&
        !container.contains(event.target)
      ) {
        setSportDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sportDropdown]);

  useEffect(() => {
    if (editMode) {
      setForm({
        name: editData?.name,
      });
      setImgURL(editData?.logo);
      setSport(editData?.sports);
    } else {
      setForm({
        name: "",
      });
      setImgURL("");
      setSport("NCAAB");
    }
  }, [editMode]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleModal={() => {
          setForm({ name: "" });
          setSport("NCAAB");
          setImgURL("");
          setTempImg(undefined);
          setSportDropdown(false);
          handleIsOpen();
        }}
        title="Add a team"
        zIndex="999"
      >
        <form className="grid gap-[1.25rem]" onSubmit={handleSubmit}>
          <label htmlFor="profileImg" className="grid gap-3 w-fit">
            <span>Upload logo/icon of the team</span>
            <div className="flex items-center gap-[0.75rem]">
              <input
                type="file"
                onChange={handleUpload}
                id="profileImg"
                className="hidden"
                accept="image/png, image/jpeg"
              />

              {imgURL ? (
                <div className="w-[100px] h-[100px] rounded-full">
                  <img
                    src={imgURL}
                    alt="team"
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
              ) : (
                <div className="relative w-[100px] h-[100px] rounded-full border border-[#59DC3D]">
                  <img
                    src={uploadSoloIcon.default}
                    alt="upload"
                    className="cursor-pointer w-[2rem] h-[2rem] rounded-full object-contain absolute"
                    style={{
                      // center the icon
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  {imageUploading && (
                    <div className="absolute w-full h-full bg-[black]/50 rounded-full">
                      <Spinner />
                    </div>
                  )}
                </div>
              )}
            </div>
          </label>
          <Input
            placeholder="Enter team name"
            label="Team name"
            name="name"
            onChange={handleChange}
            value={form.name}
          />
          <label
            htmlFor="sport-selection"
            className="grid gap-2 sport-selection"
          >
            <span className="text-[0.875rem] font-[400] cursor-pointer">
              Sport
            </span>
            <div className="w-full relative items-center">
              <button
                id="sport-selection"
                className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]"
                onClick={handleSportDropdown}
                type="button"
              >
                <span
                  className={`${
                    sport === "Select" ? "text-[#8D8E92]" : "text-[black]"
                  } rounded-[8px] flex gap-3 items-center`}
                >
                  {
                    <img
                      src={
                        sports.find(
                          (sportItem) =>
                            // sport.name === (selection?.sports || "NCAAB")
                            sportItem.name === (sport || "NCAAB")
                        )?.icon
                      }
                      alt="chevron"
                      className="w-[1.75rem] h-[1.75rem] inline-block"
                    />
                  }
                  {sport}
                </span>
                <img
                  src={chevronBlackIcon.default}
                  alt="chevron"
                  className="absolute right-[1.125rem] top-1/2 transform -translate-y-1/2"
                />
              </button>
              <Dropdown
                isOpen={sportDropdown}
                handleDropdown={() => setSportDropdown(false)}
                padding="8px"
                fromTop={false}
              >
                <SelectionButton
                  title="NCAAB"
                  onClick={() => {
                    setSport("NCAAB");
                    setSportDropdown(false);
                  }}
                  icon={NCAABIcon}
                />
                <SelectionButton
                  title="NCAAF"
                  onClick={() => {
                    setSport("NCAAF");
                    setSportDropdown(false);
                  }}
                  icon={NCAABIcon}
                />
                <SelectionButton
                  title="NFL"
                  onClick={() => {
                    setSport("NFL");
                    setSportDropdown(false);
                  }}
                  icon={NFLIcon}
                />
                <SelectionButton
                  title="MLB"
                  onClick={() => {
                    setSport("MLB");
                    setSportDropdown(false);
                  }}
                  icon={MLBIcon}
                />
                <SelectionButton
                  title="NBA"
                  onClick={() => {
                    setSport("NBA");
                    setSportDropdown(false);
                  }}
                  icon={NBAIcon}
                />
              </Dropdown>
            </div>
          </label>
          <Button
            className="w-fit ml-auto px-[1.75rem] py-[0.875rem] min-w-[7.5rem]"
            disabled={isDisabled}
            isLoading={isLoading}
            type="submit"
          >
            {editMode ? "Update" : "Add"}
          </Button>
        </form>
      </Modal>
    </>
  );
}

export function SelectionButton({
  title,
  onClick,
  icon,
}: {
  title: string;
  onClick: () => void;
  icon: string;
}) {
  return (
    <button
      className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
      onClick={onClick}
      type="button"
    >
      <img
        src={icon}
        alt="NBA"
        className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
      />
      <span className="font-[500]">{title}</span>
    </button>
  );
}
export function Dropdown({
  isOpen,
  handleDropdown,
  className = "",
  children,
  padding,
  fromTop = true,
}: {
  isOpen: boolean;
  handleDropdown: () => void;
  className?: string;
  children: React.ReactNode;
  padding: string;
  fromTop?: boolean;
}) {
  return (
    <>
      <motion.div
        className={`w-full absolute right-0 bg-[white] rounded-[10px] p-2 z-[1003] shadow-xl h-auto p-[1.5rem] overflow-hidden ${className}`}
        initial={{ maxHeight: "0px", padding: "0px" }}
        style={{
          top: fromTop ? "4rem" : "auto",
          bottom: fromTop ? "auto" : "4rem",
          overflow: "auto",
        }}
        animate={{
          maxHeight: isOpen ? "250px" : "0px",
          padding: isOpen ? padding : "0px",
          border: isOpen ? "1px solid #E1E1EC" : "0px solid #E1E1EC",
        }}
        exit={{ maxHeight: "0px", padding: "0px", border: "none" }}
      >
        <motion.div
          className="grid text-center w-full"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -10 }}
          exit={{ opacity: 0, display: "none" }}
        >
          {children}
        </motion.div>
      </motion.div>
    </>
  );
}
