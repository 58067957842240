import Button from "components/Buttons";
import { DatePicker } from "components/DatePicker/DatePicker";
import Input from "components/Input";
import Modal from "components/Modal";
import { ShowToast } from "components/Toast";
import { api } from "helpers/api/api";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";

export default function PromocodeForm({
  isOpen,
  refetch,
  onClose,
}: {
  isOpen: boolean;
  refetch: () => void;
  onClose: () => void;
}) {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<{
    code: string;
    expiredOn: string;
    discount: string;
    id?: string;
  }>({
    code: "",
    expiredOn: "",
    discount: "",
  });

  const handleDateChange = (key: string, value: any) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isDisabled = useMemo(() => {
    if (!form.code || !form.discount || !form.expiredOn) {
      return true;
    }
    return false;
  }, [form]);

  const handleSave = async () => {
    console.log(form);
    if (isDisabled) return;
    try {
      setSaving(true);
      const response = await api.savePromoCode(form);
      console.log(response);
      if ([200, 201].includes(response.status)) {
        refetch();
        onClose();
      }
      setSaving(false);
    } catch (error: any) {
      console.log(error);
      ShowToast({
        message: error?.response?.data?.message || "Something went wrong",
        type: "error",
      });
      setSaving(false);
    }
  };

  useEffect(() => {
    setForm({
      code: "",
      expiredOn: "",
      discount: "",
    });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} handleModal={onClose} title="Add promo code">
      <div className="grid gap-[1.25rem]">
        <Input
          placeholder="Enter code"
          label="Code"
          name="code"
          value={form.code}
          onChange={handleChange}
        />
        <div className="text-[0.875rem] grid gap-2 [&_.react-date-picker]:!static [&_.react-date-picker>span>div]:!top-[17.75rem] [&_.react-date-picker>span>div]:!left-[2rem]">
          <label className="text-sm opacity-80">Expiry date</label>
          <DatePicker
            value={form.expiredOn}
            onChange={(value) => handleDateChange("expiredOn", value)}
            minDate={new Date()}
          />
        </div>

        <Input
          placeholder="Enter discount %"
          label="Discount %"
          type="number"
          value={form.discount}
          name="discount"
          onChange={handleChange}
        />
      </div>
      <Button
        className="px-[1.75rem] py-[0.875rem] float-end text-[1rem] leading-[100%] mt-4"
        onClick={handleSave}
        disabled={isDisabled}
        isLoading={saving}
      >
        Save
      </Button>
    </Modal>
  );
}
