import { Navigate, useNavigate } from "react-router-dom";
import Button from "../../components/Buttons";
import { TextInput } from "../../components/TextInput";
import { Layout } from "./components/Layout";
import React from "react";
import { setToken, setUser as setUserInfo } from "../../helpers/utils";
// import { ReactComponent as AlertIcon } from "../../assets/icons/Alert.svg";
import { Password } from "./components/Password";
import { api } from "helpers/api/api";

export function Login() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("came");
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setLoading(true);
    try {
      const response = await api.login({
        email: data.email,
        password: data.password,
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      if (response.data.status === false) {
        setError(response?.data?.message);
        setLoading(false);
        return;
      }
      const result = response.data;
      console.log(result);
      if (result?.accessToken) {
        setToken(result?.accessToken);
        setUserInfo({ ...result });
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (data.email && data.password) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  if (token && userData?.admin_id) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Layout>
      <div className="flex flex-col w-full md:w-[500px] ">
        <div className="rounded-[24px] p-[40px] bg-white/70 flex flex-col mx-3">
          <div className="text-black text-3xl font-medium">Admin Sign in</div>
          <form
            onSubmit={handleSubmit}
            className="flex-1"
            onChange={handleChange}
          >
            {error && (
              <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                {/* <AlertIcon />  */}
                {error}
              </div>
            )}
            <div className="my-6 flex flex-col gap-5">
              <div className="flex flex-col gap-1.5">
                <span className="text-black/80 text-sm">Email</span>
                <TextInput
                  placeholder="Your email"
                  name="email"
                  className="w-full !p-[14px] !bg-[rgba(255,255,255,0.11)]"
                />
              </div>
              <div className="flex flex-col gap-1.5">
                <span className="text-black/80 text-sm">Password</span>
                <Password
                  placeholder="Your password"
                  name="password"
                  className="!p-[14px] !bg-[rgba(255,255,255,0.11)]"
                  type="password"
                />
              </div>
            </div>
            <Button
              isLoading={loading}
              disabled={disabled}
              className="w-full !rounded-full"
              variant="primary"
              type="submit"
            >
              Sign in
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  );
}
