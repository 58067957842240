import Button from "components/Buttons";
import Input from "components/Input";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

export function BenefitsInputList({ list, onChange }) {
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newList = [...list];
    newList[index] = value;
    onChange(newList);
  };

  const handleAdd = () => {
    onChange([...list, ""]);
  };

  return (
    <div>
      {list.map((item, index) => (
        <div className="relative">
          <Input
            placeholder="Enter"
            value={item}
            onChange={(e) => handleChange(e, index)}
            key={index}
            className="pr-12"
          />
          <button
            onClick={() => onChange(list.filter((_, i) => i !== index))}
            className="absolute top-[55%] right-6"
            style={{
              transform: "translate(50%, -50%)",
            }}
          >
            <DeleteIcon />
          </button>
        </div>
      ))}
      <Button variant="link" className="text-primary !px-0" onClick={handleAdd}>
        + Add more
      </Button>
    </div>
  );
}
