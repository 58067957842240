import { DatePicker } from "components/DatePicker/DatePicker";
import { TimePicker } from "components/DatePicker/TimePicker";
import Input from "components/Input";
import { useCallback, useEffect, useState } from "react";
import { SportSelection } from "../SportSelection";
import { Dropdown } from "module/teams/Teams";
import { WagerSelection } from "../WagerSelection";
import { WAGER } from "helpers/constant";

const chevronIcon = require("assets/images/chevron-black.svg");

const wagerList = [WAGER.MoneyLine, WAGER.Spread, WAGER.Total];
const mlbWagerList = [WAGER.MoneyLine, WAGER.RunLine, WAGER.Total];

export default function Individual({
  form,
  setForm,
  teams,
}: {
  form: any;
  setForm: any;
  teams: any[];
}) {
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);
  const handleTeamDropdown = useCallback(
    () => setIsTeamDropdownOpen((prev) => !prev),
    []
  );
  const [team, setTeam] = useState("");
  const [runLine, setRunLine] = useState(false);

  const handleDateChange = (key: string, value: any) => {
    setForm((prev) => ({
      ...prev,
      teams: [
        {
          ...prev.teams[0],
          [key]: value,
        },
      ],
    }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "winningProbability") {
      const num = parseFloat(value);
      // allow to clear
      // allow only 2 decimal places
      // allow only 1-100
      if (value === "" || (num >= 1 && num <= 100)) {
        setForm((prev: any) => ({
          ...prev,
          [name]:
            value && value.split(".")[1]?.length > 2
              ? value.slice(0, value.split(".")[0].length + 3)
              : value
              ? num >= 100
                ? 100
                : value
              : value,
        }));
      }
      return;
    }
    setForm((prev: any) => ({
      ...prev,
      teams: [
        {
          ...prev.teams[0],
          [name]: value,
        },
      ],
    }));
  };

  useEffect(() => {
    // event listener for closing emoji picker
    // outside click
    const handleClickOutside = (event) => {
      const container = document.querySelector(".team-selection");
      if (
        container &&
        container !== event.target &&
        !container.contains(event.target)
      ) {
        setIsTeamDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isTeamDropdownOpen, handleTeamDropdown]);

  return (
    <div className="grid gap-4">
      <div className="text-[0.875rem] grid gap-2">
        <span>Sport</span>
        <SportSelection
          value={form?.teams?.[0].sports}
          onChange={(value) =>
            setForm((prev) => ({
              ...prev,
              teams: [
                {
                  ...form.teams[0],
                  sports: value,
                  wager: "",
                },
              ],
            }))
          }
          setTeam={setTeam}
          setForm={setForm}
        />
      </div>
      <div className="text-[0.875rem] grid gap-2 team-selection">
        <span>Select Team</span>
        <div className="relative w-full">
          <button
            id="sport-selection"
            className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]"
            onClick={handleTeamDropdown}
          >
            <span
              className={`${
                team === "" ? "text-[#8D8E92]" : "text-[black]"
              } rounded-[8px] flex gap-3 items-center`}
            >
              {!team ? (
                <>Select</>
              ) : (
                <>
                  {
                    <img
                      src={
                        teams.find((t) => t.id === form.teams[0].teamId)?.logo
                      }
                      alt="chevron"
                      className="w-[1.75rem] h-[1.75rem] inline-block rounded-full"
                    />
                  }
                  {team}
                </>
              )}
            </span>
            <img
              src={chevronIcon.default}
              alt="chevron"
              className={`absolute right-[1.125rem] top-1/2 transform -translate-y-1/2 ${
                isTeamDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
            />
          </button>
          {isTeamDropdownOpen && (
            <TeamSelection
              teams={teams}
              isOpen={isTeamDropdownOpen}
              handleDropdown={handleTeamDropdown}
              sport={form.teams[0].sports}
              setForm={setForm}
              setTeamName={setTeam}
            />
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-1/2 relative">
          <label className="text-sm opacity-80">Date</label>
          <DatePicker
            value={form.teams[0].date}
            onChange={(value) => handleDateChange("date", value)}
            minDate={new Date()}
          />
        </div>
        <div className="w-1/2 relative">
          <label className="text-sm opacity-80">Time</label>
          <TimePicker
            value={form.teams[0].time}
            onChange={(value) => handleDateChange("time", value)}
          />
        </div>
      </div>
      <div className="flex w-full">
        <div
          className={`text-[0.875rem] relative grid gap-2 flex-1 ${
            ![WAGER.MoneyLine, WAGER.RunLine].includes(form.teams[0].wager)
              ? "[&_button]:rounded-r-[0px]"
              : ""
          }`}
        >
          <span>Wager</span>
          <WagerSelection
            options={
              form?.teams?.[0].sports === "MLB" ? mlbWagerList : wagerList
            }
            value={form.teams[0].wager}
            onChange={(wager: string) =>
              setForm((prev) => ({
                ...prev,
                teams: [{ ...prev.teams[0], wager }],
              }))
            }
          />
        </div>
        {form.teams[0].wager === WAGER.RunLine ? (
          <div className={`text-[0.875rem] grid gap-2 w-1/2 relative`}>
            {/* <Input
              placeholder="ex. Over/10.5"
              label="Run Line"
              type="string"
              value={form[0]?.runLine}
              name="spreadPoints"
              onChange={handleChange}
              max={100}
              min={1}
            /> */}
            <label className="text-[#8D8E92] rounded-[8px] flex gap-3 items-center">
              Run Line
            </label>
            <button
              className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-tr-[8px] rounded-br-[8px] h-fit relative"
              onClick={() => {
                setRunLine(!runLine);
              }}
              type="button"
            >
              <span className="rounded-[8px] flex gap-3 items-center">
                {form.teams[0].spreadPoints ||
                  (form.teams[0].spreadPoints === "" && "Select")}
              </span>
              <img
                src={chevronIcon.default}
                alt="chevron"
                className="absolute right-[1.125rem] top-1/2 transform -translate-y-1/2"
              />
            </button>
            {/* have a dropdown field of +1.5 or -1.5 */}
            <Dropdown
              isOpen={runLine}
              handleDropdown={() => {
                setRunLine(!runLine);
              }}
              padding="8px"
              fromTop={false}
            >
              <button
                className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
                onClick={() => {
                  setForm((prev) => ({
                    ...prev,
                    teams: [
                      {
                        ...prev.teams[0],
                        spreadPoints: "+1.5",
                      },
                    ],
                  }));
                  setRunLine(false);
                }}
                type="button"
              >
                <span className="font-[500]">+1.5</span>
              </button>
              <button
                className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
                onClick={() => {
                  setForm((prev) => ({
                    ...prev,
                    teams: [
                      {
                        ...prev.teams[0],
                        spreadPoints: "-1.5",
                      },
                    ],
                  }));
                  setRunLine(false);
                }}
                type="button"
              >
                <span className="font-[500]">-1.5</span>
              </button>
            </Dropdown>
          </div>
        ) : form.teams[0].wager === WAGER.Total ? (
          <div
            className={`text-[0.875rem] grid gap-2 w-1/2 [&>label>div]:border-l-[0px] [&>label>div]:rounded-l-[0px]`}
          >
            <Input
              placeholder="ex. Over/10.5"
              label="Total"
              type="string"
              value={form[0]?.spreadPoints}
              name="spreadPoints"
              onChange={handleChange}
              max={100}
              min={1}
            />
          </div>
        ) : form.teams[0].wager === WAGER.Spread ? (
          <div
            className={`text-[0.875rem] grid gap-2 w-1/2 [&>label>div]:border-l-[0px] [&>label>div]:rounded-l-[0px]`}
          >
            <Input
              placeholder="ex. (-5.5)"
              label="Spread points"
              type="string"
              value={form[0]?.spreadPoint}
              name="spreadPoints"
              onChange={handleChange}
              max={100}
              min={1}
            />
          </div>
        ) : null}
      </div>

      <Input
        placeholder="Enter winning probability (%)"
        label="Winning Probability (%)"
        type="number"
        value={form.winningProbability}
        name="winningProbability"
        onChange={handleChange}
        max={100}
        min={1}
      />
    </div>
  );
}

function TeamSelection({
  teams,
  isOpen,
  handleDropdown,
  sport,
  setForm,
  setTeamName,
}: {
  teams: any[];
  isOpen: boolean;
  handleDropdown: () => void;
  sport: string;
  setForm: any;
  setTeamName: any;
}) {
  return (
    <>
      <Dropdown isOpen={isOpen} handleDropdown={handleDropdown} padding="8px">
        {teams
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((team) => team.sports === sport)
          .map((team) => (
            <SelectButton
              key={team.id}
              team={team}
              setForm={setForm}
              setTeamName={setTeamName}
              handleDropdown={handleDropdown}
            />
          ))}
        {teams.filter((team) => team.sports !== sport).length === 0 && (
          <span className="my-8">
            No teams available Add a team first or select another sport.
          </span>
        )}
      </Dropdown>
      {isOpen && (
        <div className="fixed inset-0 z-50" onClick={handleDropdown}></div>
      )}
    </>
  );
}

function SelectButton({
  team,
  setForm,
  setTeamName,
  handleDropdown,
}: {
  team: any;
  setForm: any;
  setTeamName: any;
  handleDropdown: () => void;
}) {
  const handleTeamSelection = () => {
    setForm((prev: any) => ({
      ...prev,
      teams: [
        {
          ...prev.teams[0],
          teamId: team.id,
          teamName: team.name,
        },
      ],
    }));
    setTeamName(team.name);
    handleDropdown();
  };
  return (
    <button
      className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
      onClick={handleTeamSelection}
      type="button"
    >
      <img
        src={team.logo}
        alt="NBA"
        className="w-[1.75rem] h-[1.75rem] inline-block rounded-full"
      />
      <span className="font-[500]">{team.name}</span>
    </button>
  );
}
