import styled from "styled-components";

export default function Checkbox({
  id,
  label,
  value,
  style,
  ...props
}: {
  id: string;
  label?: string;
  style?: React.CSSProperties;
  value?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <Wrapper
      style={{
        ...style,
      }}
    >
      <label
        htmlFor={id.toLowerCase()}
        className="flex items-center gap-1 container"
      >
        <input
          type="checkbox"
          id={id.toLowerCase()}
          className="w-[1.375rem] h-[1.375rem] stroke-[#8D8E92]"
          checked={value}
          {...props}
        />
        <span>{label || id}</span>
        <span className="checkmark"></span>
      </label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.375rem;
    width: 1.375rem;
    background-color: transparent;
    border: 1px #a4a5a8 solid;
    border-radius: 4px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #73c947;
    border: 1px solid #73c947;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
