import { useEffect, useState } from "react";
import { ShowToast } from "components/Toast";
import { useDebounce } from "helpers/useDebounce";
import { api } from "helpers/api/api";
const LIMIT = 25;

function useUsers() {
  const [hasData, setHasData] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    // page: 1,
    keyword: "",
    // status: "active",
    limit: LIMIT,
    sort_by: "",
    sort_order: "",
    type: "",
    showActive: true,
  });

  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedFilters = useDebounce(state, 500);

  const fetchUsers = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      // const { data, total } = await api.getUsers({
      //   ...params,
      //   ...pageInfo,
      // });
      // Todo: remove this line after api is ready for total
      const data = await api.getUsers({
        ...params,
        ...pageInfo,
      });
      setIsLoading(false);
      if (data.length < pagination.limit) {
        setHasData(false);
      }
      // console.log("data", data, pageInfo, params);
      // filter deleted question
      console.log("refetchId", refetchId, isDeleted, refetchAll);
      if (isDeleted && refetchId) {
        setData((prev) => {
          console.log(
            "prev",
            prev,
            refetchId,
            prev.filter((item) => item.id !== refetchId)
          );
          return prev.filter((item) => item.id !== refetchId);
        });
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const data = await api.getUsers({
            ...params,
            page: i,
          });
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 0) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (err) {
      setIsLoading(false);
      ShowToast({
        type: "error",
        message: "Failed to fetch users",
      });
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchUsers(debouncedFilters, pagination, refetchId, isDeleted, refetchAll);
  };

  const loadMore = () => {
    // update page only we have data
    if (data?.length > 0 && hasData) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchUsers(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setHasData(true);
    setPagination((prev) => ({ ...prev, page: 0 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    fetchUsers(debouncedFilters);
  }, [debouncedFilters]);

  const users: any[] = data || [];
  return {
    users,
    totalPages: Math.ceil(total / LIMIT),
    total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    loadMore,
    refetch,
    updateFilters: (updates) => handleFilters(updates),
    reset: ({ showActive, type }: { showActive?: boolean; type?: string }) => {
      setData([]);
      setState({
        page: 1,
        keyword: "",
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
        type: type || "",
        showActive: showActive === undefined ? true : showActive,
      });
    },
  };
}

export default useUsers;
