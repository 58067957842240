import { Outlet } from "react-router-dom";
// import { Sidebar } from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Sidebar } from "components/Sidebar";
import { getUser } from "helpers/utils";
import { MobileSidebar } from "components/Sidebar/MobileSidebar";
import { MobileHeader } from "components/Header.tsx/MobileHeader";
import useResponsive from "helpers/useResponsive";
// import { getUser } from "../services/utils";
// import { UserProvider, useUser } from "./profile/context/user-context";

function MainApp() {
  const userInfo = getUser();
  const [collapsed, setCollapsed] = useState(false);
  const { isMobile, isTablet } = useResponsive();

  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  const closeCollapse = () => {
    setCollapsed(false);
  };

  useEffect(() => {
    if (!isMobile) {
      closeCollapse();
    }
  }, [isMobile]);

  return (
    <div className="flex-1 flex bg-background">
      {collapsed && (
        <MobileSidebar
          user={userInfo}
          onClose={() => setCollapsed((prev) => !prev)}
        />
      )}
      {!isMobile && !isTablet && <Sidebar user={userInfo} />}
      <div className="flex-1 bg-background max-h-screen">
        <MobileHeader onCollapse={handleCollapse} />
        <div className="flex-1 bg-background max-h-screen max-w-[1250px] mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export function Main() {
  const token = localStorage.getItem("token");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  if (!token || !userData?.id) {
    window.location.href = "/login";
    return null;
  }

  return <MainApp />;
}
