import Button from "components/Buttons";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import { useEffect, useMemo, useState } from "react";
import Individual from "./components/Individual";
import TTT from "./components/TTT";
import TTP from "./components/TTP";
import { api } from "helpers/api/api";
import { ShowToast } from "components/Toast";
import moment from "moment-timezone";
import { convertToEST, getESTDateTime } from "helpers/utils";
import { WAGER } from "helpers/constant";
import Input from "components/Input";
import { ReactComponent as RadioButton } from "../../assets/images/radio-checked.svg";
import { PickCard } from "./Selection";

export default function AddSelection({
  isAddSelectionOpen,
  handleAddSelection,
  subscriptions,
  teams,
  onClose,
  refetch,
}: {
  subscriptions: any[];
  teams: any[];
  isAddSelectionOpen: boolean;
  handleAddSelection: () => void;
  onClose: () => void;
  refetch?: () => void;
}) {
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<{
    subscriptions: string[];
    day: string;
    note?: string;
    teams: {
      sports: string;
      teamId: string;
      date: string;
      wager: string;
      spreadPoints: string;
      time?: string;
      teamName?: string;
    }[];
    time: string;
    wager: string;
    winningProbability: string;
    type: string;
  }>({
    subscriptions: [],
    day: "",
    teams: [
      {
        sports: "",
        teamId: "",
        date: "",
        wager: "",
        spreadPoints: "",
      },
    ],
    time: "",
    wager: "",
    winningProbability: "",
    type: "I",
  });
  const [confirm, setConfirm] = useState(false);

  const handleSubscriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    if (checked) {
      setForm((prev) => ({
        ...prev,
        subscriptions: [...prev.subscriptions, id],
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        subscriptions: prev.subscriptions.filter((sub) => sub !== id),
      }));
    }
  };

  const handleSave = () => {
    // setSaving(true);
    const teams = form.teams.map((team) => {
      const date = moment.tz(team.date, "EST").format("MMM DD YYYY HH:mm");
      // const time = moment.tz(team.time, "EST").format("HH:mm");

      // console.log("date", date);
      // console.log("time", time);

      // make date into a ISO format but not convert date or time to UTC
      const newDate = moment.tz(`${date}`, "MMM DD YYYY HH:mm", "EST").format();
      console.log(newDate);

      return {
        ...team,
        date: newDate,
      };
    });

    api
      .createSelection({ ...form, teams, day: moment().format("YYYY-MM-DD") })
      .then(() => {
        setSaving(false);
        handleAddSelection();
        ShowToast({
          message: "Selection added",
          type: "success",
        });
        onClose();
        refetch && refetch();
        setConfirm(false);
        setForm({
          subscriptions: [],
          day: "",
          teams: [
            {
              sports: "",
              teamId: "",
              date: "",
              wager: "",
              spreadPoints: "",
            },
          ],
          time: "",
          wager: "",
          winningProbability: "",
          type: "I",
        });
      })
      .catch(() => {
        ShowToast({
          message: "Failed to add selection",
          type: "error",
        });
        setSaving(false);
      });
  };

  const disabled = useMemo(() => {
    if (form.type === "I") {
      return (
        !form.subscriptions.length ||
        !form.teams[0].sports ||
        !form.teams[0].teamId ||
        !form.teams[0].date ||
        !form.teams[0].time ||
        !form.teams[0].wager ||
        !form.winningProbability
      );
    } else if (form.type === "TTT") {
      return (
        !form.subscriptions.length ||
        !form.teams[0]?.sports ||
        !form.teams[0]?.teamId ||
        !form.teams[0]?.date ||
        !form.teams[0]?.wager ||
        !form.teams[0]?.spreadPoints ||
        !form?.teams?.[1]?.sports ||
        !form.teams[1]?.teamId ||
        !form.teams[1]?.date ||
        !form.teams[1]?.wager ||
        !form.teams[1]?.spreadPoints ||
        !form.winningProbability
      );
    } else if (form.type === "TTP") {
      return (
        !form.subscriptions.length ||
        !form.teams[0]?.sports ||
        !form.teams[0]?.teamId ||
        !form.teams[0]?.date ||
        !form.teams[0]?.wager ||
        !form.teams[1] ||
        !form.teams[1]?.sports ||
        !form.teams[1]?.teamId ||
        !form.teams[1]?.date ||
        !form.teams[1]?.wager ||
        !form.winningProbability
      );
    }
  }, [form]);

  return (
    <Modal
      isOpen={isAddSelectionOpen}
      handleModal={onClose}
      title={confirm ? "Preview of selection" : "Add Selection"}
      customWidth="700px"
    >
      {confirm && (
        <>
          <div className="grid gap-5">
            {<PickCard selection={form} refetch={refetch} noResultButton />}
          </div>
          <div className="flex gap-4 ml-auto float-end">
            <Button
              className="px-[1.75rem] !py-[1rem] float-end text-[1rem] leading-[100%] mt-4"
              onClick={() => {
                setForm(() => ({
                  subscriptions: [],
                  day: "",
                  teams: [
                    {
                      sports: "",
                      teamId: "",
                      date: "",
                      wager: "",
                      spreadPoints: "",
                    },
                  ],
                  time: "",
                  wager: "",
                  winningProbability: "",
                  type: "I",
                }));
                setConfirm(false);
              }}
              disabled={disabled || saving}
              variant="secondary"
            >
              Go back
            </Button>
            <Button
              className="px-[1.75rem] !py-[1rem] float-end text-[1rem] leading-[100%] mt-4"
              isLoading={saving}
              onClick={handleSave}
              disabled={disabled || saving}
            >
              Add
            </Button>
          </div>
        </>
      )}
      {!confirm && (
        <>
          <div className="grid gap-[1.25rem]">
            <div className="text-[0.875rem] grid gap-2">
              <span>Type</span>
              <div className="border-[#E1E1EC] border-[1px] border-solid p-[1.125rem] flex flex-wrap w-full gap-4 rounded-[8px]">
                <label
                  htmlFor="1"
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <div className="flex">
                    <input
                      type="radio"
                      id="1"
                      name="subscriptions"
                      value="I"
                      checked={form.type === "I"}
                      className={`w-[22px] h-[22px] cursor-pointer ${
                        form.type === "I" ? "opacity-[0] !h-0 !w-0" : ""
                      }`}
                      onChange={() => {
                        setForm(() => ({
                          subscriptions: [],
                          day: "",
                          teams: [
                            {
                              sports: "",
                              teamId: "",
                              date: "",
                              wager: "",
                              spreadPoints: "",
                            },
                          ],
                          time: "",
                          wager: "",
                          winningProbability: "",
                          type: "I",
                        }));
                      }}
                    />
                    {form.type === "I" ? <RadioButton /> : null}
                  </div>
                  <span>Individual</span>
                </label>
                <label
                  htmlFor="2"
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <div className="flex">
                    <input
                      type="radio"
                      id="2"
                      name="subscriptions"
                      value="TTT"
                      checked={form.type === "TTT"}
                      className={`w-[22px] h-[22px] cursor-pointer ${
                        form.type === "TTT" ? "opacity-[0] !h-0 !w-0" : ""
                      }`}
                      onChange={() => {
                        setForm(() => ({
                          subscriptions: [],
                          day: "",
                          teams: [
                            {
                              sports: "",
                              teamId: "",
                              date: "",
                              wager: "",
                              spreadPoints: "",
                            },
                          ],
                          time: "",
                          wager: "",
                          winningProbability: "",
                          type: "TTT",
                        }));
                      }}
                    />

                    {form.type === "TTT" ? <RadioButton /> : null}
                  </div>
                  <span>Two team teaser</span>
                </label>
                <label
                  htmlFor="3"
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <div className="flex">
                    <input
                      type="radio"
                      id="3"
                      name="subscriptions"
                      value="TTP"
                      checked={form.type === "TTP"}
                      className={`w-[22px] h-[22px] cursor-pointer ${
                        form.type === "TTP" ? "opacity-[0] !h-0 !w-0" : ""
                      }`}
                      onChange={() =>
                        setForm(() => ({
                          subscriptions: [],
                          day: "",
                          teams: [
                            {
                              sports: "",
                              teamId: "",
                              date: "",
                              wager: WAGER.MoneyLine,
                              spreadPoints: "",
                            },
                            {
                              sports: "",
                              teamId: "",
                              date: "",
                              wager: WAGER.MoneyLine,
                              spreadPoints: "",
                            },
                          ],
                          time: "",
                          wager: "",
                          winningProbability: "",
                          type: "TTP",
                        }))
                      }
                    />

                    {form.type === "TTP" ? <RadioButton /> : null}
                  </div>
                  <span>Two team parlay</span>
                </label>
              </div>
            </div>
            <div className="text-[0.875rem] grid gap-2">
              <span>Select subscription type adding for</span>
              <div className="border-[#E1E1EC] border-[1px] border-solid p-[1.125rem] flex flex-wrap w-full gap-4 rounded-[8px]">
                {subscriptions.map((subscription) => (
                  <Checkbox
                    id={subscription.id}
                    key={subscription.id}
                    label={subscription.name}
                    style={{
                      backgroundColor: subscription.bgColor,
                      color: subscription.textColor,
                      borderRadius: "8px",
                      padding: "12px 16px",
                      fontWeight: "600",
                      // fontSize: "12px",
                    }}
                    onChange={handleSubscriptionChange}
                    value={
                      form.subscriptions.includes(subscription.id) as
                        | (false & readonly string[])
                        | (true & readonly string[])
                        | undefined
                    }
                  />
                ))}
              </div>
            </div>
            {/* <Individual form={form} setForm={setForm} teams={teams} /> */}
            {form.type === "I" ? (
              <Individual form={form} setForm={setForm} teams={teams} />
            ) : form.type === "TTT" ? (
              <TTT form={form} setForm={setForm} teams={teams} />
            ) : form.type === "TTP" ? (
              <TTP form={form} setForm={setForm} teams={teams} />
            ) : null}
            <div className="text-[0.875rem] grid gap-2">
              <span>Note (Optional)</span>
              <Input
                type="text"
                className="p-[1.125rem] w-full rounded-[8px]"
                value={form.note}
                maxLength={255}
                max={255}
                onChange={(e) =>
                  setForm((prev) => ({
                    ...prev,
                    note: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <Button
            className="px-[1.75rem] py-[0.875rem] float-end text-[1rem] leading-[100%] mt-4"
            isLoading={saving}
            onClick={() => {
              setConfirm(true);
              // get team logo
              (form.type === "TTT" || form.type === "TTP") &&
                api.getTeam(form.teams[0].teamId).then((res) => {
                  setForm((prev) => ({
                    ...prev,
                    teams: [
                      {
                        ...prev.teams[0],
                        name: res.name,
                        logo: res.logo,
                        date: convertToEST(
                          prev?.teams?.[0]?.date,
                          prev?.teams?.[0]?.time as string
                        ),
                      },
                      {
                        ...prev.teams[1],
                      },
                    ],
                  }));
                });

              (form.type === "TTT" || form.type === "TTP") &&
                api.getTeam(form.teams[1]?.teamId).then((res) => {
                  setForm((prev) => ({
                    ...prev,
                    teams: [
                      {
                        ...prev.teams[0],
                      },
                      {
                        ...prev.teams[1],
                        name: res.name,
                        logo: res.logo,
                        date: convertToEST(
                          prev?.teams?.[1]?.date,
                          prev?.teams?.[1]?.time as string
                        ),
                      },
                    ],
                  }));
                });
              form.type === "I" &&
                api.getTeam(form.teams[0]?.teamId).then((res) => {
                  setForm((prev) => ({
                    ...prev,
                    teams: [
                      {
                        ...prev.teams[0],
                        name: res.name,
                        logo: res.logo,
                        date: convertToEST(
                          prev?.teams?.[0]?.date,
                          prev?.teams?.[0]?.time as string
                        ),
                      },
                    ],
                  }));
                });
            }}
            disabled={disabled || saving}
          >
            Save
          </Button>
        </>
      )}
    </Modal>
  );
}
