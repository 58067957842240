import Spinner from "./Spinner";

export default function Button({
  variant = "primary",
  children,
  className,
  disabled,
  type = "button",
  isLoading,
  onClick,
  ref,
  scaleAnimated = false,
}: {
  variant?: "primary" | "secondary" | "icon" | "link";
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  type?: "submit" | "button";
  isLoading?: boolean;
  onClick?: () => void;
  ref?: any;
  scaleAnimated?: boolean;
}) {
  return (
    <button
      disabled={disabled || isLoading}
      type={type}
      className={`${className} flex items-center justify-center gap-2 bg-primary px-7 py-[14px] rounded-[4rem] text-[0.875rem] font-[500] h-fit transition-all disabled:bg-[#C3C3C3] disabled:text-[#555] outline-none ${
        scaleAnimated ? "transition duration-75 hover:scale-105" : ""
      }
      ${variant === "primary" ? "hover:bg-[#AAEC87]" : ""}
        ${
          variant === "secondary"
            ? "outline-variant bg-transparent shadow-button border-[1px] border-primary hover:opacity-70 transition-all outline-none"
            : ""
        }
        ${variant === "icon" ? "bg-transparent" : ""}
      ${variant === "link" ? "bg-transparent" : ""}
        
        `}
      onClick={onClick}
      ref={ref}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
}
