import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
// import { ReactComponent as Ellipse } from "../../../assets/images/ellipse.svg";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="h-[100vh] flex flex-col bg-background">
      <div className="absolute top-5 left-10">
        <div className="justify-center items-center flex relative">
          <Logo className="w-[175px] h-[55px]" />
          {/* <Ellipse className="absolute -left-[18px] right-0 h-[70px] w-[165px]" /> */}
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">{children}</div>
    </div>
  );
}
