import { useSearchParams } from "react-router-dom";
import UIPopover from "components/Popover/Popover";
import moment from "moment";
import Button from "components/Buttons";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow.svg";
import { ReactComponent as More } from "../../assets/images/more.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron.svg";
import { ReactComponent as Eye } from "../../assets/images/eye.svg";
import { ReactComponent as BanIcon } from "../../assets/images/slash.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/delete.svg";
import useUsers from "./useUsers";
// import { UserView } from "../View/View";
import { UserDeleteConfirm } from "./components/UserDeleteConfirm";
import { UserBlockConfirm } from "./components/UserBlockConfirm";
// import { ReportedUserList } from "./ReportedUsers";
// import { BlockedUserList } from "./BlockedUser";
import { LoadAnimatedContainer } from "components/AnimatedContainer";
import { EmptyTableView } from "components/EmptyTableView";
import { ShowToast } from "components/Toast";
import { api } from "helpers/api/api";
import SubscriptionCard from "components/SubscriptionCard";
import toast from "react-hot-toast";
import DefaultUserIcon from "assets/images/default-user.png";
import useResponsive from "helpers/useResponsive";
import Input from "components/Input";
import parsePhoneNumber from "libphonenumber-js";
import { NoteTooltip } from "module/selection/components/NoteTooltip";
import Tippy from "@tippyjs/react";

function UserView({
  user,
  setRemoveUserModal,
  setDeactivateUser,
  setUser,
  handleUnblockUser,
  status,
}) {
  return (
    <div className="rounded-md bg-white/80 p-4 flex flex-col gap-3 max-w-[92vw]">
      <div className="flex justify-between items-start">
        <div className="flex gap-2.5 items-center">
          <div className="w-[42px] h-[42px] rounded-full">
            <img
              src={user.profileImg || DefaultUserIcon}
              className="rounded-full w-full h-full object-cover"
              alt="user"
            />
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-medium text-black max-w-[50vw] overflow-hidden text-ellipsis">
              {user.firstName} {user.lastName}
            </span>
            <span className="text-[13px] font-medium text-[#252525]/50">
              {user.email}
            </span>
          </div>
        </div>
        <div className="relative ml-auto">
          <UIPopover
            trigger={
              <span className="block p-2 ">
                <More />
              </span>
            }
          >
            {(close) => (
              <UserDropdown
                clientStatus={status}
                clientId={user.id}
                removeUser={() => {
                  close();
                  setRemoveUserModal(user);
                }}
                onDeactiveUser={() => {
                  close();
                  setDeactivateUser(user);
                }}
                onActivateUser={() => handleUnblockUser(user)}
                onView={() => {
                  close();
                  setUser(user);
                }}
              />
            )}
          </UIPopover>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <div className="flex flex-col justify-start gap-2 w-1/3">
          <span className="text-[#878787] text-xs font-medium">PHONE</span>
          <span className="text-black text-sm font-medium">
            {" "}
            {parsePhoneNumber(user.mobile, "US")?.formatNational() || "-"}
          </span>
        </div>
        <div className="flex flex-col justify-start gap-2 w-1/3">
          <span className="text-[#878787] text-xs font-medium">PLAN</span>
          <span className="text-black text-sm font-medium">
            {user.subscriptionName ? <SubscriptionCard {...user} /> : "-"}
          </span>
        </div>
        {user.cancelledAt ? (
          <div className="flex flex-col justify-start gap-2 w-1/3">
            <span className="text-[#878787] text-xs font-medium">
              CANCELED ON
            </span>
            <span className="text-black text-sm font-medium">
              {/* {moment.utc(user.date_created).local().format("MMM DD,YYYY")} */}
              {moment.utc(user.cancelledAt).local().format("MMM DD,YYYY")}
            </span>
          </div>
        ) : (
          <div className="flex flex-col justify-start gap-2 w-1/3">
            <span className="text-[#878787] text-xs font-medium">
              JOINED ON
            </span>
            <span className="text-black text-sm font-medium">
              {/* {moment.utc(user.date_created).local().format("MMM DD,YYYY")} */}
              {moment.utc(user.joinedOn).local().format("MMM DD,YYYY")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr key={i} className="[&_td]:py-3 border-b">
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="mrr">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function Users() {
  const [params] = useSearchParams();
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    users,
    total,
    loadMore,
  } = useUsers();
  const [status, setStatus] = useState<"active" | "deactive">("active");
  const [canceled, setCanceled] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const { isMobile } = useResponsive();

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
            ? ""
            : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleUnblockUser = async (user) => {
    try {
      // api call
      toast.loading("Unblocking user...");
      const response = await api.unblockUser({ id: user.id });
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        refetch(user.id, true);
        setDeactivateUser(null);
        ShowToast({
          type: "success",
          message: "User unblocked successfully",
        });
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      console.log(e);
      toast.dismiss();
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleDeactivateUserConfirm = async () => {
    try {
      // api call
      const user = deactivateUser;
      setBlocking(true);
      const response = await api.blockUser({ id: user.id });
      setBlocking(false);
      if ([200, 201].includes(response.status)) {
        refetch(user.id, true);
        setDeactivateUser(null);
        ShowToast({
          type: "success",
          message: "User blocked successfully",
        });
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteUser({ id: user.id });
      setDeleting(false);
      if (response.status === 200) {
        ShowToast({
          type: "success",
          message: "User removed successfully",
        });
        refetch(user.id, true);
        setRemoveUserModal(null);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const observerTarget = useRef(null);
  const mobileObserverTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          // users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          // users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (mobileObserverTarget.current) {
      observer.observe(mobileObserverTarget.current);
    }

    return () => {
      if (mobileObserverTarget.current) {
        observer.unobserve(mobileObserverTarget.current);
      }
    };
  }, [mobileObserverTarget, users, total, isLoading]);

  useEffect(() => {
    // console.log(status, canceled);
    // if (status === "active") {
    //   updateFilters({ type: "", showActive: true });
    // } else {
    //   updateFilters({ type: "", showActive: false });
    // }

    // if (canceled) {
    //   updateFilters({ type: "SUBSCRIBERS", showActive: false });
    // } else {
    //   updateFilters({ type: "", showActive: true });
    // }

    if (status === "active" && canceled) {
      updateFilters({ type: "SUBSCRIBERS", showActive: false });
    } else if (status === "active" && !canceled) {
      updateFilters({ type: "", showActive: true });
    } else {
      updateFilters({ type: "", showActive: false });
    }
  }, [status, canceled]);

  useEffect(() => {
    if (user) {
      const newUser = users?.find((category) => category.id === user.id);
      if (newUser) {
        setUser(newUser);
      }
    }
  }, [users, user]);

  console.log(canceled);

  return (
    <div className="p-4 md:p-7 pt-6 bg-background h-[calc(100vh-70px)] lg:h-[100vh]">
      <div className="mb-5 flex justify-between items-center">
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Manage users
        </h1>
        <div className="flex lg:hidden items-center gap-3">
          {status === "active" && !canceled ? (
            <div className="grid gap-2">
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black w-max"
                onClick={() => {
                  setStatus("deactive");
                  setCanceled(false);
                }}
              >
                Deactivated users{" "}
                <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
              </Button>
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black w-max"
                onClick={() => {
                  setCanceled(true);
                  setStatus("active");
                }}
              >
                Canceled users{" "}
                <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
              </Button>
            </div>
          ) : (
            <Button
              variant="link"
              scaleAnimated={false}
              className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black w-max"
              onClick={() => {
                setStatus("active");
                setCanceled(false);
              }}
            >
              Active users{" "}
              <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
            </Button>
          )}
        </div>
      </div>
      {/** mobile */}
      <LoadAnimatedContainer className="lg:hidden">
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)]">
          <div className="flex items-center gap-2 flex-1">
            {/* Skeleton for Search Input */}
            <Input
              type="text"
              search
              placeholder="Search"
              className="outline-none w-full"
              parentClassName="w-full"
              onChange={(e) => {
                updateFilters({
                  keyword: e.target.value,
                });
                console.log(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 mt-3 h-[calc(100vh-225px)] overflow-auto">
          {/* Skeleton for User List */}
          {isLoading ? (
            <div className="flex flex-col gap-3">
              {Array(5) // Assuming we want 5 skeleton loaders
                .fill(0)
                .map((_, i) => (
                  <div key={i} className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-gray-300 h-12 w-12"></div>
                    <div className="flex-1 space-y-6 py-1">
                      <div className="h-2 bg-gray-300 rounded"></div>
                      <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-gray-300 rounded col-span-2"></div>
                          <div className="h-2 bg-gray-300 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-300 rounded"></div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              {!isFetching && !users?.length && (
                <div className="flex-1 flex items-center justify-center">
                  <EmptyTableView
                    cols={7}
                    message="No users found"
                    className="h-[calc(100vh-225px)] flex items-center justify-center"
                  />
                </div>
              )}
              {users?.map((user, i) => (
                <UserView
                  key={i}
                  user={user}
                  setRemoveUserModal={setRemoveUserModal}
                  setDeactivateUser={setDeactivateUser}
                  setUser={setUser}
                  handleUnblockUser={handleUnblockUser}
                  status={status}
                />
              ))}
              <div ref={mobileObserverTarget}></div>
              <div className="p-2.5"></div>
            </div>
          )}
        </div>
      </LoadAnimatedContainer>

      {/** desktop */}
      <LoadAnimatedContainer className="hidden lg:block">
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex items-center justify-between flex-wrap gap-6 pl-2">
            <div className="flex items-center gap-2">
              <Input
                type="text"
                search
                placeholder="Search"
                className="outline-none w-full"
                onChange={(e) => updateFilters({ keyword: e.target.value })}
              />
            </div>
            <div className="flex items-center gap-3">
              {status === "active" && !canceled ? (
                <div className="grid gap-2">
                  <Button
                    variant="link"
                    scaleAnimated={false}
                    className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
                    onClick={() => {
                      setStatus("deactive");
                      setCanceled(false);
                    }}
                  >
                    Deactivated users{" "}
                    <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
                  </Button>
                  <Button
                    variant="link"
                    scaleAnimated={false}
                    className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
                    onClick={() => {
                      setCanceled(true);
                      setStatus("active");
                    }}
                  >
                    Canceled users{" "}
                    <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
                  </Button>
                </div>
              ) : (
                <Button
                  variant="link"
                  scaleAnimated={false}
                  className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
                  onClick={() => {
                    setStatus("active");
                    setCanceled(false);
                  }}
                >
                  Active users{" "}
                  <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
                </Button>
              )}
            </div>
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-225px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={!canceled ? { width: "15%" } : { width: "10%" }} />
                <col style={!canceled ? { width: "15%" } : { width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
                {canceled && <col style={{ width: "5%" }} />}
              </colgroup>

              <thead className="[&_td]:bg-[#DCECE0] [&_td]:font-medium [&_td]:text-[15px] [&_td]:uppercase [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-l pl-5">
                    <div className=" flex items-center">Name</div>
                  </td>
                  <td>
                    <div className="flex items-center">Email</div>
                  </td>
                  <td>
                    <div className="flex items-center">Phone</div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      {canceled ? "Canceled" : "Plan"}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">
                      {!canceled ? "Joined On" : "Subscribed On"}
                    </div>
                  </td>
                  {canceled && (
                    <td>
                      <div className="flex items-center">Canceled On</div>
                    </td>
                  )}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !users?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No users found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {users?.map((aclient, i) => (
                  <tr
                    key={aclient.id}
                    className="[&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setUser(aclient)}
                  >
                    <td className="pl-5">
                      <div className="flex items-center gap-3">
                        <img
                          src={aclient.profileImg || DefaultUserIcon}
                          className="w-10 h-10 rounded-full object-cover"
                          alt="user"
                        />
                        <p className="text-ellipsis max-w-[150px] overflow-hidden">
                          {aclient.firstName} {aclient.lastName}
                        </p>
                      </div>
                    </td>
                    <td className="subscription">
                      {/* {!!isInactive && aclient.total_past_subscription} */}
                      {/* {!!isTrial && aclient.total_trial_subscription} */}
                      {/* {!!isActive && aclient.total_active_subscription} */}
                      <p className="text-ellipsis max-w-[200px] overflow-hidden">
                        {aclient.email}
                      </p>
                    </td>
                    <td className="account-manager">
                      {parsePhoneNumber(
                        aclient.mobile,
                        "US"
                      )?.formatNational() || "-"}
                    </td>
                    <td className="account-executive">
                      {aclient.subscriptionName ? (
                        <SubscriptionCard {...aclient} />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="account-manager">
                      {!canceled
                        ? moment
                            .utc(aclient.joinedOn)
                            .local()
                            .format("MMM DD,YYYY")
                        : moment
                            .utc(aclient.subscribedOn)
                            .local()
                            .format("MMM DD,YYYY")}
                    </td>
                    {canceled &&
                      (aclient.cancelledAt ? (
                        <td className="account-manager">
                          {moment
                            .utc(aclient.cancelledAt)
                            .local()
                            .format("MMM DD,YYYY")}
                        </td>
                      ) : (
                        <td className="account-manager">-</td>
                      ))}
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 hover:bg-[#D0F3C9] rounded-[6px] transition-all">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <UserDropdown
                            clientStatus={status}
                            clientId={aclient.id}
                            removeUser={() => {
                              close();
                              setRemoveUserModal(aclient);
                            }}
                            onDeactiveUser={() => {
                              close();
                              setDeactivateUser(aclient);
                            }}
                            onActivateUser={() => handleUnblockUser(aclient)}
                            onView={() => {
                              close();
                              setUser(aclient);
                            }}
                            canceledUsers={canceled}
                            cancelReason={aclient?.cancelReason}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.firstName} ${removeUserModal?.lastName}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <UserBlockConfirm
        title={`Are you sure you want to block ‘${deactivateUser?.firstName} ${deactivateUser?.lastName}’?`}
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        loading={blocking}
      />
    </div>
  );
}

export const UserDropdown = ({
  clientId,
  onActivateUser,
  onDeactiveUser,
  removeUser,
  clientStatus,
  onView,
  canceledUsers,
  cancelReason,
}: {
  clientId?: string;
  onActivateUser?: () => void;
  onDeactiveUser?: () => void;
  removeUser?: () => void;
  clientStatus?: "active" | "deactive";
  onView?: () => void;
  canceledUsers?: boolean;
  cancelReason?: string;
}) => {
  console.log(clientStatus);
  return (
    <div className="z-10 w-52 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        {canceledUsers && (
          <li>
            <Tippy
              className="my-tooltip"
              arrow={true}
              interactive={true}
              hideOnClick={true}
              content={
                <div className="text-white text-[13px] p-2 bg-black/70 rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
                  {cancelReason}
                </div>
              }
            >
              <div className="!px-4 !justify-start w-full text-start items-center flex gap-[0.5rem] transition-all p-2 rounded-[8px] font-[500] cursor-pointer">
                <Eye /> Reason
              </div>
            </Tippy>
          </li>
        )}
        {clientStatus !== "deactive" ? (
          <li>
            <Button
              variant="icon"
              className="!px-4 !justify-start w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
              onClick={onDeactiveUser}
              scaleAnimated={false}
            >
              <BanIcon />
              Deactivate user
            </Button>
          </li>
        ) : (
          <li>
            <Button
              variant="icon"
              className="!px-4 !justify-start w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
              onClick={onActivateUser}
              scaleAnimated={false}
            >
              <BanIcon />
              Activate user
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            className="!px-4 !justify-start text-[#f54343] w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove user
          </Button>
        </li>
      </ul>
    </div>
  );
};
