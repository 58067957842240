import Tippy from "@tippyjs/react";

const infoIcon = require("assets/images/info.svg");
export function NoteTooltip({
  note,
  className = "",
}: {
  note: string;
  className?: string;
}) {
  return (
    <Tippy
      className="my-tooltip"
      arrow={true}
      interactive={true}
      hideOnClick={true}
      content={
        <div className="text-white text-[13px] p-2 bg-black/70 rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
          {note}
        </div>
      }
    >
      <div
        className={`rounded-md bg-white text-primary font-semibold border border-primary flex gap-1.5 justify-center items-center px-3 cursor-pointer py-1.5 ${className}`}
      >
        Note <infoIcon.ReactComponent className="w-5 h-5" />
      </div>
    </Tippy>
  );
}
