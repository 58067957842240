import { useEffect, useState } from "react";
import { ShowToast } from "components/Toast";
import { useDebounce } from "helpers/useDebounce";
import { api } from "helpers/api/api";
const LIMIT = 25;

function usePromoCodes() {
  const [hasData, setHasData] = useState(true);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: LIMIT,
  });
  const [keyword, setKeyword] = useState("");
  const [state, setState] = useState<any>({
    sort_by: "",
    sort_order: "",
    type: "active",
  });
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const debouncedKeyword = useDebounce(keyword, 500);

  const fetchUsers = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      // const { data, total } = await api.getPromoCodes({
      //   ...params,
      //   ...pageInfo,
      // });
      // Todo: remove this line after api is ready for total
      const data = await api.getPromoCodes({
        ...params,
        ...pageInfo,
      });
      setIsLoading(false);
      if (data.length < pagination.limit) {
        setHasData(false);
      }
      // console.log("data", data, pageInfo, params);
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) => prev.filter((item) => item.id !== refetchId));
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.id !== refetchId),
          ...data,
        ]);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 1; i <= pageInfo.page; i++) {
          const data = await api.getPromoCodes({
            ...params,
            page: i,
          });
          newData.push(...data);
        }
        setData(newData);
      } else {
        if (pageInfo.page === 0) {
          setData([...data]);
        } else {
          setData((prev) => [...prev, ...data]);
        }
      }
      setTotal(total);
    } catch (err: any) {
      setIsLoading(false);
      // if ()
      console.log(err);
      // if err TypeError: data is not iterable
      if (err?.response?.status === 401) {
        ShowToast({
          type: "error",
          message: "Session expired, please login again",
        });
      }
      ShowToast({
        type: "error",
        message: "Failed to fetch users",
      });
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchUsers(
      { ...state, keyword: debouncedKeyword },
      pagination,
      refetchId,
      isDeleted,
      refetchAll
    );
  };

  const loadMore = () => {
    // update page only we have data
    if (data?.length > 0 && hasData) {
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchUsers(
        { ...state, keyword: debouncedKeyword },
        {
          ...pagination,
          page: pagination.page + 1,
        }
      );
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    // console.log("updates", updates);
    setIsLoading(true);
    setTimeout(() => {
      setData([]);
    }, 0);
    setHasData(true);
    setPagination((prev) => ({ ...prev, page: 0 }));
    const { keyword, ...rest } = updates;
    if (keyword !== undefined) {
      setKeyword(keyword);
    }
    // console.log("fetching users111", debouncedKeyword, state, rest);
    if (Object.keys(rest).length > 0) {
      // check if any filter is updated in state from rest then update state
      Object.keys(rest).forEach((key) => {
        if (state[key] !== rest[key]) {
          setState((prev) => ({ ...prev, ...rest }));
        }
      });
    }
  };

  useEffect(() => {
    // console.log("fetching users", debouncedKeyword, state);
    fetchUsers({ ...state, keyword: debouncedKeyword });
  }, [debouncedKeyword, state]);

  const promocodes: any[] = data || [];
  return {
    promocodes,
    totalPages: Math.ceil(total / LIMIT),
    total,
    isLoading: isLoading,
    isFetching,
    filters: state,
    loadMore,
    refetch,
    updateFilters: (updates) => handleFilters(updates),
    reset: () => {
      setData([]);
      setKeyword("");
      setState((prev) => ({
        ...prev,
        page: 1,
        // status: "active",
        limit: LIMIT,
        sort_by: "",
        sort_order: "",
      }));
    },
  };
}

export default usePromoCodes;
