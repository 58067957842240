import React, { useRef } from "react";
import { Popover } from "react-tiny-popover";
import { twMerge } from "tailwind-merge";
type Position = "bottom" | "left" | "right" | "top";

function UIPopover({
  children,
  trigger,
  positions,
  withChevron,
  containerClassName,
  popoverButtonClassName = "",
}: {
  children: (close: () => void, position?: string) => any;
  trigger: any;
  positions?: Position[];
  withChevron?: boolean;
  containerClassName?: string;
  popoverButtonClassName?: string;
}) {
  const clickMeButtonRef = useRef<any>();
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={positions || ["bottom", "left", "right", "top"]}
      clickOutsideCapture={true}
      onClickOutside={() => {
        console.log("clicked outside");
        setIsPopoverOpen(false);
      }}
      ref={clickMeButtonRef}
      content={({ position }) =>
        children(() => setIsPopoverOpen(!isPopoverOpen), position)
      }
      containerClassName={containerClassName}
    >
      <button
        className={twMerge(
          `flex flex-col ${isPopoverOpen ? " rounded-lg" : ""}`,
          popoverButtonClassName
        )}
        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
      >
        {withChevron ? trigger(isPopoverOpen) : trigger}
      </button>
    </Popover>
  );
}

export default UIPopover;
