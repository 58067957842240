import Button from "components/Buttons";
import { useState } from "react";
import useResponsive from "helpers/useResponsive";
import { motion } from "framer-motion";
import { ReactComponent as StarIcon } from "../../assets/images/star.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";

import { useQuery } from "@tanstack/react-query";
import { api } from "helpers/api/api";
import SubscriptionForm from "./SubscriptionForm";
import { SubscriptionDeleteConfirm } from "./components/SubscriptionDeleteConfirm";
import { ShowToast } from "components/Toast";
import { getArrayOfBenefitsString } from "helpers/utils";
import moment from "moment";
const noSelectionsIcon = require("assets/images/no-selections.svg");

export default function Subscriptions() {
  const [subscriptionFormModal, setSubscriptionFormModal] = useState<any>({
    isOpen: false,
    subscription: null,
  });
  const [deleting, setDeleting] = useState(false);

  const [removeSubscriptionModal, setRemoveSubscriptionModal] =
    useState<any>(null);

  const { isMobile } = useResponsive();

  // get subscription data
  const {
    data: subscriptions = [],
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => api.getSubscriptions(),
  });

  const handleAddSubscription = (data?: any) => {
    setSubscriptionFormModal({ isOpen: true, subscription: data });
  };

  const handleDelete = (subscription) => {
    setRemoveSubscriptionModal(subscription);
  };

  const closeSelectionForm = () => {
    setSubscriptionFormModal({ isOpen: false, subscription: null });
  };

  const handleSubscriptionRemoveConfirm = async () => {
    try {
      setDeleting(true);
      const response = await api.deleteSubscription(removeSubscriptionModal.id);
      setDeleting(false);
      if ([200, 201].includes(response.status)) {
        ShowToast({
          type: "success",
          message: "Subscription deleted successfully",
        });
        setRemoveSubscriptionModal(null);
        refetch();
      } else {
        ShowToast({
          type: "error",
          message: "Unable to delete subscription",
        });
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Failed to delete subscription",
      });
      setDeleting(false);
      console.log(error);
    }
  };

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="items-center justify-between flex md:mb-5 mb-2">
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Subscription type
        </h1>
      </div>
      {/** TODO: once all filter supports in api then we can uncomment this part. */}
      {subscriptions.length === 0 && !isLoading ? (
        <>
          <div className="bg-[white] rounded-[12px] p-[0.75rem] w-full h-[calc(100vh-200px)] grid items-center justify-content-center gap-[2rem] justify-center content-center">
            <noSelectionsIcon.ReactComponent className="w-[100%]" />
            <span className="font-[500] text-[1.25rem]">
              No subscription type found
            </span>
            <Button
              className="w-fit justify-self-center px-[1.75rem] py-[0.875rem]"
              onClick={handleAddSubscription}
            >
              Add a subscription
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="md:bg-[white] rounded-[12px] md:p-[1.5rem] w-full md:h-[calc(100vh-118px)] overflow-auto flex items-center flex-col justify-center items-start gap-7 w-full content-start">
            <div className="lg:max-w-[620px] mx-auto flex flex-col justify-center items-center gap-7 w-full content-start">
              <div className="flex flex-col gap-4 md:max-h-[calc(100vh-250px)] overflow-auto w-full">
                {isLoading &&
                  !isMobile &&
                  Array.from({ length: 3 }).map((_, i) => (
                    <SubscriptionSkeleton key={i} />
                  ))}
                {isLoading &&
                  isMobile &&
                  Array.from({ length: 3 }).map((_, i) => (
                    <MobileSkeleton key={i} />
                  ))}
                {!isLoading &&
                  subscriptions.map((subscription) => (
                    <SubscriptionCard
                      key={subscription.id}
                      subscription={subscription}
                      onEdit={() => handleAddSubscription(subscription)}
                      onDelete={() => handleDelete(subscription)}
                    />
                  ))}
              </div>
              <Button className="w-fit" onClick={handleAddSubscription}>
                Add another type
              </Button>
            </div>
          </div>
        </>
      )}
      <SubscriptionForm
        handleAddSubscription={handleAddSubscription}
        isOpen={subscriptionFormModal.isOpen}
        onClose={closeSelectionForm}
        subscription={subscriptionFormModal.subscription}
        refetch={refetch}
      />
      <SubscriptionDeleteConfirm
        title={`Are you sure you want to remove ‘${removeSubscriptionModal?.name}’ subscription type?`}
        open={!!removeSubscriptionModal}
        onClose={() => setRemoveSubscriptionModal(null)}
        onConfirm={handleSubscriptionRemoveConfirm}
        loading={deleting}
      />
    </div>
  );
}
function SubscriptionSkeleton({ className }: { className?: string }) {
  return (
    <div
      className={`${className} h-fit flex gap-3 w-[620px] p-2 w-full animate-pulse min-w-[500px]`}
    >
      <div className="flex flex-1 gap-4 items-center border-solid border-[1px] border-[#DDDDDD] rounded-[18px] p-4">
        <div className="p-6 flex flex-col gap-3 rounded-[12px] bg-gray-200 min-w-[150px]">
          <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          <div className="h-6 bg-gray-300 rounded w-1/2"></div>
        </div>
        <div className="flex flex-col gap-5 flex-1">
          {Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className="flex items-center gap-2">
              <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
              <div className="h-4 bg-gray-300 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center border border-[#ddd] rounded-[18px] p-[22px]">
        <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
        <div className="w-5 h-[1px] bg-gray-300 my-[22px]"></div>
        <div className="w-10 h-10 bg-gray-300 rounded-full"></div>
      </div>
    </div>
  );
}

function MobileSkeleton() {
  return (
    <div className="animate-pulse flex flex-col gap-3 p-4 w-full max-w-[620px] border border-[#DDDDDD] rounded-lg">
      {/* Header Section */}
      <div className="flex-1 space-y-2">
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        <div className="h-6 bg-gray-300 rounded w-1/2"></div>
      </div>

      {/* Benefits List */}
      <div className="flex-1 space-y-4">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="flex items-center space-x-2">
            <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
            <div className="h-4 bg-gray-300 rounded w-full"></div>
          </div>
        ))}
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-2">
        <div className="w-8 h-8 bg-gray-300 rounded-md"></div>
        <div className="w-8 h-8 bg-gray-300 rounded-md"></div>
      </div>
    </div>
  );
}

function SubscriptionCard({
  className,
  subscription,
  onEdit,
  onDelete,
}: {
  className?: string;
  SideBySide?: boolean;
  selectionOfTheDay?: string;
  subscription: any;
  onEdit: () => void;
  onDelete: () => void;
}) {
  const { isMobile } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className={`${className} h-fit flex gap-3 w-[620px] ${
        isMobile ? "gap-[0.875rem]" : "p-2"
      } w-full`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      exit={{ opacity: 0 }}
    >
      <div
        className={`md:flex flex-1 gap-4 items-center border-solid border-[1px] border-[#DDDDDD] rounded-[18px] bg-white/80 md:bg-white p-2 ${
          isMobile ? "" : "p-4"
        }`}
      >
        <div
          className="p-6 flex flex-col gap-3 rounded-[12px]"
          style={{ backgroundColor: subscription.bgColor }}
        >
          <span
            className="text-base font-semibold"
            style={{ color: subscription.textColor }}
          >
            {subscription.name}
          </span>
          <span className="text-2xl font-bold text-black">
            ${subscription.priceMonthly}/month
          </span>
        </div>
        <div className="flex flex-col gap-5 flex-1 md:mt-0 mt-4 px-3 pb-2">
          {getArrayOfBenefitsString(subscription.benefits).map((item, i) => (
            <div key={i} className="flex items-center gap-2">
              <StarIcon />
              <span>{item}</span>
            </div>
          ))}
          {isMobile && (
            <div
              className={`${
                isMobile
                  ? "border-none gap-2 flex ml-auto"
                  : `flex flex-col justify-center border border-[#ddd] rounded-[18px] p-[22px]`
              }`}
            >
              <Button variant="icon" className="!p-0" onClick={onEdit}>
                <EditIcon />
              </Button>
              <Button variant="icon" className="!p-0" onClick={onDelete}>
                <DeleteIcon />
              </Button>
            </div>
          )}
        </div>
      </div>
      {!isMobile && (
        <div
          className={`${
            isMobile
              ? "border-none grid gap-2"
              : `flex flex-col justify-center border border-[#ddd] rounded-[18px] p-[22px]`
          }`}
        >
          <Button variant="icon" className="!p-0" onClick={onEdit}>
            <EditIcon />
          </Button>

          <div className="w-5 h-[1px] bg-[#E2E2E2] my-[22px]" />
          <Button variant="icon" className="!p-0" onClick={onDelete}>
            <DeleteIcon />
          </Button>
        </div>
      )}
    </motion.div>
  );
}
