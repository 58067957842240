import DatePickerComponent from "react-date-picker";
import { ReactComponent as CalendarIcon } from "../../assets/images/calendar-black.svg";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

export function DatePicker({
  value,
  onChange,
  minDate,
}: {
  value: any;
  onChange: (value: any) => void;
  minDate?: Date;
}) {
  return (
    <div className="date-picker">
      <DatePickerComponent
        onChange={onChange}
        value={value}
        calendarIcon={<CalendarIcon />}
        minDate={minDate}
        format="MM/dd/yyyy"
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        clearIcon={null}
        className="!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 p-3 text-base text-black [&>div]:!border-none"
      />
    </div>
  );
}
