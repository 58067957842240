import { useSearchParams } from "react-router-dom";
import UIPopover from "components/Popover/Popover";
import moment from "moment";
import Button from "components/Buttons";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow.svg";
import { ReactComponent as More } from "../../assets/images/more.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search.svg";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/delete.svg";
import { PromoCodeDeleteConfirm } from "./components/PromoCodeDeleteConfirm";
import { LoadAnimatedContainer } from "components/AnimatedContainer";
import { EmptyTableView } from "components/EmptyTableView";
import { ShowToast } from "components/Toast";
import { api } from "helpers/api/api";
import usePromoCodes from "./usePromoCodes";
import PromocodeForm from "./PromocodeForm";
import Input from "components/Input";
import { OptionView } from "./components/OptionView";

function PromoCodeView({ promoCode, setRemovePromoCodeModal }) {
  return (
    <div className="rounded-md bg-white/80 p-4 flex flex-col gap-3 max-w-[92vw]">
      <div className="flex justify-between items-start">
        <div className="flex gap-2.5 items-center">
          <div className="flex flex-col">
            <span className="text-sm font-medium text-black max-w-[50vw] overflow-hidden text-ellipsis">
              {promoCode.code}
            </span>
          </div>
        </div>
        <div className="relative ml-auto">
          <OptionView removeUser={() => setRemovePromoCodeModal(promoCode)} />
        </div>
      </div>
      <div className="flex justify-between gap-4 items-center">
        <div className="flex flex-col justify-start gap-2">
          <span className="text-[#878787] text-xs font-medium">NO. OF USE</span>
          <span className="text-black text-sm font-medium">
            {promoCode.totalUsage}
          </span>
        </div>
        <div className="flex flex-col justify-start gap-2">
          <span className="text-[#878787] text-xs font-medium">EXPIRES ON</span>
          <span className="text-black text-sm font-medium">
            {moment.utc(promoCode.expiredOn).local().format("MMM DD,YYYY")}
          </span>
        </div>
        <div className="flex flex-col justify-start gap-2">
          <span className="text-[#878787] text-xs font-medium">CREATED ON</span>
          <span className="text-black text-sm font-medium">
            {moment.utc(promoCode.createdAt).local().format("MMM DD,YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr key={i} className="[&_td]:py-3 border-b">
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-full bg-gray-200 animate-pulse transition duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
                  <div className="w-4 h-4 bg-gray-200 animate-pulse transition duration-50" />
                </div>
                <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td className="mrr">
            <div className="w-16 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function PromoCodes() {
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    promocodes,
    total,
    loadMore,
  } = usePromoCodes();
  const [type, setType] = useState<"active" | "expired">("active");
  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [promoCodeFormModal, setPromoCodeFormModal] = useState<boolean>(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [removePromoCodeModal, setRemovePromoCodeModal] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
            ? ""
            : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleDeactivateUserConfirm = async () => {
    try {
      // api call
      const user = deactivateUser;
      setBlocking(true);
      const response = await api.blockUser({ id: user.id });
      setBlocking(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          refetch(user.id, true);
          setDeactivateUser(null);
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removePromoCodeModal;
      setDeleting(true);
      const response = await api.deletePromocode({ id: user.code });
      setDeleting(false);
      if (response.status === 200) {
        ShowToast({
          type: "success",
          message: response?.data?.message || "Promo code removed successfully",
        });
        refetch(user.id, true);
        setRemovePromoCodeModal(null);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleAddPromocode = () => {
    setPromoCodeFormModal(true);
  };

  const observerTarget = useRef(null);
  const mobileObserverTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          promocodes &&
          promocodes.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, promocodes, total, isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // console.log(
        //   "loading more",
        //   entries[0].isIntersecting,
        //   promocodes,
        //   total,
        //   isLoading
        // );
        if (
          entries[0].isIntersecting &&
          promocodes &&
          // users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );
    // console.log("mobileObserverTarget.current", mobileObserverTarget.current);
    if (mobileObserverTarget.current) {
      observer.observe(mobileObserverTarget.current);
    }

    return () => {
      if (mobileObserverTarget.current) {
        observer.unobserve(mobileObserverTarget.current);
      }
    };
  }, [mobileObserverTarget, promocodes, total, isLoading]);

  useEffect(() => {
    updateFilters({ type });
  }, [type]);

  useEffect(() => {
    if (user) {
      const newUser = promocodes?.find((category) => category.id === user.id);
      if (newUser) {
        setUser(newUser);
      }
    }
  }, [promocodes, user]);

  return (
    <div className="p-4 md:p-7 pt-6 bg-background h-[100vh]">
      <div className="mb-5 flex justify-between items-center">
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Promo codes
        </h1>
        <Button
          className="hidden lg:block"
          variant="primary"
          onClick={handleAddPromocode}
        >
          Add another promo code
        </Button>
        <div className="flex lg:hidden items-center gap-3">
          {type === "expired" ? (
            <Button
              variant="link"
              scaleAnimated={false}
              className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
              onClick={() => setType("active")}
            >
              Active promo codes{" "}
              <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
            </Button>
          ) : (
            <Button
              variant="link"
              scaleAnimated={false}
              className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
              onClick={() => setType("expired")}
            >
              Expired promo codes{" "}
              <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
            </Button>
          )}
        </div>
      </div>
      {/** mobile */}
      <LoadAnimatedContainer className="lg:hidden">
        <div className="lg:hidden flex justify-center mb-4">
          <Button
            variant="primary"
            className="w-full"
            onClick={handleAddPromocode}
          >
            Add another promo code
          </Button>
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)]">
          <div className="flex items-center gap-2 flex-1 [&>div]:w-full">
            <Input
              type="text"
              search
              placeholder="Search"
              className="outline-none w-full"
              onChange={(e) => updateFilters({ keyword: e.target.value })}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 mt-4">
          {promocodes?.map((aclient, i) => (
            <PromoCodeView
              key={aclient.id}
              promoCode={aclient}
              setRemovePromoCodeModal={setRemovePromoCodeModal}
            />
          ))}
          <div ref={mobileObserverTarget}></div>
          <div className="p-2.5"></div>
        </div>
      </LoadAnimatedContainer>
      <LoadAnimatedContainer className="hidden lg:block">
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex items-center justify-between flex-wrap gap-6 pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              {type === "expired" ? (
                <Button
                  variant="link"
                  scaleAnimated={false}
                  className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
                  onClick={() => setType("active")}
                >
                  Active promo codes{" "}
                  <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
                </Button>
              ) : (
                <Button
                  variant="link"
                  scaleAnimated={false}
                  className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-[#4E4E4E] group text-black"
                  onClick={() => setType("expired")}
                >
                  Expired promo codes{" "}
                  <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
                </Button>
              )}
            </div>
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>

              <thead className="[&_td]:bg-[#DCECE0] [&_td]:font-medium [&_td]:text-[15px] [&_td]:uppercase [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    // onClick={() => handleSort("name")}
                    className="rounded-l pl-5"
                  >
                    <div className="cursor-pointer flex items-center">
                      Code
                      {/* {filters.sort_by === "name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">Added on</div>
                  </td>
                  <td
                  // onClick={() => handleSort("phone")}
                  >
                    <div className="flex items-center cursor-pointer">
                      {type === "active" ? "Expires on" : "Expired on"}
                      {/* {filters.sort_by === "phone" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td
                  // onClick={() => handleSort("plan")}
                  >
                    <div className="flex items-center cursor-pointer">
                      No. of use
                      {/* {filters.sort_by === "plan" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !promocodes?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No promo codes found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {promocodes?.map((aclient, i) => (
                  <tr
                    key={aclient.id}
                    className="[&_td]:py-3 border-b border-b-[#EFF1FF]"
                  >
                    <td className="pl-5">
                      <div className="flex items-center gap-3">
                        <p className="text-ellipsis max-w-[250px] overflow-hidden">
                          {aclient.code}
                        </p>
                      </div>
                    </td>
                    <td className="subscription">
                      {moment
                        .utc(aclient.createdAt)
                        .local()
                        .format("MMM DD,YYYY")}
                    </td>
                    <td className="account-manager">
                      {moment
                        .utc(aclient.expiredOn)
                        .local()
                        .format("MMM DD,YYYY")}
                    </td>
                    <td className="account-executive">
                      {aclient.totalUsage || 0}
                    </td>
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 hover:bg-[#D0F3C9] rounded-[6px] transition-all">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            clientStatus={aclient.status}
                            clientId={aclient.id}
                            removeUser={() => {
                              close();
                              setRemovePromoCodeModal(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      <PromoCodeDeleteConfirm
        title={`Are you sure you want to remove ‘${removePromoCodeModal?.code}’?`}
        open={!!removePromoCodeModal}
        onClose={() => setRemovePromoCodeModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      {promoCodeFormModal && (
        <PromocodeForm
          isOpen={promoCodeFormModal}
          onClose={() => setPromoCodeFormModal(false)}
          refetch={refetch}
        />
      )}
    </div>
  );
}

const Dropdown = ({ clientId, removeUser, clientStatus }) => {
  return (
    <div className="z-10 w-52 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#DCECE0] !rounded-[3px] text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove promo code
          </Button>
        </li>
      </ul>
    </div>
  );
};
