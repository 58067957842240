import { useMediaQuery } from "react-responsive";

export default function useResponsive() {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const isTablet = useMediaQuery({ maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isXLDesktop = useMediaQuery({ minWidth: 1850 });

  return {
    isDesktop,
    isTablet,
    isMobile,
    isXLDesktop,
  };
}
