import Input from "components/Input";
import NCAABIcon from "assets/sports/ncaab.png";
import NBAIcon from "assets/sports/nba.png";
import NFLIcon from "assets/sports/nfl.png";
import MLBIcon from "assets/sports/mlb.png";
import Button from "components/Buttons";
import Modal from "components/Modal";
import { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { api } from "helpers/api/api";
import { sports } from "helpers/constant";
import toast from "react-hot-toast";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Spinner from "components/Spinner";
import moment from "moment";
import { TeamDeleteConfirm } from "./TeamDeleteConfirm";
import { ShowToast } from "components/Toast";
const chevronBlackIcon = require("assets/images/chevron-black.svg");
const deleteIcon = require("assets/images/delete.svg");
const editIcon = require("assets/images/edit.svg");
const uploadIcon = require("assets/images/upload.svg");

function LoadingSkeleton() {
  return (
    <div className="grid gap-4 px-[1.25rem] py-4 bg-gray-200/80 rounded-[12px] w-full cursor-pointer transition-all">
      <div className="flex gap-4 items-center w-full">
        <div className="min-w-[4rem] h-[4rem] bg-gray-300 rounded-full"></div>
        <div className="grid gap-2 w-full">
          <div className="w-full h-4 bg-gray-300 rounded"></div>
          <div className="flex justify-between items-center w-full">
            <div className="border-[#C7C7C7] border-[1px] border-solid w-[6rem] h-6 bg-gray-300 rounded p-[0.25rem]"></div>
            <div className="flex gap-4">
              <div className="w-6 h-6 bg-gray-300 rounded"></div>
              <div className="w-6 h-6 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function TeamManagementMobile() {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedTeam, setSelectedTeam] = useState<any>();
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [search, setSearch] = useState("");
  const loadMoreRef = useRef(null);
  const {
    data: teams = { pages: [] },
    isLoading,
    isError,
    refetch,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["teams", search],
    queryFn: ({ pageParam = 0 }) =>
      api.getTeams({
        showPrevious: true,
        page: pageParam,
        limit: 10,
        searchKeyword: search,
      }),
    getNextPageParam: (lastPage, pages) =>
      lastPage?.length === 10 ? pages?.length : undefined,
    initialPageParam: 0, // Add this line
  });
  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };
  const handleSelectedTeam = (team: any) => {
    setSelectedTeam(team);
    setRemoveUserModal(true);
  };
  const handleEditTeam = (team: any) => {
    setSelectedTeam(team);
    setEditMode(true);
    setIsOpen(true);
  };
  const handleRemoveTeamConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteTeam(selectedTeam.id);
      setDeleting(false);
      if (response.status === 200) {
        ShowToast({
          type: "success",
          message: "Team removed successfully",
        });
        refetch();
        setRemoveUserModal(null);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };
  const handleIsOpen = () => {
    setEditMode(false);
    setIsOpen(!isOpen);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <div className="p-5 lg:p-7 pt-6">
      <AddTeam
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
        refetch={refetch}
        editMode={editMode}
        editData={selectedTeam}
      />
      <TeamDeleteConfirm
        title={`Are you sure you want to remove ‘${selectedTeam?.name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveTeamConfirm}
        loading={deleting}
      />
      <div className="flex justify-between mb-4">
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Sport teams
        </h1>
        <Button
          className="px-[1.75rem] py-[0.875rem] text-[1rem] font-[500]"
          onClick={handleIsOpen}
        >
          Add a team
        </Button>
      </div>
      <div className="rounded-[12px] p-[0.75rem] w-full">
        <div className="w-full">
          <Input
            placeholder="Search"
            className="p-[1rem] bg-white/80"
            search
            onChange={handleChange}
          />
        </div>
        <div className="w-full mt-3">
          <div className="grid gap-4">
            {!isLoading &&
              teams?.pages.map((page, i) =>
                page.map((team, j) => (
                  <Data
                    key={j}
                    team={team}
                    handleSelectedTeam={handleSelectedTeam}
                    handleEditTeam={handleEditTeam}
                  />
                ))
              )}
            {hasNextPage && (
              <button
                onClick={handleLoadMore}
                ref={loadMoreRef}
                className="rounded-[12px] p-[1.25rem] w-full text-[#6A6A6A] text-[1rem] font-[500] mt-3 cursor-pointer"
              >
                <Spinner />
              </button>
            )}
            {isLoading && <LoadingSkeleton />}
          </div>
        </div>
      </div>
    </div>
  );
}

function Data({
  team,
  handleSelectedTeam,
  handleEditTeam,
}: {
  team: any;
  handleSelectedTeam: (team) => void;
  handleEditTeam: (team) => void;
}) {
  return (
    <div className="grid gap-4 px-[1.25rem] py-4 bg-white/80 rounded-[12px] w-full cursor-pointer hover:bg-[#F9F9F9] transition-all">
      <div className="flex gap-4 items-center w-full">
        <img
          src={team.logo}
          alt="logo"
          className="w-[4rem] h-[4rem] rounded-full"
        />
        <div className="grid gap-2 w-full">
          <span>{team.name}</span>
          <div className="flex justify-between items-center w-full">
            <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
              <img
                src={
                  sports.find(
                    (sportItem) => sportItem.name === (team.sports || "NCAAB")
                  )?.icon
                }
                alt="NBA"
                className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
              />
              <span className="font-[500] text-[0.75rem]">{team.sports}</span>
            </div>
            <div className="flex gap-4">
              <button
                className="flex gap-2"
                onClick={() => handleEditTeam(team)}
              >
                <editIcon.ReactComponent />
              </button>
              <button
                className="flex gap-2"
                onClick={() => handleSelectedTeam(team)}
              >
                <deleteIcon.ReactComponent />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function AddTeam({
  isOpen,
  handleIsOpen,
  refetch,
  editMode,
  editData,
}: {
  isOpen: boolean;
  handleIsOpen: () => void;
  refetch: () => void;
  editMode?: boolean;
  editData?: any;
}) {
  const [sportDropdown, setSportDropdown] = useState(false);
  const [sport, setSport] = useState("NCAAB");
  const handleSportDropdown = () => setSportDropdown(!sportDropdown);
  const [tempImg, setTempImg] = useState<File>();
  const [imgURL, setImgURL] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<{
    name: string;
  }>({
    name: "",
  });
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    api
      .getImageSignedURL(e.target?.files?.[0]?.type)
      .then((res) => {
        axios
          .put(
            res.uploadUrl,
            // remove data:image/png;base64, from the base64 string
            //   (reader.result as string)?.split(";")[1]
            e.target?.files?.[0],
            { headers: { "Content-Type": e?.target?.files?.[0].type } }
          )
          .then(() => {
            setImgURL(res.accessUrl);
            // setTempImg so it can be shown in the UI
            setTempImg(e?.target?.files?.[0]);
            // refetch();
          })
          .catch((err) => {
            console.log(err);
            setImgURL("");
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const promise = editMode
      ? api.updateTeam(editData.id, {
          sports: sport,
          logo: imgURL,
          name: form.name,
        })
      : api.addTeam({
          sports: sport,
          logo: imgURL,
          name: form.name,
        });

    promise
      .then(() => {
        setIsLoading(false);
        handleIsOpen();
        toast.success("Team added successfully");
        setImgURL("");
        setForm({ name: "" });
        setTempImg(undefined);
        setSport("NCAAB");
        refetch();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Failed to add team");
      });
  };

  const isDisabled = useMemo(() => {
    if (!form.name || imgURL === "") {
      return true;
    }
    return false;
  }, [form, imgURL]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (editMode) {
      setForm({
        name: editData?.name,
      });
      setImgURL(editData?.logo);
      setSport(editData?.sports);
    }
  }, [editMode]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleModal={handleIsOpen}
        title="Add a team"
        zIndex="104"
      >
        <form className="grid gap-[1.25rem]" onSubmit={handleSubmit}>
          <label htmlFor="profileImg" className="grid gap-3 ">
            <span>Upload logo/icon of the team</span>
            <div className="flex items-center gap-[0.75rem]">
              <input
                type="file"
                onChange={handleUpload}
                id="profileImg"
                className="hidden"
                accept="image/png, image/jpeg"
              />
              <img
                src={
                  (tempImg && URL.createObjectURL(tempImg)) ||
                  imgURL ||
                  uploadIcon.default
                }
                alt="upload"
                className="cursor-pointer w-[6.25rem] h-[6.25rem] rounded-full object-contain"
              />
            </div>
          </label>
          <Input
            placeholder="Enter team name"
            label="Team name"
            name="name"
            onChange={handleChange}
            value={form.name}
          />
          <label htmlFor="sport-selection" className="grid gap-2">
            <span className="text-[0.875rem] font-[400] cursor-pointer">
              Sport
            </span>
            <div className="w-full relative items-center">
              <button
                id="sport-selection"
                className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]"
                onClick={handleSportDropdown}
                type="button"
              >
                <span
                  className={`${
                    sport === "Select" ? "text-[#8D8E92]" : "text-[black]"
                  } rounded-[8px] flex gap-3 items-center`}
                >
                  {
                    <img
                      src={
                        sports.find(
                          (sportItem) =>
                            // sport.name === (selection?.sports || "NCAAB")
                            sportItem.name === (sport || "NCAAB")
                        )?.icon
                      }
                      alt="chevron"
                      className="w-[1.75rem] h-[1.75rem] inline-block"
                    />
                  }
                  {sport}
                </span>
                <img
                  src={chevronBlackIcon.default}
                  alt="chevron"
                  className="absolute right-[1.125rem] top-1/2 transform -translate-y-1/2"
                />
              </button>
              <Dropdown
                isOpen={sportDropdown}
                handleDropdown={handleSportDropdown}
                padding="8px"
              >
                <SelectionButton
                  title="NCAAB"
                  onClick={() => {
                    setSport("NCAAB");
                    setSportDropdown(false);
                  }}
                  icon={NCAABIcon}
                />
                <SelectionButton
                  title="NCAAF"
                  onClick={() => {
                    setSport("NCAAF");
                    setSportDropdown(false);
                  }}
                  icon={NCAABIcon}
                />
                <SelectionButton
                  title="NFL"
                  onClick={() => {
                    setSport("NFL");
                    setSportDropdown(false);
                  }}
                  icon={NFLIcon}
                />
                <SelectionButton
                  title="MLB"
                  onClick={() => {
                    setSport("MLB");
                    setSportDropdown(false);
                  }}
                  icon={MLBIcon}
                />
                <SelectionButton
                  title="NBA"
                  onClick={() => {
                    setSport("NBA");
                    setSportDropdown(false);
                  }}
                  icon={NBAIcon}
                />
              </Dropdown>
            </div>
          </label>
          <Button
            className="w-fit ml-auto px-[1.75rem] py-[0.875rem] min-w-[7.5rem]"
            disabled={isDisabled}
            isLoading={isLoading}
            type="submit"
          >
            {editMode ? "Update" : "Add"}
          </Button>
        </form>
      </Modal>
    </>
  );
}

export function SelectionButton({
  title,
  onClick,
  icon,
}: {
  title: string;
  onClick: () => void;
  icon: string;
}) {
  return (
    <button
      className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
      onClick={onClick}
      type="button"
    >
      <img
        src={icon}
        alt="NBA"
        className="w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
      />
      <span className="font-[500]">{title}</span>
    </button>
  );
}
export function Dropdown({
  isOpen,
  handleDropdown,
  className = "",
  children,
  padding,
}: {
  isOpen: boolean;
  handleDropdown: () => void;
  className?: string;
  children: React.ReactNode;
  padding: string;
}) {
  return (
    <>
      <motion.div
        className={`w-full absolute bottom-[6rem] right-0 bg-[white] rounded-[10px] p-2 z-[103] shadow-xl h-auto p-[1.5rem] overflow-hidden ${className}`}
        initial={{ maxHeight: "0px", padding: "0px" }}
        animate={{
          maxHeight: isOpen ? "500px" : "0px",
          padding: isOpen ? padding : "0px",
          border: isOpen ? "1px solid #E1E1EC" : "0px solid #E1E1EC",
        }}
        exit={{ maxHeight: "0px", padding: "0px", border: "none" }}
      >
        <motion.div
          className="grid text-center w-full"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -10 }}
          exit={{ opacity: 0, display: "none" }}
        >
          {children}
        </motion.div>
      </motion.div>
      {/* all over behind dropdown click closes the dropdown */}
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full z-[102] h-[100%]"
          onClick={handleDropdown}
        />
      )}
    </>
  );
}
