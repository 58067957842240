import { DatePicker } from "components/DatePicker/DatePicker";
import { TimePicker } from "components/DatePicker/TimePicker";
import Input from "components/Input";
import { useCallback, useEffect, useState } from "react";
import { SportSelection } from "../SportSelection";
import { Dropdown } from "module/teams/Teams";
import { WagerSelection } from "../WagerSelection";
import { WAGER } from "helpers/constant";

const chevronIcon = require("assets/images/chevron-black.svg");

const wagerList = [WAGER.MoneyLine];

export default function TTP({
  form,
  setForm,
  teams,
}: {
  form: any;
  setForm: any;
  teams: any[];
}) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setForm((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [form1, setForm1] = useState<any>({ wager: WAGER.MoneyLine });
  const [form2, setForm2] = useState<any>({ wager: WAGER.MoneyLine });

  useEffect(() => {
    setForm((prev: any) => ({
      ...prev,
      teams: [form1, form2],
    }));
  }, [form1, form2]);

  return (
    <div className="grid gap-4">
      {/* do indivual selection twice but pass index */}
      <IndividualSelection
        teams={teams}
        selectionIndex={0}
        form1={form1}
        setForm1={setForm1}
      />
      <IndividualSelection
        teams={teams}
        selectionIndex={1}
        form1={form2}
        setForm1={setForm2}
      />
      <div className="flex w-full gap-4">
        <div className="text-[0.875rem] relative grid gap-2 w-full">
          <span>Wager</span>

          <WagerSelection
            options={wagerList}
            value={form1?.wager}
            onChange={(wager: string) => {
              setForm1((prev) => ({
                ...prev,
                wager,
              }));
              setForm2((prev) => ({
                ...prev,
                wager,
              }));
            }}
          />
        </div>
      </div>
      <Input
        placeholder="Enter winning probability (%)"
        label="Winning Probability (%)"
        type="number"
        value={form.winningProbability}
        name="winningProbability"
        onChange={handleChange}
        max={100}
        min={1}
      />
    </div>
  );
}

function IndividualSelection({
  teams,
  selectionIndex,
  form1,
  setForm1,
}: {
  teams: any[];
  selectionIndex: number;
  form1: any;
  setForm1: any;
}) {
  const [isTeamDropdownOpen, setIsTeamDropdownOpen] = useState(false);

  const handleTeamDropdown = useCallback(
    () => setIsTeamDropdownOpen((prev) => !prev),
    []
  );
  const [team, setTeam] = useState("");

  return (
    <div className="border-[1px] border-solid border-[#E1E1EC] p-4 rounded-[12px] grid gap-4">
      <div className="text-[0.875rem] grid gap-2">
        <h5 className="text-[#33363F] font-[700]">Team {selectionIndex + 1}</h5>
        <span>Sport</span>
        <SportSelection
          value={form1?.sports}
          onChange={(value: string) =>
            setForm1((prev) => ({
              ...prev,
              sports: value,
            }))
          }
          setTeam={setTeam}
          setForm={setForm1}
        />
      </div>
      <div className="text-[0.875rem] grid gap-2 team-selection">
        <span>Select Team</span>
        <div className="relative w-full">
          <button
            id="sport-selection"
            className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]"
            onClick={handleTeamDropdown}
          >
            <span
              className={`${
                team === "" ? "text-[#8D8E92]" : "text-[black]"
              } rounded-[8px] flex gap-3 items-center`}
            >
              {!team ? (
                <>Select</>
              ) : (
                <>
                  {
                    <img
                      src={teams.find((t) => t.id === form1.teamId)?.logo}
                      alt="chevron"
                      className="w-[1.75rem] h-[1.75rem] inline-block rounded-full"
                    />
                  }
                  {team}
                </>
              )}
            </span>
            <img
              src={chevronIcon.default}
              alt="chevron"
              className={`absolute right-[1.125rem] top-1/2 transform -translate-y-1/2 ${
                isTeamDropdownOpen ? "" : "rotate-180"
              }`}
            />
          </button>
          {isTeamDropdownOpen && (
            <TeamSelection
              teams={teams}
              isOpen={isTeamDropdownOpen}
              handleDropdown={handleTeamDropdown}
              sport={form1?.sports}
              setForm={setForm1}
              setTeamName={setTeam}
            />
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <div className="w-1/2 relative">
          <label className="text-sm opacity-80">Date</label>
          <DatePicker
            value={form1?.date}
            onChange={(value) => {
              setForm1((prev) => ({
                ...prev,
                date: value,
              }));
            }}
            minDate={new Date()}
          />
        </div>
        <div className="w-1/2 relative">
          <label className="text-sm opacity-80">Time</label>
          <TimePicker
            value={form1?.time}
            onChange={(value) => {
              setForm1((prev) => ({
                ...prev,
                time: value,
              }));
            }}
          />
        </div>
      </div>
    </div>
  );
}

function TeamSelection({
  teams,
  isOpen,
  handleDropdown,
  sport,
  setForm,
  setTeamName,
}: {
  teams: any[];
  isOpen: boolean;
  handleDropdown: () => void;
  sport: string;
  setForm: any;
  setTeamName: any;
}) {
  return (
    <>
      <Dropdown isOpen={isOpen} handleDropdown={handleDropdown} padding="8px">
        {teams
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((team) => team.sports === sport)
          .map((team) => (
            <SelectButton
              key={team.id}
              team={team}
              setForm={setForm}
              setTeamName={setTeamName}
              handleDropdown={handleDropdown}
            />
          ))}
        {teams.filter((team) => team.sports !== sport).length === 0 && (
          <span className="my-8">
            No teams available Add a team first or select another sport.
          </span>
        )}
      </Dropdown>
      {isOpen && (
        <div className="fixed inset-0 z-50" onClick={handleDropdown}></div>
      )}
    </>
  );
}
function SelectButton({
  team,
  setForm,
  setTeamName,
  handleDropdown,
}: {
  team: any;
  setForm: any;
  setTeamName: any;
  handleDropdown: () => void;
}) {
  const handleTeamSelection = () => {
    setForm((prev: any) => ({
      ...prev,
      teamId: team.id,
      teamName: team.name,
    }));
    setTeamName(team.name);
    handleDropdown();
  };
  return (
    <button
      className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
      onClick={handleTeamSelection}
      type="button"
    >
      <img
        src={team.logo}
        alt="NBA"
        className="w-[1.75rem] h-[1.75rem] inline-block"
      />
      <span className="font-[500]">{team.name}</span>
    </button>
  );
}
