import { WAGER_LABEL } from "helpers/constant";
import { Dropdown } from "module/teams/Teams";
import { useState } from "react";
const chevronIcon = require("assets/images/chevron-black.svg");

export function WagerSelection({
  onChange,
  value,
  options,
}: {
  value: string;
  onChange: (wager: string) => void;
  options: string[];
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="relative w-full wager-selection">
        <button
          id="sport-selection"
          className="w-full text-start p-[1.125rem] border-solid border-[#E1E1EC] border-[1px] rounded-[8px]"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span
            className={`${
              value === "" ? "text-[#8D8E92]" : "text-[black]"
            } rounded-[8px] flex gap-3 items-center`}
          >
            {!value ? <>Select</> : <>{WAGER_LABEL[value] || value}</>}
          </span>
          <img
            src={chevronIcon.default}
            alt="chevron"
            className={`absolute right-[1.125rem] top-1/2 transform -translate-y-1/2 ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>
      </div>
      <Dropdown
        fromTop={false}
        isOpen={isOpen}
        handleDropdown={() => setIsOpen(!isOpen)}
        padding="8px"
      >
        {options
          ?.filter((wager) => value !== wager)
          .map((wager) => (
            <button
              className="w-full text-start items-center flex gap-[0.75rem] transition-all hover:bg-[#DCECE0] p-2 rounded-[8px]"
              onClick={() => {
                setIsOpen(!isOpen);
                onChange(wager);
              }}
              type="button"
            >
              <span className="font-[500]">{WAGER_LABEL[wager]}</span>
            </button>
          ))}
      </Dropdown>
      {isOpen && (
        <div
          className="fixed inset-0 z-40"
          onClick={() => setIsOpen(!isOpen)}
        />
      )}
    </>
  );
}
