import SubscriptionCard from "components/SubscriptionCard";
import UserIcon from "../../../assets/images/user.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/email.svg";
import { ReactComponent as MoreIcon } from "../../../assets/images/more.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/images/phone.svg";
import { UserDropdown } from "module/users/Users";
import UIPopover from "components/Popover/Popover";
import { useState } from "react";
import { UserDeleteConfirm } from "module/users/components/UserDeleteConfirm";
import { UserBlockConfirm } from "module/users/components/UserBlockConfirm";
import { ShowToast } from "components/Toast";
import { api } from "helpers/api/api";
import moment from "moment";

export function PersonCard({ subscriber, refetch }) {
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);

  const handleDeactivateUserConfirm = () => {
    setBlocking(true);
    api.blockUser({ id: deactivateUser.userId }).then((response) => {
      if (response.status === 200) {
        setDeactivateUser(null);
        ShowToast({
          type: "success",
          message: "User blocked successfully",
        });
        setBlocking(false);
        refetch(subscriber.userId, true);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
        setBlocking(false);
      }
    });
  };

  const handleRemoveUserConfirm = async () => {
    setDeleting(true);
    api
      .deleteUser({ id: removeUserModal.userId })
      .then((response) => {
        if (response.status === 200) {
          ShowToast({
            type: "success",
            message: "User removed successfully",
          });
          refetch(removeUserModal.id, true);
          setRemoveUserModal(null);
          setDeleting(false);
        }
      })
      .catch((error) => {
        ShowToast({
          type: "error",
          message: error?.response?.data?.message || "Something went wrong",
        });
        setDeleting(false);
      });
  };

  return (
    <div className="rounded-[12px] border-[#DDD] border-[1px] border-solid p-4 flex gap-3">
      <img
        src={subscriber.profileImg || UserIcon}
        alt="user"
        className="w-[2.375rem] h-[2rem] mt-2 md:mt-0 md:w-[4.125rem] md:h-[3.625rem] rounded-full"
      />

      <div className="grid gap-2 w-full">
        <div className="flex justify-between relative">
          <div className="md:flex grid md:gap-1 gap-2 items-center">
            <span className="font-[600] text-[1rem] max-w-[250px] break-all">
              {subscriber.firstName} {subscriber.lastName}
            </span>
            <div className="hidden lg:flex flex-col items-start h-full">
              <SubscriptionCard {...subscriber} />
            </div>
          </div>

          <UIPopover
            trigger={
              <span className="block p-2 hover:bg-[#D0F3C9] rounded-[6px] transition-all">
                <MoreIcon />
              </span>
            }
          >
            {(close) => {
              console.log(subscriber);
              return (
                <UserDropdown
                  clientId={subscriber.id}
                  removeUser={() => {
                    close();
                    setRemoveUserModal(subscriber);
                  }}
                  onDeactiveUser={() => {
                    close();
                    console.log(subscriber);
                    setDeactivateUser(subscriber);
                  }}
                />
              );
            }}
          </UIPopover>
        </div>
        <div className="flex gap-1 items-center">
          <EmailIcon className="w-[1.125rem] h-[1.125rem]" />
          <a
            href={`mailto:${subscriber.email}`}
            className="text-[0.875rem] text-[#666666] font-[500] leading-[100%]"
            style={{
              overflowWrap: "anywhere",
            }}
          >
            {subscriber.email}
          </a>
        </div>
        <div className="lg:flex grid gap-2 justify-between w-full">
          <div className="flex gap-1 items-center">
            <PhoneIcon className="w-[1.125rem] h-[1.125rem]" />
            <a
              href={`tel:${subscriber.mobile}`}
              className="text-[0.875rem] text-[#666666] font-[500] leading-[100%]"
            >
              +{subscriber.mobile}
            </a>
          </div>
          <div className="flex lg:hidden flex-col items-start h-full">
            <SubscriptionCard {...subscriber} />
          </div>
          <span className="text-[0.75rem] font-[500]">
            Subscribed on{" "}
            {moment(subscriber.subscribedOn).format("MMM DD, YYYY")}
          </span>
        </div>
      </div>
      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.firstName} ${removeUserModal?.lastName}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <UserBlockConfirm
        title={`Are you sure you want to block ‘${deactivateUser?.firstName} ${deactivateUser?.lastName}’?`}
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        loading={blocking}
      />
    </div>
  );
}
