import Button from "components/Buttons";
import AddSelection from "./AddSelection";
import { useEffect, useState } from "react";
import useResponsive from "helpers/useResponsive";
import { motion } from "framer-motion";
import Dropdown from "components/Dropdown";
import { Link } from "react-router-dom";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron.svg";
import { useQuery } from "@tanstack/react-query";
import { api } from "helpers/api/api";
import moment from "moment-timezone";
import { WAGER, WAGER_LABEL, sports } from "helpers/constant";
import toast from "react-hot-toast";
import { NoteTooltip } from "./components/NoteTooltip";
import { getESTDateTime } from "helpers/utils";
import { ResultButton } from "./components/ResultButton";
import { SubscriptionPicker } from "./components/SubscriptionPicker";
import Modal from "components/Modal";
import Checkbox from "components/Checkbox";
const noSelectionsIcon = require("assets/images/no-selections.svg");
const chevronIcon = require("assets/images/chevron-black.svg");

const getWager = (selection: any) => {
  if (selection?.type === "I") {
    const wager = selection?.teams?.[0]?.wager || selection?.teams?.[1]?.wager;
    if (wager === WAGER.Total) {
      return (
        selection?.teams?.[0]?.spreadPoints ||
        selection?.teams?.[1]?.spreadPoints
      );
    } else if (wager === WAGER.Spread) {
      return (
        selection?.teams?.[0]?.spreadPoints ||
        selection?.teams?.[1]?.spreadPoints + " points"
      );
    } else {
      return (
        WAGER_LABEL[wager] ||
        selection?.teams?.[0]?.wager ||
        selection?.teams?.[1]?.wager
      );
    }
  } else if (selection?.type === "TTT") {
    return "Two Team Teaser";
  } else if (selection?.type === "TTP") {
    const wager = selection?.teams?.[0]?.wager || selection?.teams?.[1]?.wager;
    return WAGER_LABEL[wager] || wager;
  }
};

const getPoints = (team: any) => {
  if (team?.wager === WAGER.Total) {
    return ` (${team?.spreadPoints})`;
  }
  if (team?.wager === WAGER.Spread) {
    return ` (${team?.spreadPoints} points)`;
  }
  return "";
};

export default function Selections() {
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [isAddSelectionOpen, setIsAddSelectionOpen] = useState(false);
  const [noSelectionModal, setNoSelectionModal] = useState(false);

  const handleNoSelectionModal = () => {
    setNoSelectionModal(!noSelectionModal);
  };

  // get subscription data
  const {
    data: subscriptions = [],
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => api.getSubscriptions(),
  });
  // get team data
  const {
    data: teams = [],
    isLoading: isTeamsLoading,
    isError: isTeamsError,
  } = useQuery({
    queryKey: ["teams-selection"],
    queryFn: () => api.getTeams({ limit: 1000000 }),
  });

  // get selection data
  const {
    data: selections = [],
    isLoading: isSelectionsLoading,
    refetch: refetchSelections,
  } = useQuery({
    queryKey: ["selections", selectedSubscription],
    queryFn: () =>
      api.getSelectionList({
        day: moment().format("YYYY-MM-DD"),
        subscriptionId: selectedSubscription,
        page: 0,
        limit: 10,
        showPrevious: false,
      }),
  });
  const handleAddSelection = () => setIsAddSelectionOpen(!isAddSelectionOpen);
  const { isMobile } = useResponsive();
  const handleSubscriptionClick = (id: string) => {
    setSelectedSubscription(id);
  };
  const handleSelectionForm = () => {
    setIsAddSelectionOpen(!isAddSelectionOpen);
  };
  const closeSelectionForm = () => {
    setIsAddSelectionOpen(false);
  };
  const selectionOfTheDayList =
    (selections &&
      selections?.filter(
        (item) => Number(item.winningProbability || 0) >= 70
      )) ||
    [];

  return (
    <div className="p-4 md:p-7 pt-6 bg-background h-[100vh]">
      <div className="items-center justify-between md:flex grid mb-5">
        <h1 className="font-monument text-black text-2xl md:text-[1.75rem] font-[800]">
          Selection of the day
        </h1>
        <Link to="/previous-selections" className="flex items-center">
          <Button variant="link" className="!p-0 md:px-7 group !gap-0">
            <span>View previous selections</span>
            <ChevronRight className="transition duration-75 pt-[3px] group-hover:translate-x-[2px]" />
          </Button>
        </Link>
      </div>
      {selections.length === 0 && !isSelectionsLoading ? (
        <div className="bg-[white] rounded-[12px] p-[1.5rem] w-full md:h-[82vh] overflow-auto grid items-start gap-[2rem] w-full content-start">
          <SubscriptionPicker
            selectedSubscription={selectedSubscription}
            subscriptions={subscriptions}
            handleSubscriptionClick={handleSubscriptionClick}
            className="bg-white/80"
          />
          {noSelectionModal && (
            <NoSelectionModal
              isOpen={noSelectionModal}
              handleModal={handleNoSelectionModal}
            />
          )}

          <div className="justify-content-center gap-[2rem] justify-center content-center text-center grid gap-[2rem] md:h-[60vh]">
            <noSelectionsIcon.ReactComponent className="w-[100%]" />
            <span className="font-[500] text-[1.25rem]">
              No selection added for today ({moment().format("MMM DD, YYYY")})
            </span>
            <Button
              className="w-fit justify-self-center px-[1.75rem] py-[0.875rem] mx-auto"
              onClick={handleAddSelection}
            >
              Add a selection
            </Button>
            <button
              className="border-dotted border-b-[1px] border-b-primary w-fit mx-auto"
              onClick={handleNoSelectionModal}
            >
              No selection today
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="md:bg-[white] rounded-[12px] md:p-[1.5rem] w-full md:h-[82vh] overflow-x-hidden overflow-y-auto grid items-start gap-[2rem] w-full content-start">
            <SubscriptionPicker
              selectedSubscription={selectedSubscription}
              subscriptions={subscriptions}
              handleSubscriptionClick={handleSubscriptionClick}
              className="bg-white/80"
            />
            <div className="grid">
              <div className="w-full rounded-[12px]">
                {selectionOfTheDayList.length > 1 && (
                  <div className="bg-[#E4F7E0] w-full py-4 px-[1.75rem] rounded-[5px] text-center mb-[1.25rem]">
                    <span
                      className={`uppercase ${
                        isMobile ? "text-[0.875rem]" : "text-[1.125rem]"
                      } font-[500] tracking-[-0.36px]`}
                    >
                      Selection of the day ({moment().format("MMM DD, YYYY")})
                    </span>
                  </div>
                )}
                <div className="grid gap-5">
                  {isSelectionsLoading &&
                    Array.from({ length: 1 }).map((_, i) => (
                      <PickCardSkeleton key={i} />
                    ))}
                  {!isSelectionsLoading &&
                    selections
                      ?.filter(
                        (item) => Number(item.winningProbability || 0) >= 70
                      )
                      ?.map((selection, i) => (
                        <PickCard
                          selection={selection}
                          key={selection?.id}
                          selectionOfTheDay={
                            i === 0 && selectionOfTheDayList.length === 1
                              ? moment().format("MMM DD, YYYY")
                              : undefined
                          }
                          refetch={refetchSelections}
                        />
                      ))}
                  {selections?.filter(
                    (item) => Number(item.winningProbability || 0) < 70
                  ).length ? (
                    <div className="pt-5 pb-1">
                      <span className="text-xl text-black font-bold">
                        Other selections
                      </span>
                    </div>
                  ) : null}
                  {isSelectionsLoading &&
                    Array.from({ length: 1 }).map((_, i) => (
                      <PickCardSkeleton key={i} />
                    ))}
                  {!isSelectionsLoading &&
                    selections
                      ?.filter(
                        (item) => Number(item.winningProbability || 0) < 70
                      )
                      ?.map((selection) => (
                        <PickCard
                          selection={selection}
                          key={selection?.id}
                          refetch={refetchSelections}
                        />
                      ))}
                </div>
              </div>
            </div>
            {!isSelectionsLoading && (
              <Button className="w-fit" onClick={handleAddSelection}>
                Add another selection
              </Button>
            )}
          </div>
        </>
      )}
      {isAddSelectionOpen && (
        <AddSelection
          isAddSelectionOpen={isAddSelectionOpen}
          handleAddSelection={handleSelectionForm}
          onClose={closeSelectionForm}
          subscriptions={subscriptions}
          teams={teams}
          refetch={refetchSelections}
        />
      )}
    </div>
  );
}

function PickCardSkeleton({ className }: { className?: string }) {
  return (
    <div
      className={`${className} grid p-4 w-full rounded-[12px] border-solid border-[1px] border-[#DDDDDD] animate-pulse`}
    >
      <div className="bg-gray-200 w-full h-10 rounded-[5px] mb-[1.25rem]"></div>
      <div className="flex gap-4 items-center py-[0.625rem]">
        <div className="w-[4rem] h-[4rem] bg-gray-300 rounded-full"></div>
        <div className="grid gap-2 h-fit">
          <div className="h-4 bg-gray-300 rounded w-3/4"></div>
          <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        </div>
        <div className="ml-auto h-10 bg-gray-200 rounded-[8px] w-1/4"></div>
      </div>
      <div className="rounded-[8px] bg-gray-200 h-10 w-full"></div>
      <div className="grid grid-cols-2 gap-2 mt-2">
        <div className="col-span-1 bg-gray-200 h-6 rounded"></div>
        <div className="col-span-1 bg-gray-200 h-6 rounded"></div>
      </div>
    </div>
  );
}
export function PickCard({
  className,
  SideBySide,
  selectionOfTheDay,
  selection,
  refetch,
  noResultButton = false,
}: {
  className?: string;
  SideBySide?: boolean;
  selectionOfTheDay?: string;
  selection: any;
  refetch: any;
  noResultButton?: boolean;
}) {
  const { isMobile, isTablet } = useResponsive();
  const isSmallDevice = isMobile || isTablet;
  const [isOpen, setIsOpen] = useState(false);
  const isAbove70 = Number(selection?.winningProbability || 0) >= 70;

  const handleResultClick = (result: 1 | -1) => {
    api.patchSelection(selection.id, { winningStatus: result }).then(
      () => {
        refetch();
        setIsOpen(false);
        toast.success("Result updated successfully");
      },
      (error) => {
        console.log(error);
        toast.error("Failed to update result");
      }
    );
  };

  if (["TTP", "TTT"].includes(selection?.type)) {
    return (
      <motion.div
        className={`${className} grid px-[1.25rem] ${
          isSmallDevice ? "gap-[0.875rem] p-3 lg:p-4" : ""
        } w-full bg-[white] rounded-[12px] border-[#C8C8C8] border-solid border-[1px]`}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.25 }}
        viewport={{ once: true }}
        exit={{ opacity: 0 }}
      >
        <div className="flex flex-col lg:flex-row items-center gap-4">
          <div className="flex-1 rounded-md w-full">
            {selection?.teams?.map((team: any, i: number) => (
              <div
                key={i}
                className={`py-[1.25rem] flex lg:items-center gap-2.5 ${
                  i !== 0 ? "border-t border-t-[#DDD]" : ""
                }`}
              >
                <div>
                  <img
                    src={team?.logo}
                    alt="logo"
                    className={`rounded-full ${
                      isSmallDevice
                        ? "min-w-11 min-h-11 w-11 h-11 object-contain"
                        : "w-[4rem] h-[4rem] object-contain"
                    }`}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col lg:flex-row lg:items-center gap-1.5">
                    <span className="text-sm lg:text-lg font-medium text-black">
                      {team?.name}{" "}
                      <span className="whitespace-nowrap">
                        {getPoints(team)}
                      </span>
                    </span>
                    <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
                      <img
                        src={
                          sports.find(
                            (sport) =>
                              sport?.name.toLowerCase() ===
                              team?.sports?.toLowerCase()
                          )?.icon
                        }
                        alt="NBA"
                        className="rounded-full w-5 h-5 inline-block mr-[0.375rem]"
                      />
                      <span className="font-[500] text-[#252525] text-[15px]">
                        {team?.sports}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col lg:flex-row lg:items-center">
                      <span
                        className={`pr-[1.125rem] text-[15px] ${
                          isSmallDevice
                            ? "border-none text-[0.75rem]"
                            : "border-r-solid border-r-[1px] border-[#D0D0D0] text-sm lg:text-[1rem]"
                        } text-[#646464] font-[500]`}
                      >
                        Wager:
                        <span className="ml-9 lg:ml-1.5 text-sm lg:text-base text-[black]">
                          {getWager(selection)}{" "}
                        </span>
                      </span>
                      <span
                        className={`${
                          isSmallDevice
                            ? "pl-0 text-[0.75rem]"
                            : "pl-[1.125rem] text-[13px] lg:text-[1rem]"
                        } text-[#646464] font-[500] text-[15px]`}
                      >
                        Date/time:
                        <span className="ml-2 lg:ml-1.5 text-[13px] lg:text-base text-[black]">
                          {getESTDateTime(team.date)}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {!isSmallDevice && (
            <div className="h-full flex flex-col gap-3 py-[1.25rem]">
              <div
                className={`flex flex-col lg:flex-row gap-4 items-center flex-1`}
              >
                <div
                  className={`content-center ${
                    isAbove70 ? "bg-[#E9F8EB]" : " bg-[#F3F3F7]"
                  } grid ml-auto px-[1.25rem] py-[0.875rem] text-center rounded-[8px] gap-[0.375rem] h-full`}
                >
                  <span className="text-[0.875rem] font-[500] text-[#3D3D3D]">
                    Winning probability
                  </span>
                  <span
                    className={`${
                      isAbove70 && "text-[#5CB030]"
                    } text-[1.5rem] font-[600] text-[#050505] font-[600]`}
                  >
                    {selection.winningProbability}%
                  </span>
                </div>
              </div>

              {selection.note && <NoteTooltip note={selection.note} />}
              {!noResultButton && (
                <ResultButton
                  selection={selection}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  handleResultClick={handleResultClick}
                />
              )}
            </div>
          )}
        </div>

        {isSmallDevice && (
          <>
            <div
              className={`${
                isAbove70 ? "bg-[#E9F8EB]" : " bg-[#F3F3F7]"
              } rounded-[8px] p-[0.75rem] text-center font-[500] text-[0.875rem] flex gap-[0.75rem] items-center justify-center`}
            >
              <span>Winning probability</span>
              <span
                className={`${
                  isAbove70 && "text-[#5CB030]"
                } text-[1.5rem] font-[600] text-[#050505] font-[600]`}
              >
                {selection.winningProbability}%
              </span>
            </div>

            <ResultButton
              selection={selection}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleResultClick={handleResultClick}
            />
          </>
        )}
      </motion.div>
    );
  }

  return (
    <motion.div
      className={`${className} grid ${
        isMobile ? "gap-[0.875rem] p-4 bg-white/80" : "p-2"
      } w-full rounded-[12px] border-solid border-[1px] border-[#DDDDDD]`}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      exit={{ opacity: 0 }}
    >
      {selectionOfTheDay && (
        <div className="bg-[#E4F7E0] w-full py-4 px-[1.75rem] rounded-[5px] text-center mb-[1.25rem]">
          <span
            className={`uppercase ${
              isMobile ? "text-[0.875rem]" : "text-[1.125rem]"
            } font-[500] tracking-[-0.36px]`}
          >
            Selection of the day ({selectionOfTheDay})
          </span>
        </div>
      )}
      <div
        className={`flex gap-4 items-center ${
          isMobile ? "" : "py-[0.625rem] px-4"
        }`}
      >
        <img
          src={selection?.teams?.[0]?.logo || selection?.teams?.[1]?.logo}
          alt="logo"
          className={`rounded-full
            ${isMobile ? "w-[3.875rem] h-[3.875rem]" : "w-[4rem] h-[4rem]"}
          `}
        />

        <div className={`grid ${isMobile ? "gap-2" : "gap-[0.5rem]"} h-fit`}>
          {selection?.type === "I" ? (
            <>
              <span
                className={`font-[600] ${
                  isMobile ? "text-[1rem]" : "text-[1rem]"
                }`}
              >
                {selection?.teams?.[0]?.name || selection?.teams?.[1]?.name}
              </span>
              <div className="border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-[40px] flex items-center">
                <img
                  src={
                    sports.find(
                      (sport) =>
                        sport?.name === selection?.teams?.[0]?.sports ||
                        sport?.name === selection?.teams?.[1]?.sports
                    )?.icon
                  }
                  alt="sport"
                  className="rounded-full w-[1.75rem] h-[1.75rem] inline-block mr-[0.375rem]"
                />
                <span className="font-[500] text-[0.75rem]">
                  {selection?.teams?.[0]?.sports ||
                    selection?.teams?.[1]?.sports}
                </span>
              </div>
            </>
          ) : (
            <>
              {/** multiple teams */}
              {selection?.teams?.map((team, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row md:items-center gap-1.5"
                >
                  <div
                    className={`flex flex-col gap-[2px] md:flex-row md:items-center font-medium ${
                      isMobile ? "text-[1rem]" : "text-[1rem]"
                    }`}
                  >
                    <span>{team?.name}</span>
                    <span>{getPoints(team)}</span>
                  </div>
                  <div className="rounded-full border-[#C7C7C7] border-[1px] border-solid w-fit pr-4 p-[0.25rem] rounded-full flex items-center">
                    <img
                      src={
                        sports.find((sport) => sport?.name === team?.sports)
                          ?.icon
                      }
                      alt="sport"
                      className="w-5 h-5 inline-block mr-[0.375rem]"
                    />
                    <span className="font-[500] text-[0.75rem]">
                      {team?.sports}
                    </span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {!isMobile && !SideBySide && (
          <div
            className={`${
              isAbove70 ? "bg-[#E9F8EB]" : "bg-[#F3F3F7]"
            } grid ml-auto px-[1.25rem] py-[0.875rem] text-center rounded-[8px] gap-[0.375rem]`}
          >
            <span className="text-[0.875rem] font-[500] text-[#3D3D3D]">
              Winning probability
            </span>
            <span
              className={`${
                isAbove70 ? "text-primary" : "text-[#050505]"
              } text-[1.5rem] font-[600] font-[600]`}
            >
              {selection?.winningProbability}%
            </span>
          </div>
        )}
      </div>
      {(isMobile || SideBySide) && (
        <div className="rounded-[8px] bg-[#ECE9F8] p-[0.75rem] text-center font-[500] text-[0.875rem] flex gap-[0.75rem] items-center justify-center">
          <span>Winning probability</span>
          <span className="text-[1.25rem] font-[600]">
            {selection?.winningProbability}%
          </span>
        </div>
      )}
      <div
        className={`${
          isMobile
            ? "border-none grid gap-2"
            : `${
                SideBySide
                  ? ""
                  : "border-t-[1px] border-t-solid border-t-[#C8C8C8]"
              } mx-[0.25rem] px-[0.75rem] py-[0.875rem] flex items-center`
        }`}
      >
        <div className="md:flex grid">
          <span
            className={`pr-[1.125rem] ${
              isMobile
                ? "border-none text-[0.75rem]"
                : "border-r-solid border-r-[1px] border-[#D0D0D0] text-[0.875rem]"
            } text-[#646464] font-[500]`}
          >
            Wager:
            <span className="ml-[0.375rem] text-[black]">
              {getWager(selection)}
            </span>
          </span>
          <span
            className={`${
              isMobile ? "pl-0 text-[0.75rem]" : "pl-[1.125rem] text-[0.875rem]"
            } text-[#646464] font-[500]`}
          >
            Date/time:
            <span className="ml-[0.375rem] text-[black]">
              {getESTDateTime(selection?.teams?.[0]?.date)}
            </span>
          </span>
        </div>
        {!noResultButton && (
          <div
            className={`relative ml-auto flex items-center gap-1.5 w-full lg:w-auto ${
              isSmallDevice ? "[&>div]:flex-1 [&>button]:flex-1" : ""
            }`}
          >
            {selection.note && <NoteTooltip note={selection.note} />}

            {selection?.winningStatus === 0 ? (
              <button
                className="text-primary text-[0.875rem] font-[600]"
                onClick={() => setIsOpen(!isOpen)}
              >
                Add Result
              </button>
            ) : (
              <button
                className={`${
                  selection?.winningStatus === 1
                    ? "text-[#00AB11]"
                    : "text-[#EE3900]"
                } font-[500] flex items-center gap-1`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selection?.winningStatus === 1 ? "Win" : "Loss"}
                <chevronIcon.ReactComponent className="w-[1.125rem] h-[1.125rem] opacity-[0.5]" />
              </button>
            )}

            <Dropdown
              isOpen={isOpen}
              setIsOpen={() => setIsOpen(!isOpen)}
              padding="6px"
            >
              <div className="place-content-start">
                <button
                  className="text-[#66BA3C] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                  onClick={() => handleResultClick(1)}
                >
                  Win
                </button>
                <button
                  className="text-[#D02929] font-[500] text-start w-full hover:bg-[#F5F5F5] rounded-[3px] px-[12px] py-[4px] transition-all"
                  onClick={() => handleResultClick(-1)}
                >
                  Loss
                </button>
              </div>
            </Dropdown>
          </div>
        )}
      </div>
    </motion.div>
  );
}
function NoSelectionModal({
  isOpen,
  handleModal,
}: {
  isOpen: boolean;
  handleModal: () => void;
}) {
  const [form, setForm] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // get subscription data
  const { data: subscriptions = [] } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: () => api.getSubscriptions(),
  });

  const handleSubscriptionChange = (e: any) => {
    // console.log("e", e.target.id);
    const id = e.target.id;
    // add id in form list
    if (form.includes(id)) {
      setForm(form.filter((item) => item !== id));
    } else {
      setForm([...form, id]);
    }
  };

  const onSubmit = () => {
    api
      .createSelection({
        subscriptions: form,
        day: moment().format("YYYY-MM-DD"),
        noSelection: true,
      })
      .then(
        () => {
          handleModal();
          toast.success("Subscription now marked as no selection");
        },
        (error) => {
          console.log(error);
          toast.error("Failed to mark as no selection");
        }
      );
  };

  return (
    <Modal
      isOpen={isOpen}
      handleModal={handleModal}
      title="Mark as no selection for today"
    >
      <div className="flex flex-col gap-4">
        <label className="text-[0.875rem] font-[500] text-[#252525]">
          Select subscription type adding for
        </label>
        <div className="flex gap-2 flex-wrap border-solid border-[1px] border-[#D9D9E7] rounded-[8px]">
          {subscriptions.map((subscription) => (
            <Checkbox
              id={subscription.id}
              key={subscription.id}
              label={subscription.name}
              style={{
                // backgroundColor: subscription.bgColor,
                // color: subscription.textColor,
                borderRadius: "8px",
                padding: "12px 16px",
                // fontWeight: "600",
                // fontSize: "12px",
              }}
              onChange={handleSubscriptionChange}
              value={
                form.includes(subscription.id) as
                  | (false & readonly string[])
                  | (true & readonly string[])
                  | undefined
              }
            />
          ))}
        </div>
        <Button
          onClick={onSubmit}
          className="w-fit ml-auto min-w-[120px] font-[500] text-[1rem]"
          isLoading={isLoading}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
